import React, { useState, Fragment } from 'react';
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link } from 'react-router-dom';

import { useNavigate } from 'react-router-dom';


const Register1 = () => {


    const [formData, setFormData] = useState({
        name: '',
        email: '',
        password: '',
    
      
        role: 'user' // default role
    });
    const navigate = useNavigate()

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post('/api/register', formData);
            console.log(response.data);

            toast.success(`Registration successful!`, {
                position: `top-right`,
                onClose: () => navigate('/login') // Navigate to login page after toast is closed
            });
        } catch (error) {
            console.error(error);
            toast.error('Error in registration', { position: 'top-right' });
        }
    };
    return (
        <Fragment>





            <div class="login-w3ls py-5">
                <div class="container py-xl-5 py-lg-3">
                    <h3 class="title text-capitalize font-weight-light text-dark text-center mb-5">register
                        <span class="font-weight-bold">now</span>
                    </h3>

                    <div class="sub-main-w3 pt-md-4">


                        <form onSubmit={handleSubmit}>
                            <div class="form-style-agile form-group">
                                <label>
                                    Your Name
                                    <i class="fas fa-user"></i>
                                </label>
                                <input placeholder="Your Name" class="form-control" name="name" type="text" required="" value={formData.name} onChange={handleChange} />
                            </div>
                            <div class="form-style-agile form-group">
                                <label>
                                    Email
                                    <i class="fas fa-envelope"></i>
                                </label>
                                <input placeholder="Email" class="form-control" name="email" type="email" required="" value={formData.email} onChange={handleChange} />
                            </div>
                            <div class="form-style-agile form-group">
                                <label>
                                    Password
                                    <i class="fas fa-unlock-alt"></i>
                                </label>
                                <input placeholder="Password" class="form-control" name="password" id="password1" type="password" required="" value={formData.password} onChange={handleChange} />
                            </div>
                            <p>
                                <select className='login-1 login-form input ' name="role" value={formData.role} onChange={handleChange}>
                                    <option value="user">User</option>
                                    {/* <option value="admin">Admin</option> */}
                                    <option value="user">Staff</option>
                                </select>
                            </p>



                            <p>

                                <button type="submit">Register</button>
                            </p>

                        </form>
                        <ToastContainer position="top-right" autoClose={5000} />
                    </div>

                </div>
            </div>
        </Fragment>
    )
}

export default Register1
