import React, { Fragment } from 'react'
import TrendingCourses from './TrendingCourses'

const Allprogram = () => {
	return (

		<Fragment>



			<div className="banner-agile-2">
				{/* <div className="navigation-w3ls">
					<nav className="navbar navbar-expand-lg navbar-light bg-light sticky-nav">
						<button className="navbar-toggler mx-auto" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
							aria-expanded="false" aria-label="Toggle navigation">
							<span className="navbar-toggler-icon"></span>
						</button>
						<div className="collapse navbar-collapse text-center" id="navbarSupportedContent">
							<ul className="navbar-nav justify-content-center">
								<li className="nav-item">
									<a className="nav-link text-white" href="/">Home
										<span className="sr-only">(current)</span>
									</a>
								</li>
								<li className="nav-item active">
									<a className="nav-link text-white" href="/about">About Us</a>
								</li>
								<li className="nav-item dropdown">
									<a className="nav-link dropdown-toggle text-white" href="#" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
										Courses
									</a>
									<div className="dropdown-menu">
										<a className="dropdown-item" href="language.html">Language</a>
										<a className="dropdown-item" href="communication.html">Communication</a>
										<a className="dropdown-item" href="business.html">Business</a>
										<a className="dropdown-item" href="software.html">Software</a>
										<a className="dropdown-item" href="social_media.html">Social Media</a>
										<a className="dropdown-item" href="photography.html">Photography</a>
										<a className="dropdown-item" href="course_details.html">Course Details</a>
										<div className="dropdown-divider"></div>
										<a className="dropdown-item" href="form.html">Apply Now</a>
									</div>
								</li>
								
								<li className="nav-item">
									<a className="nav-link text-white" href="/gallery">Gallery</a>
								</li>
								<li className="nav-item">
									<a className="nav-link text-white" href="/contactus">Contact Us</a>
								</li>
							</ul>
						</div>
					</nav>
				</div> */}
				<div className="navigation-w3ls">
					<nav className="navbar navbar-expand-lg navbar-light bg-light sticky-nav">
						<button className="navbar-toggler mx-auto" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
							aria-expanded="false" aria-label="Toggle navigation">
							<span className="navbar-toggler-icon"></span>
						</button>
						<div className="collapse navbar-collapse text-center" id="navbarSupportedContent">
							<ul className="navbar-nav justify-content-center">
								<li className="nav-item active">

									<a className="nav-link text-white" href="/">Home
										<span className="sr-only">(current)</span>
									</a>
								</li>
								<li className="nav-item">
									<a className="nav-link text-white" href="/about">About Us</a>
								</li>
								<li className="nav-item">
									<a className="nav-link text-white" href="/allprogram">All Programs</a>
								</li>
								<li className="nav-item">
									<a className="nav-link text-white" href="/Onlineprogram">Online Programs</a>
								</li>
								<li className="nav-item">
									<a className="nav-link text-white" href="/gallery1">Gallery</a>
								</li>
								<li className="nav-item">
									<a className="nav-link text-white" href="/internship">Internship</a>
								</li>
								<li className="nav-item">
									<a className="nav-link text-white" href="/placement">Placement</a>
								</li>

								{/* <li className="nav-item dropdown">
                  <a className="nav-link dropdown-toggle text-white" href="#" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    Courses
                  </a>
                </li> */}
{/* 
								<li className="nav-item">
									<a className="nav-link text-white" href="/couses">courses</a>
								</li> */}
								<li className="nav-item">
									<a className="nav-link text-white" href="/contactus">Contactus</a>
								</li>


							</ul>
						</div>
					</nav>
				</div>
			</div>
			<nav aria-label="breadcrumb">
				<ol className="breadcrumb" style={{ fontSize: "14px", fontFamily:"'Open Sans', sans-serif",padding : (".5em",".5em"), backgroundColor: "#e9ecef"}}>
					<li className="breadcrumb-item">
						<a href="/">Home</a>
					</li>
					<li className="breadcrumb-item active" aria-current="page">Allprogram</li>
				</ol>
			</nav>




			{/* <div class="details-w3l py-5">
				<div class="container py-xl-5 py-lg-3">
					<h3 class="title text-capitalize font-weight-light text-dark text-center mb-5">course
						<span class="font-weight-bold">details</span>
					</h3>

					<div class="row inner_sec_info pt-md-4">

						<div class="col-lg-8 single-left">
							<div class="single-left1">
								<img src="assests/images/images12.jpg" alt=" " class="img-fluid" />
								<h6 class="details-heading font-italic my-4">Courses Information</h6>
								<h5 class="card-title">
									<a href="course_details.html" class="text-dark">IT DESK provides professional software training on a vast array of courses in the fields of Computer Science and Information Technology.</a>
								</h5>
								<p>R Programming is one of the most popular languages, which has inroads into social media services, financial institutions, and media, etc. Due to its easy-to-use interface, the data scientists can collect data in real-time, perform various analysis techniques to visualize and present data. IT DESK training course provides the skills required to work with real data sets and provides an opportunity to use data to provide data-driven strategic and tactical recommendations.

									With a wide range of packages, R programming works on all popular platforms – Windows, Linux, and Mac. R programming is highly recommended by data scientists and data analysts for statistical analysis, data visualization, and predictive modeling. IT DESK encourages the students to do Internship during the course and also Industrial visits are being organized. Each and every training is provided on the latest version of R Programming..</p>
								<p class="mt-3">IT DESK is a pioneer in CS/IT training and most of the industries give great recognition to its certificate throughout India and abroad. IT DESK Certificates are ISO verified which makes it highly reputed comparing to the other local brands and helps the student get prioritize while going for job opportunities in the industry. .</p>
							</div>
							<div class="admin p-4 my-5">
								<p>
									<i class="fas fa-quote-left mr-2"></i>IT DESK is the Authorized Training Partner of Microsoft Technology Associate. It enables us to work on a wide range of software in Computer Science and Information Technology
									<i class="fas fa-quote-right ml-2"></i>
								</p>
								<a href="#" class="mt-3 font-weight-bold text-right blockquote-footer">James Doe</a>
							</div>
							<div class="single-left2">
								<h6 class="reque-title text-dark mb-3">Requirements</h6>
								<div class="row">
									<div class="col-md-6 single-left2-left">
										<ul>
											<li class="mt-3">
												<i class="fas fa-check mr-2"></i>
												<a href="course_details.html">At vero eos et accusamus iusto</a>
											</li>
											<li class="mt-3">
												<i class="fas fa-check mr-2"></i>
												<a href="course_details.html">Sed ut perspiciatis unde omnis</a>
											</li>
											<li class="mt-3">
												<i class="fas fa-check mr-2"></i>
												<a href="course_details.html">Accusantium doloremque lauda</a>
											</li>
											<li class="mt-3">
												<i class="fas fa-check mr-2"></i>
												<a href="course_details.html">Vel illum qui dolorem fugiat quo</a>
											</li>
											<li class="mt-3">
												<i class="fas fa-check mr-2"></i>
												<a href="course_details.html">Quis autem vel eum repreh</a>
											</li>
											<li class="mt-3">
												<i class="fas fa-check mr-2"></i>
												<a href="course_details.html">Neque porro quisquam est qui</a>
											</li>
										</ul>
									</div>
									<div class="col-md-6 single-left2-left">
										<ul>
											<li class="mt-3">
												<i class="fas fa-check mr-2"></i>
												<a href="course_details.html">At vero eos et accusamus iusto</a>
											</li>
											<li class="mt-3">
												<i class="fas fa-check mr-2"></i>
												<a href="course_details.html">Sed ut perspiciatis unde omnis</a>
											</li>
											<li class="mt-3">
												<i class="fas fa-check mr-2"></i>
												<a href="course_details.html">Accusantium doloremque lauda</a>
											</li>
											<li class="mt-3">
												<i class="fas fa-check mr-2"></i>
												<a href="course_details.html">Vel illum qui dolorem fugiat quo</a>
											</li>
											<li class="mt-3">
												<i class="fas fa-check mr-2"></i>
												<a href="course_details.html">Quis autem vel eum repreh</a>
											</li>
											<li class="mt-3">
												<i class="fas fa-check mr-2"></i>
												<a href="course_details.html">Neque porro quisquam est qui</a>
											</li>
										</ul>
									</div>
								</div>
							</div>
							<div class="social-details p-4 my-5 border">
								<h5>You Can Share it:</h5>
								<ul class="list-unstyled social-details-icons my-3">
									<li>
										<a href="#" class="fab fa-facebook-f bg-dark text-white text-center rounded-circle"> </a>
									</li>
									<li class="mx-2">
										<a href="#" class="fab fa-twitter bg-dark text-white text-center rounded-circle"> </a>
									</li>
									<li>
										<a href="#" class="fab fa-google-plus-g bg-dark text-white text-center rounded-circle"> </a>
									</li>
									<li class="ml-2">
										<a href="#" class="fab fa-youtube icon-border youtube rounded-circle"> </a>
									</li>

								</ul>

								<div class="text-right">
									<a class="btn bg-dark text-white" href="form.html" role="button">Apply Now</a>
								</div>
							</div>
						</div>


						<div class="col-lg-4 event-right mt-lg-0 mt-sm-5 mt-4">
							<div class="event-right1">
								<div class="search1">
									<form class="form-inline" action="#" method="post">
										<input class="form-control rounded-0 mr-sm-2" type="search" placeholder="Search Here" aria-label="Search" required />
										<button class="btn bg-dark text-white rounded-0 mt-3" type="submit">Search</button>
									</form>
								</div>
								<div class="categories my-4 p-4 border">
									<h3 class="blog-title text-dark">Categories</h3>
									<ul>
										<li class="mt-3">
											<i class="fas fa-check mr-2"></i>
											<a href="language.html">Language</a>
										</li>
										<li class="mt-3">
											<i class="fas fa-check mr-2"></i>
											<a href="communication.html">Communication</a>
										</li>
										<li class="mt-3">
											<i class="fas fa-check mr-2"></i>
											<a href="business.html">Business</a>
										</li>
										<li class="mt-3">
											<i class="fas fa-check mr-2"></i>
											<a href="software.html">Software</a>
										</li>
										<li class="mt-3">
											<i class="fas fa-check mr-2"></i>
											<a href="social_media.html">Social Media</a>
										</li>
										<li class="mt-3">
											<i class="fas fa-check mr-2"></i>
											<a href="photography.html">Photography</a>
										</li>
									</ul>
								</div>
								<div class="posts p-4 border">
									<h3 class="blog-title text-dark">Latest Courses</h3>
									<div class="posts-grids">
										<div class="row posts-grid mt-4">
											<div class="col-lg-4 col-md-3 col-4 posts-grid-left pr-0">
												<a href="course_details.html">
													<img src="images/c2.jpg" alt=" " class="img-fluid" />
												</a>
											</div>
											<div class="col-lg-8 col-md-7 col-8 posts-grid-right mt-lg-0 mt-md-5 mt-sm-4">
												<h4>
													<a href="course_details.html" class="text-dark">Fullstack Web Development JAVA</a>
												</h4>
												<ul class="wthree_blog_events_list mt-2">
													<li class="mr-2 text-dark">
														<i class="far fa-calendar-alt  mr-2"></i>4 Months</li>
													<li>
														<i class="fas fa-dollar-sign"></i>
														<a href="form.html" class="text-dark ml-2">- 150</a>
													</li>
												</ul>
											</div>
										</div>
										<div class="row posts-grid mt-4">
											<div class="col-lg-4 col-md-3 col-4 posts-grid-left pr-0">
												<a href="course_details.html">
													<img src="images/c1.jpg" alt=" " class="img-fluid" />
												</a>
											</div>
											<div class="col-lg-8 col-md-7 col-8 posts-grid-right mt-lg-0 mt-md-5 mt-sm-4">
												<h4>
													<a href="course_details.html" class="text-dark">Fullstack Web Development Python</a>
												</h4>
												<ul class="wthree_blog_events_list mt-2">
													<li class="mr-2 text-dark">
														<i class="far fa-calendar-alt mr-2"></i>8 Months</li>
													<li>
														<i class="fas fa-dollar-sign"></i>
														<a href="form.html" class="text-dark ml-2">- 70</a>
													</li>
												</ul>
											</div>
										</div>
										<div class="row posts-grid mt-4">
											<div class="col-lg-4 col-md-3 col-4 posts-grid-left pr-0">
												<a href="course_details.html">
													<img src="images/c3.jpg" alt=" " class="img-fluid" />
												</a>
											</div>
											<div class="col-lg-8 col-md-7 col-8 posts-grid-right mt-lg-0 mt-md-5 mt-sm-4">
												<h4>
													<a href="course_details.html" class="text-dark">MERN STACK</a>
												</h4>
												<ul class="wthree_blog_events_list mt-2">
													<li class="mr-2 text-dark">
														<i class="far fa-calendar-alt mr-2"></i>1 Year</li>
													<li>
														<i class="fas fa-dollar-sign"></i>
														<a href="form.html" class="text-dark ml-2">- 300</a>
													</li>
												</ul>
											</div>
										</div>
									</div>
								</div>
								<div class="tags mt-4 p-4 border">
									<h3 class="blog-title text-dark">Recent Tags</h3>
									<ul class="mt-4">
										<li>
											<a href="course_details.html" class="text-dark border">Designs</a>
										</li>
										<li>
											<a href="course_details.html" class="text-dark border">Growth</a>
										</li>
										<li>
											<a href="course_details.html" class="text-dark border">Latest</a>
										</li>
										<li>
											<a href="course_details.html" class="text-dark border">Price</a>
										</li>
										<li>
											<a href="course_details.html" class="text-dark border">Tools</a>
										</li>
										<li>
											<a href="course_details.html" class="text-dark border">Business</a>
										</li>
										<li>
											<a href="course_details.html" class="text-dark border">Work Space</a>
										</li>
										<li>
											<a href="course_details.html" class="text-dark border">New Course</a>
										</li>
										<li>
											<a href="course_details.html" class="text-dark border">Advantage</a>
										</li>
										<li>
											<a href="course_details.html" class="text-dark border">Concepts</a>
										</li>
										<li>
											<a href="course_details.html" class="text-dark border">Arts</a>
										</li>
										<li>
											<a href="course_details.html" class="text-dark border">Photography</a>
										</li>
										<li>
											<a href="course_details.html" class="text-dark border">Research</a>
										</li>
										<li>
											<a href="course_details.html" class="text-dark border">Software</a>
										</li>
									</ul>
								</div>
							</div>
						</div>

					</div>
				</div>


				<div class="stats-w3layouts py-5">
					<div class="container py-xl-5 py-lg-3">
						<div class="stats-info">
							<div class="row">
								<div class="col-md-3 col-6 stats-grid-w3-agile text-center px-xl-5 px-3">
									<div class='numscroller font-weight-bold my-2' data-slno='1' data-min='0' data-max='1280' data-delay='.5' data-increment="1">1280</div>
									<p class="text-uppercase text-white border-top pt-4 mt-3">happy students</p>
								</div>
								<div class="col-md-3 col-6 stats-grid-w3-agile text-center  px-xl-5 px-3">
									<div class='numscroller font-weight-bold my-2' data-slno='1' data-min='0' data-max='920' data-delay='.5' data-increment="1">920</div>
									<p class="text-uppercase text-white border-top pt-4 mt-3">approved courses</p>
								</div>
								<div class="col-md-3 col-6 stats-grid-w3-agile text-center mt-md-0 mt-5  px-xl-5 px-3">
									<div class='numscroller font-weight-bold my-2' data-slno='1' data-min='0' data-max='1200' data-delay='.5' data-increment="1">1200</div>
									<p class="text-uppercase text-white border-top pt-4 mt-3">certified teachers</p>
								</div>
								<div class="col-md-3 col-6 stats-grid-w3-agile text-center mt-md-0 mt-5  px-xl-5 px-3">
									<div class='numscroller font-weight-bold my-2' data-slno='1' data-min='0' data-max='1200' data-delay='.5' data-increment="1">1200</div>
									<p class="text-uppercase text-white border-top pt-4 mt-3">graduate students</p>
								</div>
							</div>
						</div>
					</div>
				</div>



				<div class="classes py-5">
					<div class="container py-xl-5 py-lg-3">
						<h3 class="title text-capitalize font-weight-light text-dark text-center mb-sm-5 mb-4">choose your
							<span class="font-weight-bold">course</span>
						</h3>
						<div class="row pt-4">
							<div class="col-lg-4 col-news-top text-center">

								<div class="ih-item circle effect16 left_to_right mx-auto">
									<a href="language.html">
										<div class="img">
											<img src="assests/images/c1.jpg" alt="img" class="img-fluid rounded-circle" />
										</div>
										<div class="info">
											<h3 class="text-capitalize text-white">language</h3>
											<p>Description goes here</p>
										</div>
									</a>
								</div>
								<h6 class="small-heading text-capitalize text-center mt-4">
									<a href="language.html" class="text-dark">language
										<i class="fas fa-long-arrow-alt-right ml-1"></i>
									</a>
								</h6>

							</div>
							<div class="col-lg-4 col-news-top text-center my-lg-0 my-sm-5 my-4">

								<div class="ih-item circle effect16 left_to_right mx-auto">
									<a href="communication.html">
										<div class="img">
											<img src="assests/images/c2.jpg" alt="img" class="img-fluid rounded-circle" />
										</div>
										<div class="info">
											<h3 class="text-capitalize text-white">communication</h3>
											<p>Description goes here</p>
										</div>
									</a>
								</div>
								<h6 class="small-heading text-capitalize text-center mt-4">
									<a href="communication.html" class="text-dark">communication
										<i class="fas fa-long-arrow-alt-right ml-1"></i>
									</a>
								</h6>

							</div>
							<div class="col-lg-4 col-news-top text-center">


								<div class="ih-item circle effect16 left_to_right mx-auto">
									<a href="business.html">
										<div class="img">
											<img src="assests/images/c3.jpg" alt="img" class="img-fluid rounded-circle" />
										</div>
										<div class="info">
											<h3 class="text-capitalize text-white">business</h3>
											<p>Description goes here</p>
										</div>
									</a>
								</div>
								<h6 class="small-heading text-capitalize text-center mt-4">
									<a href="business.html" class="text-dark">business
										<i class="fas fa-long-arrow-alt-right ml-1"></i>
									</a>
								</h6>

							</div>
						</div>
						<div class="row mt-5">
							<div class="col-lg-4 col-news-top text-center">

								<div class="ih-item circle effect16 left_to_right mx-auto">
									<a href="software.html">
										<div class="img">
											<img src="assests/images/c4.jpg" alt="img" class="img-fluid rounded-circle" />
										</div>
										<div class="info">
											<h3 class="text-capitalize text-white">software</h3>
											<p>Description goes here</p>
										</div>
									</a>
								</div>
								<h6 class="small-heading text-capitalize text-center mt-4">
									<a href="software.html" class="text-dark">software
										<i class="fas fa-long-arrow-alt-right ml-1"></i>
									</a>
								</h6>

							</div>
							<div class="col-lg-4 col-news-top text-center  my-lg-0 my-sm-5 my-4">

								<div class="ih-item circle effect16 left_to_right mx-auto">
									<a href="social_media.html">
										<div class="img">
											<img src="assests/images/c6.jpg" alt="img" class="img-fluid rounded-circle" />
										</div>
										<div class="info">
											<h3 class="text-capitalize text-white">social media </h3>
											<p>Description goes here</p>
										</div>
									</a>
								</div>
								<h6 class="small-heading text-capitalize text-center mt-4">
									<a href="social_media.html" class="text-dark">social media
										<i class="fas fa-long-arrow-alt-right ml-1"></i>
									</a>
								</h6>

							</div>
							<div class="col-lg-4 col-news-top text-center">

								<div class="ih-item circle effect16 left_to_right mx-auto">
									<a href="photography.html">
										<div class="img">
											<img src="assests/images/c5.jpg" alt="img" class="img-fluid rounded-circle" />
										</div>
										<div class="info">
											<h3 class="text-capitalize text-white">photography </h3>
											<p>Description goes here</p>
										</div>
									</a>
								</div>
								<h6 class="small-heading text-capitalize text-center mt-4">
									<a href="photography.html" class="text-dark">photography
										<i class="fas fa-long-arrow-alt-right ml-1"></i>
									</a>
								</h6>

							</div>
						</div>
					</div>
				</div>
			</div> */}





			{/* <div class="course-w3ls py-5">
				<div class="container py-xl-5 py-lg-3">
					<h3 class="title text-capitalize font-weight-light text-dark text-center mb-sm-5 mb-4">course -
						<span class="font-weight-bold">Software</span>
					</h3>
					<div class="row cource-list-agile pt-4">
						<div class="col-lg-7 agile-course-main">
							<div class="w3ls-cource-first">
								<img src="assests/images/1.png" alt="" class="img-fluid img-poiscour mx-auto d-block mt-2"></img>
								<div class="px-md-5 px-4  pb-md-5 pb-4">
									<h3 class="text-dark">A Guide For Beginners Software</h3>
									<p class="mt-3 mb-4 pr-lg-5">Java has long been one of the most popular programming languages in the software industry. With its versatility and wide range of applications.</p>
									<ul class="list-unstyled text-capitalize">
										<li>
											<i class="fas fa-calendar-alt mr-3"></i>may - aug 2018</li>
										<li class="my-3">
											<i class="fas fa-clock mr-3"></i>4 - 6 hours</li>
										<li>
											<i class="fas fa-users mr-3"></i>70 seats</li>
									</ul>
								</div>
							</div>
						</div>
						<div class="col-lg-5 agile-course-main-2 mt-4">
							<img src="assests/images/java3.jpg" alt="" class="img-fluid" />
						</div>
						<div class="buttons-w3ls">
							<a class="btn button-cour-w3ls text-white" href="/courses" role="button">Learn More</a>
							<a class="btn bg-dark text-white" href="form.html" role="button">Apply Now</a>
						</div>
					</div>
					<div class="row cource-list-agile cource-list-agile-2">
						<div class="col-lg-5 agile-course-main-3 mt-4">
							<img src="assests/images/ds1.jpg" alt="" class="img-fluid" />
						</div>
						<div class="col-lg-7 agile-course-main text-right">
							<div class="w3ls-cource-first">
								<img src="assests/images/1.png" alt="" class="img-fluid img-poiscour mx-auto d-block mt-2"></img>
								<div class="px-md-5 px-4  pb-md-5 pb-4">
									<h3 class="text-dark">Introduction To Engineering Design</h3>
									<p class="mt-3 mb-4 pl-lg-4">if youre looking to explore the fascinating world of data science and gain proficiency in R programming,.</p>
									<ul class="list-unstyled text-capitalize">
										<li>june - aug 2018
											<i class="fas fa-calendar-alt ml-3"></i>
										</li>
										<li class="my-3">3 - 6 hours
											<i class="fas fa-clock ml-3"></i>
										</li>
										<li>60 seats
											<i class="fas fa-users ml-3"></i>
										</li>
									</ul>
								</div>
							</div>
						</div>
						<div class="buttons-w3ls-2">
							<a class="btn button-cour-w3ls text-white" href="course_details.html" role="button">Learn More</a>
							<a class="btn bg-dark text-white" href="form.html" role="button">Apply Now</a>
						</div>
					</div>
					<div class="row cource-list-agile pt-4">
						<div class="col-lg-7 agile-course-main">
							<div class="w3ls-cource-first">
								<img src="assests/images/2.png" alt="" class="img-fluid img-poiscour mx-auto d-block mt-2"></img>
								<div class="px-md-5 px-4  pb-md-5 pb-4">
									<h3 class="text-dark">Organize Of Program Languages</h3>
									<p class="mt-3 mb-4 pr-lg-5">Mean Stack Development is one such technology that has gained immense popularity among developers.</p>
									<ul class="list-unstyled text-capitalize">
										<li>
											<i class="fas fa-calendar-alt mr-3"></i>may - aug 2018</li>
										<li class="my-3">
											<i class="fas fa-clock mr-3"></i>4 - 6 hours</li>
										<li>
											<i class="fas fa-users mr-3"></i>70 seats</li>
									</ul>
								</div>
							</div>
						</div>
						<div class="col-lg-5 agile-course-main-2 mt-4">
							<img src="assests/images/ds2.png" alt="" class="img-fluid" />
						</div>
						<div class="buttons-w3ls">
							<a class="btn button-cour-w3ls text-white" href="course_details.html" role="button">Learn More</a>
							<a class="btn bg-dark text-white" href="form.html" role="button">Apply Now</a>
						</div>
					</div>
					<div class="row cource-list-agile cource-list-agile-2">
						<div class="col-lg-5 agile-course-main-3 mt-4">
							<img src="assests/images/ds7.jpg" alt="" class="img-fluid" />
						</div>
						<div class="col-lg-7 agile-course-main text-right">
							<div class="w3ls-cource-first">
								<img src="assests/images/2.png" alt="" class="img-fluid img-poiscour mx-auto d-block mt-2"></img>
								<div class="px-md-5 px-4  pb-md-5 pb-4">
									<h3 class="text-dark">Programming Software Engineer</h3>
									<p class="mt-3 mb-4 pl-lg-4"> C/C++ programming internship is a practical learning opportunity designed for students and programming enthusiasts.</p>
									<ul class="list-unstyled text-capitalize">
										<li>june - aug 2018
											<i class="fas fa-calendar-alt ml-3"></i>
										</li>
										<li class="my-3">3 - 6 hours
											<i class="fas fa-clock ml-3"></i>
										</li>
										<li>60 seats
											<i class="fas fa-users ml-3"></i>
										</li>
									</ul>
								</div>
							</div>
						</div>
						<div class="buttons-w3ls-2">
							<a class="btn button-cour-w3ls text-white" href="course_details.html" role="button">Learn More</a>
							<a class="btn bg-dark text-white" href="form.html" role="button">Apply Now</a>
						</div>
					</div>
					<div class="row cource-list-agile pt-4">
						<div class="col-lg-7 agile-course-main">
							<div class="w3ls-cource-first">
								<img src="assests/images/1.png" alt="" class="img-fluid img-poiscour mx-auto d-block mt-2"></img>
								<div class="px-md-5 px-4  pb-md-5 pb-4">
									<h3 class="text-dark">Object-Oriented Programming Java</h3>
									<p class="mt-3 mb-4 pr-lg-5">Java is an object-oriented programming language which is generally designed either to be compiled to native (machine) code or to be interpreted from source code at runtime..</p>
									<ul class="list-unstyled text-capitalize">
										<li>
											<i class="fas fa-calendar-alt mr-3"></i>may - aug 2018</li>
										<li class="my-3">
											<i class="fas fa-clock mr-3"></i>4 - 6 hours</li>
										<li>
											<i class="fas fa-users mr-3"></i>70 seats</li>
									</ul>
								</div>
							</div>
						</div>
						<div class="col-lg-5 agile-course-main-2 mt-4">
							<img src="assests/images/ds8.jpg" alt="" class="img-fluid" />
						</div>
						<div class="buttons-w3ls">
							<a class="btn button-cour-w3ls text-white" href="course_details.html" role="button">Learn More</a>
							<a class="btn bg-dark text-white" href="form.html" role="button">Apply Now</a>
						</div>
					</div>
					<div class="row cource-list-agile cource-list-agile-2">
						<div class="col-lg-5 agile-course-main-3 mt-4">
							<img src="assests/images/ds6.jpg" alt="" class="img-fluid" />
						</div>
						<div class="col-lg-7 agile-course-main text-right">
							<div class="w3ls-cource-first">
								<img src="assests/images/2.png" alt="" class="img-fluid img-poiscour mx-auto d-block mt-2"></img>
								<div class="px-md-5 px-4  pb-md-5 pb-4">
									<h3 class="text-dark">Algorithms Software Programming</h3>
									<p class="mt-3 mb-4 pl-lg-4"> Java offers a plethora of opportunities for developers. Among the various branches of Java, Advanced Java J2EE stands out as a powerful tool for building enterprise-level applications</p>
									<ul class="list-unstyled text-capitalize">
										<li>june - aug 2018
											<i class="fas fa-calendar-alt ml-3"></i>
										</li>
										<li class="my-3">3 - 6 hours
											<i class="fas fa-clock ml-3"></i>
										</li>
										<li>60 seats
											<i class="fas fa-users ml-3"></i>
										</li>
									</ul>
								</div>
							</div>
						</div>
						<div class="buttons-w3ls-2">
							<a class="btn button-cour-w3ls text-white" href="course_details.html" role="button">Learn More</a>
							<a class="btn bg-dark text-white" href="form.html" role="button">Apply Now</a>
						</div>
					</div>
				</div>
			</div> */}

			<TrendingCourses />

			{/* <div class="stats-w3layouts py-5">
				<div class="container py-xl-5 py-lg-3">
					<div class="stats-info">
						<div class="row">
							<div class="col-md-3 col-6 stats-grid-w3-agile text-center px-xl-5 px-3">
								<div class='numscroller font-weight-bold my-2' data-slno='1' data-min='0' data-max='1280' data-delay='.5' data-increment="1">1280</div>
								<p class="text-uppercase text-white border-top pt-4 mt-3">happy students</p>
							</div>
							<div class="col-md-3 col-6 stats-grid-w3-agile text-center  px-xl-5 px-3">
								<div class='numscroller font-weight-bold my-2' data-slno='1' data-min='0' data-max='920' data-delay='.5' data-increment="1">920</div>
								<p class="text-uppercase text-white border-top pt-4 mt-3">approved courses</p>
							</div>
							<div class="col-md-3 col-6 stats-grid-w3-agile text-center mt-md-0 mt-5  px-xl-5 px-3">
								<div class='numscroller font-weight-bold my-2' data-slno='1' data-min='0' data-max='1200' data-delay='.5' data-increment="1">1200</div>
								<p class="text-uppercase text-white border-top pt-4 mt-3">certified teachers</p>
							</div>
							<div class="col-md-3 col-6 stats-grid-w3-agile text-center mt-md-0 mt-5  px-xl-5 px-3">
								<div class='numscroller font-weight-bold my-2' data-slno='1' data-min='0' data-max='1200' data-delay='.5' data-increment="1">1200</div>
								<p class="text-uppercase text-white border-top pt-4 mt-3">graduate students</p>
							</div>
						</div>
					</div>
				</div>
			</div> */}



			{/* <div class="classes py-5">
				<div class="container py-xl-5 py-lg-3">
					<h3 class="title text-capitalize font-weight-light text-dark text-center mb-sm-5 mb-4">choose your
						<span class="font-weight-bold">course</span>
					</h3>
					<div class="row pt-4">
						<div class="col-lg-4 col-news-top text-center">

							<div class="ih-item circle effect16 left_to_right mx-auto">
								<a href="language.html">
									<div class="img">
										<img src="assests/images/c1.jpg" alt="img" class="img-fluid rounded-circle" />
									</div>
									<div class="info">
										<h3 class="text-capitalize text-white">language</h3>
										<p>Description goes here</p>
									</div>
								</a>
							</div>
							<h6 class="small-heading text-capitalize text-center mt-4">
								<a href="language.html" class="text-dark">language
									<i class="fas fa-long-arrow-alt-right ml-1"></i>
								</a>
							</h6>

						</div>
						<div class="col-lg-4 col-news-top text-center my-lg-0 my-sm-5 my-4">

							<div class="ih-item circle effect16 left_to_right mx-auto">
								<a href="communication.html">
									<div class="img">
										<img src="assests/images/c2.jpg" alt="img" class="img-fluid rounded-circle" />
									</div>
									<div class="info">
										<h3 class="text-capitalize text-white">communication</h3>
										<p>Description goes here</p>
									</div>
								</a>
							</div>
							<h6 class="small-heading text-capitalize text-center mt-4">
								<a href="communication.html" class="text-dark">communication
									<i class="fas fa-long-arrow-alt-right ml-1"></i>
								</a>
							</h6>

						</div>
						<div class="col-lg-4 col-news-top text-center">

							<div class="ih-item circle effect16 left_to_right mx-auto">
								<a href="business.html">
									<div class="img">
										<img src="assests/images/c3.jpg" alt="img" class="img-fluid rounded-circle" />
									</div>
									<div class="info">
										<h3 class="text-capitalize text-white">business</h3>
										<p>Description goes here</p>
									</div>
								</a>
							</div>
							<h6 class="small-heading text-capitalize text-center mt-4">
								<a href="business.html" class="text-dark">business
									<i class="fas fa-long-arrow-alt-right ml-1"></i>
								</a>
							</h6>

						</div>
					</div>
					<div class="row mt-5">
						<div class="col-lg-4 col-news-top text-center">

							<div class="ih-item circle effect16 left_to_right mx-auto">
								<a href="software.html">
									<div class="img">
										<img src="assests/images/c4.jpg" alt="img" class="img-fluid rounded-circle" />
									</div>
									<div class="info">
										<h3 class="text-capitalize text-white">software</h3>
										<p>Description goes here</p>
									</div>
								</a>
							</div>
							<h6 class="small-heading text-capitalize text-center mt-4">
								<a href="software.html" class="text-dark">software
									<i class="fas fa-long-arrow-alt-right ml-1"></i>
								</a>
							</h6>

						</div>
						<div class="col-lg-4 col-news-top text-center  my-lg-0 my-sm-5 my-4">

							<div class="ih-item circle effect16 left_to_right mx-auto">
								<a href="social_media.html">
									<div class="img">
										<img src="assests/images/c6.jpg" alt="img" class="img-fluid rounded-circle" />
									</div>
									<div class="info">
										<h3 class="text-capitalize text-white">social media </h3>
										<p>Description goes here</p>
									</div>
								</a>
							</div>
							<h6 class="small-heading text-capitalize text-center mt-4">
								<a href="social_media.html" class="text-dark">social media
									<i class="fas fa-long-arrow-alt-right ml-1"></i>
								</a>
							</h6>

						</div>
						<div class="col-lg-4 col-news-top text-center">

							<div class="ih-item circle effect16 left_to_right mx-auto">
								<a href="photography.html">
									<div class="img">
										<img src="assests/images/c5.jpg" alt="img" class="img-fluid rounded-circle" />
									</div>
									<div class="info">
										<h3 class="text-capitalize text-white">photography </h3>
										<p>Description goes here</p>
									</div>
								</a>
							</div>
							<h6 class="small-heading text-capitalize text-center mt-4">
								<a href="photography.html" class="text-dark">photography
									<i class="fas fa-long-arrow-alt-right ml-1"></i>
								</a>
							</h6>

						</div>
					</div>
				</div>
			</div> */}


{/* 
			<div class="brands-w3ls py-md-5 py-4">
				<div class="container py-xl-3">
					<ul class="list-unstyled">
						<li>
							<i class="fab fa-supple"></i>
						</li>
						<li>
							<i class="fab fa-angrycreative"></i>
						</li>
						<li>
							<i class="fab fa-aviato"></i>
						</li>
						<li>
							<i class="fab fa-aws"></i>
						</li>
						<li>
							<i class="fab fa-cpanel"></i>
						</li>
						<li>
							<i class="fab fa-hooli"></i>
						</li>
						<li>
							<i class="fab fa-node"></i>
						</li>
					</ul>
				</div>
			</div> */}




		</Fragment >

	)
}

export default Allprogram
