import { color } from 'framer-motion'
import React, { Fragment } from 'react'

const Corejava = () => {
    return (
        <Fragment>
            {/* <div className="botam-navbar">
                <p style="margin-left: 15px; color: #fff">To avail the weekly free training offer <br />Sign up Now.</p>
                <a href="#corseform" className="active">To know course fee.</a>
                <a href="#corseform" className="active">Know More</a>
            </div> */}

            <div id="main-wrapper">
                <div className="site-wrapper-reveal">
                    <div className="about-banner-wrap vision-space bg-img" style={{ backgroundImage: 'url(assests/images/background3.jpg)', height: "600px" }}>
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-6 section-space--pt_100">
                                    <h1 style={{ color: "#fff" }}>Core Java Internship</h1>
                                    <p style={{ color: "#fff" }}>Most popular in Console Application, Desktop Application, and Web Application Development.</p>
                                </div>
                                <div className="col-lg-2">
                                </div>
                                <div className="col-lg-4">
                                    <div className="form-container mx-auto mx-xl-0 ml-xl-auto border" id="corseform">
                                        <div id="signup-form">
                                            <form id="basic-form" name="yoursite_wp_mail_from_name" method="post" />
                                            <input type="hidden" name="courseId" value="865" />
                                            <input type="hidden" name="category" value="5" />
                                            <div className="form-group">
                                                <label>Name:</label>
                                                <input type="text" id="fname" name="fname" className="form-control" placeholder="Enter Your Name" value="" required="" />
                                            </div>
                                            <div className="form-group">
                                                <label>Email:</label>
                                                <input type="email" id="email" name="email" className="form-control" placeholder="Enter Your Email Address" value="" required="" />
                                            </div>
                                            <div className="form-group">
                                                <label>Mobile:</label>
                                                <input type="number" id="phone" name="phone" className="form-control" placeholder="Enter Your mobile number" value="" required="" />
                                            </div>
                                            <div className="form-group mb-0">
                                                <div className="form-row">
                                                    <div className="form-group col-6">
                                                        <label className="control-label">State</label>
                                                        <select id="states" name="states" className="form-control" required="">
                                                            <option value="">SELECT STATE</option>
                                                            <option value="2">Andhra Pradesh</option>
                                                            <option value="5">Bihar</option>
                                                            <option value="12">Gujarat</option>
                                                            <option value="13">Haryana</option>
                                                            <option value="14">Himachal Pradesh</option>
                                                            <option value="16">Jharkhand</option>
                                                            <option value="17">Karnataka</option>
                                                            <option value="21">Maharashtra</option>
                                                            <option value="28">Punjab</option>
                                                            <option value="29">Rajasthan</option>
                                                            <option value="31">Tamil Nadu</option>
                                                            <option value="32">Telangana</option>
                                                            <option value="35">Uttar Pradesh</option>
                                                        </select>
                                                    </div>
                                                    <div className="form-group col-6 city">
                                                        <label className="control-label">Center</label>
                                                        <select name="city" id="city" className="form-control center" required="">
                                                            <option value="">SELECT CENTRE</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-group mb-0">
                                                <button type="submit" id="btn" className="btn btn-primary contest_register_now" name="register_users">Apply Now</button>
                                            </div>
                                        </div>


                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <nav aria-label="breadcrumb">
                <ol class="breadcrumb" >
                    <li class="breadcrumb-item" >
                        <a href="/" >Home</a>
                    </li>
                    <li class="breadcrumb-item active" aria-current="page">Gallery</li>
                </ol>
            </nav>
            <br />
            <br />

            <div class="vision-overview ">
                <div class="container">
                    <div class="section-title-wrap text-center">
                        <h3 class="heading"><span class="text-color-primary" style={{ fontSize: "21px;", color: "black" }}> Training Highlights</span></h3>
                    </div>
                    <div class="row">
                        <div class="col-12 col-lg-4">
                            <div class="item-container d-flex align-items-center">
                                <div class="image-container">
                                    <img src="https://itdeskindia.com/wp-content/uploads/2021/02/1.png" class="lazy-load" />
                                </div>
                                <div class="content-container">
                                    <h3 class="title">Instructor Led Training</h3>
                                    <p class="description"></p>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-lg-4">
                            <div class="item-container d-flex align-items-center">
                                <div class="image-container">
                                    <img src="https://itdeskindia.com/wp-content/uploads/2021/02/2.png" class="lazy-load" />
                                </div>
                                <div class="content-container">
                                    <h3 class="title">Weekly Doubt clearing Session</h3>
                                    <p class="description"></p>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-lg-4">
                            <div class="item-container d-flex align-items-center">
                                <div class="image-container">
                                    <img src="https://itdeskindia.com/wp-content/uploads/2021/02/offline-revision-min.png" class="lazy-load" />
                                </div>
                                <div class="content-container">
                                    <h3 class="title">Lifetime Assistance for Revision</h3>
                                    <p class="description"></p>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-lg-4">
                            <div class="item-container d-flex align-items-center">
                                <div class="image-container">
                                    <img src="https://itdeskindia.com/wp-content/uploads/2021/02/LOGIN.jpg" class="lazy-load" />
                                </div>
                                <div class="content-container">
                                    <h3 class="title">Lifetime Login Access to Student Dashboard</h3>
                                    <p class="description"></p>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-lg-4">
                            <div class="item-container d-flex align-items-center">
                                <div class="image-container">
                                    <img src="https://itdeskindia.com/wp-content/uploads/2021/02/certificate-icon-10.png" class="lazy-load" />
                                </div>
                                <div class="content-container">
                                    <h3 class="title">World-Wide Valid Certification</h3>
                                    <p class="description"></p>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-lg-4">
                            <div class="item-container d-flex align-items-center">
                                <div class="image-container">
                                    <img src="https://itdeskindia.com/wp-content/uploads/2021/02/books-min.png" class="lazy-load" />
                                </div>
                                <div class="content-container">
                                    <h3 class="title">Study Material, Assignments and Projects</h3>
                                    <p class="description"></p>
                                </div>
                            </div>
                        </div>

                    </div>
                    <br /> <br />
                    <div class="section-title-wrap text-center">
                        <h3 class="heading"><span class="text-color-primary" style={{ fontSize: "21px" }}> Course Highlights</span></h3>
                    </div>
                    <div class="row">
                        <div class="d-flex flex-row flex-lg-column align-items-lg-center mx-auto">
                            <div class="dashed-border">
                                <img class="d-block d-lg-none" src="https://itdeskindia.com/wp-content/themes/itdesk_theme/assets/images/dotted_line_mobile.svg" />
                                <img class="d-none d-lg-block" src="https://itdeskindia.com/wp-content/themes/itdesk_theme/assets/images/dotted_line_desktop.svg" />
                            </div>
                            <div class="d-flex flex-column flex-lg-row justify-content-lg-center">


                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-lg-12 col-12">
                            {/* <h3 class="heading" style="font-size: 21px;">Course Overview</h3>  */}
                            <h3 class="heading"><span class="text-color-primary" style={{ fontSize: "21px" }}>Course Overview</span></h3>
                            <div class="vision-content">
                                <h2><strong>Core Java Internship Program: Empowering Future Developers</strong></h2>
                                <p><strong>Table of Contents</strong></p>
                                <ol style={{fontSize:'15px', fontfamily: 'Open Sans sans-serif'}}>
                                    <br />
                                    <br />
                                    <li>Introduction: Embracing the World of Core Java Internship</li>
                                    <li>IT Desk India: Pioneering the Core Java Internship Institute</li>
                                    <li>Core Java Internship Training: Building Strong Foundations</li>
                                    <li>The Core Java Internship Class: A Comprehensive Learning Experience</li>
                                    <li>Core Java Internship Fees: Affordable and Accessible</li>
                                    <li>Core Java Internship in Jaipur: Exploring the City of Culture and Technology</li>
                                    <li>The Significance of Core Java in the Software Industry</li>
                                    <li>Advantages of Joining a Core Java Internship Program</li>
                                    <li>How to Choose the Right Core Java Internship Institute</li>
                                    <li>The Learning Methodology: Hands-on Projects and Real-world Applications</li>
                                    <li>Core Java Internship Certification Program: Topics Covered</li>
                                    <li>Mentorship and Guidance: Nurturing Future Java Developers</li>
                                    <li>Testimonials: Success Stories of Core Java Interns</li>
                                    <li>Embark on Your Java Journey Today!</li>
                                    <br />
                                    <br />
                                </ol>
                                <h2><strong>Introduction: Embracing the World of Core Java Internship</strong></h2>
                                <p>Welcome to the realm of Core Java Internship, a stepping stone towards a rewarding career in software development. Java, a versatile and widely-used programming language, lays the foundation for building robust applications, web platforms, and mobile solutions. At <strong>IT Desk</strong> India, we offer an exceptional Core Java Internship Program in Jaipur, empowering aspiring developers to harness the full potential of Java.</p>
                                <br />

                                <h2><strong>IT Desk India: Pioneering the Core Java Internship Institute</strong></h2>
                                <p>IT Desk India stands as a trailblazer in the domain of technology education, and our Core Java Internship Institute is a testament to our commitment to nurturing talent. Our institute boasts state-of-the-art facilities, experienced instructors, and a supportive learning environment to facilitate holistic growth.</p>
                                <br />
                                <h2><strong>Core Java Internship Training: Building Strong Foundations</strong></h2>
                                <p>Our <strong>Core Java Internship Training</strong> is designed meticulously to instill a strong understanding of Java fundamentals. From variables and data types to classes and objects, our students gain hands-on experience to write efficient Java code.</p>
                                <br />
                                <h2><strong>The Core Java Internship Class: A Comprehensive Learning Experience</strong></h2>
                                <p>Joining our Core Java Internship Class in Jaipur at IT Desk provides students with a comprehensive learning experience. We encourage interactive sessions, group projects, and code challenges to ensure that students gain practical insights into the language.</p>
                                <br />
                                <br />
                                <h2><strong>Core Java Internship Fees: Affordable and Accessible</strong></h2>
                                <p>At IT Desk India, we believe that quality education should be accessible to all. Our Core Java Internship Fees are budget-friendly, making it an ideal choice for students from diverse backgrounds.</p>
                                <br />
                                <h2><strong>Core Java Internship in Jaipur: Exploring the City of Culture and Technology</strong></h2>
                                <p>Nestled in the vibrant city of Jaipur, our Core Java Internship offers more than just technical knowledge. Students get to immerse themselves in the rich culture and heritage of Rajasthan, creating unforgettable memories.</p>
                                <br />
                                <p><strong>The Significance of Core Java in the Software Industry</strong></p>

                                <p>Java continues to be a preferred language for the software industry due to its portability, scalability, and security features. Discover why Core Java skills are in high demand among employers worldwide.</p>
                                <br />
                                <p><strong>Advantages of Joining a Core Java Internship Program</strong></p>
                                <br />
                                <p>Enrolling in a Core Java Internship Program unlocks numerous benefits, including hands-on learning, networking opportunities, and a chance to work on real-world projects.</p>
                                <br />
                                <h2><strong>How to Choose the Right Core Java Internship Institute</strong></h2>
                                <p>Selecting the right Core Java Internship Institute can be daunting. We provide valuable tips to help you make an informed decision and find the best fit for your learning journey. An IT Desk is the right institute for you that Provides core Java Internships at a very low cost in an efficient manner.</p>
                                <br />
                                <p><strong>IT DESK</strong> provides professional software training on a vast array of courses in the fields of <strong>Computer Science and Information Technology</strong>. Since its founding in 2016, <strong>IT DESK</strong> has provided training to over 10Thousands+ students and professionals from various countries.</p>
                                <br />
                                <p>Since its inception, in a very short span of time, with the qualitative services of training and well-designed training modules, <strong>IT DESK</strong> has expanded its network and become the topmost leading franchise network. <em><strong>IT DESK proudly owns 35+ franchise centers in various parts of the country</strong></em>. Furthermore, we are now expanding our network center in various countries in the world.</p>
                                <br />
                                <p><strong>The Learning Methodology: Hands-on Projects and Real-world Applications</strong></p>
                                <br />
                                <p>Our learning methodology revolves around practical applications, empowering students to build projects that simulate real-world scenarios, and enhancing their problem-solving skills.</p>
                                <br />
                                <h2><strong>Core Java Internship Certification Program: Topics Covered</strong></h2>
                                <br />
                                <p>Explore the diverse range of topics covered in our Core Java Internship Curriculum, from the basics of Java programming to advanced concepts such as multithreading and networking.</p>
                                <br />
                                <p><strong>Mentorship and Guidance: Nurturing Future Java Developers</strong></p>
                                <br />
                                <p>At IT Desk India, we understand the importance of mentorship. Our instructors serve as guides, offering personalized attention and career advice to aspiring Java developers.</p>
                                <br />
                                <p><strong>Testimonials: Success Stories of Core Java Interns</strong></p>
                                <br />
                                <p>Read inspiring success stories from our past Core Java Interns, as they share their transformational journeys and how the internship program paved the way for their success.</p>
                                <p><strong>Embark on Your Java Journey Today!</strong></p>
                                <br />
                                <p>In conclusion, the Core Java Internship Program at IT Desk India is a gateway to a promising career in software development. Join us today and embark on an exciting journey of learning, growth, and opportunities.</p>
                            </div>



                        </div>
                    </div>
                </div>
            </div>
            {/* <div class="accordion-wrapper">
                <div class="container">
                    <br />
                    <br />
                    <h3 class="heading"><span class="text-color-primary" style={{ fontSize: "21px" }}>Course Contents</span></h3>
                    <p>The studio was keen to create an identity that would not interfere with any of the existing brands, settling on a helicoid system that can be easily adapted to “work with any colour system”. </p>
                    <div class="row ">
                        <div class="col-lg-8">
                            <div class="faq-two-wrapper section-space--mt_40">
                                <div id="accordion_two">
                                    <div class="card">
                                        <div class="card-header" id="heading__110">
                                            <h5 class="mb-0">
                                                <button class="btn-link collapsed" data-toggle="collapse" data-target="#collapse__110" aria-expanded="false" aria-controls="collapse__110">
                                                    Core Java<span>
                                                        <i class="far fa-caret-circle-down"></i>
                                                        <i class="far fa-caret-circle-right"></i>  </span>
                                                </button>
                                            </h5>
                                        </div>
                                        <div id="collapse__110" class="collapse" aria-labelledby="heading__110" data-parent="#accordion_two">
                                            <div class="card-body">
                                                <p><ul>
                                                    <li>Introduction to Java Programming</li>
                                                    <li>Java Fundamentals and Control Structure</li>
                                                    <li>Object-Oriented Programming</li>
                                                    <li>Packages and Interfaces</li>
                                                    <li>Exception Handling</li>
                                                    <li>File Handling</li>
                                                    <li>Threading</li>
                                                    <li>String Handling</li>
                                                </ul>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4">

                            <div class="contact-info style-two text-left">
                                <div class="contact-list-item">
                                    <a href="tel:7378123128" class="single-contact-list">
                                        <div class="content-wrap">
                                            <div class="content">
                                                <div class="icon">
                                                    <span class="fal fa-phone"></span>
                                                </div>
                                                <div class="main-content">
                                                    <h6 class="heading">Call for advice now!</h6>
                                                    <div class="text" style={{ fontSize: "22px" }}>7378123128</div>
                                                </div>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div> */}


            <div class="contact-us-section-wrappaer ">
                <div class="container">
                    <div class="row align-items-center">
                        <div class="col-lg-7 col-lg-7">
                            <div class="conact-us-wrap-one">
                                <h5 class="heading mb-20">How will you get your certificate?</h5>
                                <div class="center_details">
                                    {/* <h5 class="heading mb-20">CERTIFICATE FAQ</h5>  */}
                                    <p style={{ textAlign: "justify" }}>IT DESK is a pioneer in CAD/CAM/CAE training and most of the industries give great recognition to its certificate throughout India and abroad. IT DESK Certificates are ISO verified which makes it highly reputed comparing to the other local brands and helps the student get prioritize while going for job opportunities in the industry. IT DESK Software Training is conducted by highly knowledgeable trainers which help students to enhance their skill set by putting great emphasizes on project-based learning.</p>
                                    {/* <a class="btn btn-primary">Get Certificate</a> */}
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-5 col-lg-5">
                            <div class="contact-form-wrap">
                                {/* <h5 class="heading mb-20">How will you get your certificate?</h5> */}
                                <img src="assests/images/cert11.jpg" style={{ width: "100%;", height: "370px" }} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>



        </Fragment >
    )
}

export default Corejava
