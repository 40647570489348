import React, { Fragment } from 'react';
import Menu from './Menu';
import Footer from '../components/Footer';
import Navbar from '../components/Navbar';
import WhyUs from '../components/WhyUs';

const About = () => {
	return (
		<Fragment>


			<div className="banner-agile-2">
				
				<div className="navigation-w3ls">
					<nav className="navbar navbar-expand-lg navbar-light bg-light sticky-nav">
						<button className="navbar-toggler mx-auto" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
							aria-expanded="false" aria-label="Toggle navigation">
							<span className="navbar-toggler-icon"></span>
						</button>
						<div className="collapse navbar-collapse text-center" id="navbarSupportedContent">
							<ul className="navbar-nav justify-content-center">
								<li className="nav-item active">

									<a className="nav-link text-white" href="/">Home
										<span className="sr-only">(current)</span>
									</a>
								</li>
								<li className="nav-item">
									<a className="nav-link text-white" href="/about">About Us</a>
								</li>
								<li className="nav-item">
									<a className="nav-link text-white" href="/allprogram">All Programs</a>
								</li>
								
								<li className="nav-item">
									<a className="nav-link text-white" href="/Onlineprogram">Online Programs</a>
								</li>
								<li className="nav-item">
									<a className="nav-link text-white" href="/gallery1">Gallery</a>
								</li>
								<li className="nav-item">
									<a className="nav-link text-white" href="/internship">Internship</a>
								</li>
								<li className="nav-item">
									<a className="nav-link text-white" href="/placement">Placement</a>
								</li>
								{/* 
                                <li className="nav-item dropdown">
                                    <a className="nav-link dropdown-toggle text-white" href="#" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        Courses
                                    </a>  
                                </li> */}

								{/* <li className="nav-item">
									<a className="nav-link text-white" href="/courses">courses</a>
								</li> */}
								<li className="nav-item">
									<a className="nav-link text-white" href="/contactus">Contactus</a>
								</li>


							</ul>
						</div>
					</nav>
				</div>
			</div>
			<nav aria-label="breadcrumb" className="fixed-header">
				<ol className="breadcrumb" style={{ fontSize: "14px", fontFamily:"'Open Sans', sans-serif",padding : (".5em",".5em"), backgroundColor: "#e9ecef"}}>
					<li className="breadcrumb-item" >
						<a href="/">Home</a>
					</li>
					<li className="breadcrumb-item active" aria-current="page" >About Us</li>
				</ol>
			</nav>

			<div className="about-page py-5">
				<div className="container py-xl-5 py-lg-3">
					<h3 className="title text-capitalize font-weight-light text-dark text-center mb-5">about
						<span className="font-weight-bold"> us</span>
					</h3>
					<div className="row about-head-wthree">
						<div className="col-lg-6 left-abw3ls">
							<img src="assests/images/IMG1.jpg" alt="" className="img-fluid" />
						</div>
						<div className="col-lg-6 right-abw3ls mt-lg-0 mt-sm-5 mt-4">
							<h4 className="font-italic border-bottom text-center font-weight-bold pb-3 mb-4">Our History</h4>
							<p>The world is now witnessing a boom in the information technology industry, which has led to the emergence of a large number of jobs and opportunities; nevertheless, there is a gap between employers and workers since there are not enough skilled persons. </p>
							<p className="mt-3"> I hope that everyone of our students is able to realize their full potential and seize many such opportunities on the market..</p>
						</div>
					</div>
				</div>
			</div>

			<div className="about-page-2 py-5">
				<div className="container-fluid py-xl-5 py-lg-3">
					<div className="row about-head-wthree-2">
						<div className="col-lg-4 col-12 left-abw3ls text-lg-left text-center">
							<img src="assests/images/abt2.jpeg" alt="" className="img-fluid" />
						</div>
						<div className="col-lg-4 col-12 right-abw3ls my-lg-0 my-sm-5 my-4">
							<h4 className="font-italic border-bottom text-center font-weight-bold pb-3 mb-4">Our Mission</h4>
							<p>In partnership with others on the team, you will create enhancements and/or new services and solutions based data acquisition and analytics. You will analyze, design, program, debug and modify software interface for our system. </p>
							<p className="mt-3">.</p>
						</div>
						<div className="col-lg-4 col-12 left-abw3ls text-lg-left text-center">
							<img src="assests/images/abt3.jpeg" alt="" className="img-fluid" />
						</div>
					</div>
				</div>
			</div>

          <WhyUs />
			{/* <div className="team py-5">
				<div className="container py-xl-5 py-lg-3">
					<h3 className="title text-capitalize font-weight-light text-dark text-center mb-5">our
						<span className="font-weight-bold">Instructors</span>
					</h3>
					<div className="row inst-grids pt-md-4">
						<div className="col-md-3 col-6 blog-gd-w3ls">
							<img src="assests/images/team1.jpg" alt=" " className="img-fluid" />
							<div className="date-w3">
								<h4>Michael King</h4>
								<p className="para-w3-agile">Phasellus sed iaculis</p>
								<div className="agileinfo-social-grids">
									<ul>
										<li>
											<a href="#">
												<i className="fab fa-facebook-f"></i>
											</a>
										</li>
										<li>
											<a href="#">
												<i className="fab fa-twitter"></i>
											</a>
										</li>
										<li>
											<a href="#">
												<i className="fas fa-rss"></i>
											</a>
										</li>
									</ul>
								</div>
							</div>
						</div>
						<div className="col-md-3 col-6 blog-gd-w3ls">
							<img src="assests/images/team2.jpg" alt=" " className="img-fluid" />
							<div className="date-w3">
								<h4>Katherin Tresa</h4>
								<p className="para-w3-agile">Phasellus sed iaculis</p>
								<div className="agileinfo-social-grids">
									<ul>
										<li>
											<a href="#">
												<i className="fab fa-facebook-f"></i>
											</a>
										</li>
										<li>
											<a href="#">
												<i className="fab fa-twitter"></i>
											</a>
										</li>
										<li>
											<a href="#">
												<i className="fas fa-rss"></i>
											</a>
										</li>
									</ul>
								</div>
							</div>
						</div>
						<div className="col-md-3 col-6 blog-gd-w3ls  last mt-md-0 mt-sm-5 mt-4">
							<img src="assests/images/team3.jpg" alt=" " className="img-fluid" />
							<div className="date-w3">
								<h4>Steven Monroe</h4>
								<p className="para-w3-agile">Phasellus sed iaculis</p>
								<div className="agileinfo-social-grids">
									<ul>
										<li>
											<a href="#">
												<i className="fab fa-facebook-f"></i>
											</a>
										</li>
										<li>
											<a href="#">
												<i className="fab fa-twitter"></i>
											</a>
										</li>
										<li>
											<a href="#">
												<i className="fas fa-rss"></i>
											</a>
										</li>
									</ul>
								</div>
							</div>
						</div>
						<div className="col-md-3 col-6 blog-gd-w3ls last mt-md-0 mt-sm-5 mt-4">
							<img src="assests/images/team4.jpg" alt=" " className="img-fluid" />
							<div className="date-w3">
								<h4>Khamok Mokai</h4>
								<p className="para-w3-agile">Phasellus sed iaculis</p>
								<div className="agileinfo-social-grids">
									<ul>
										<li>
											<a href="#">
												<i className="fab fa-facebook-f"></i>
											</a>
										</li>
										<li>
											<a href="#">
												<i className="fab fa-twitter"></i>
											</a>
										</li>
										<li>
											<a href="#">
												<i className="fas fa-rss"></i>
											</a>
										</li>
									</ul>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div> */}


			{/* <div className="why-choose-agile py-5">
				<div className="container py-xl-5 py-lg-3">
					<h3 className="title text-capitalize font-weight-light text-white text-center mb-5">what we
						<span className="font-weight-bold">do</span>
					</h3>
					<div className="row agileits-w3layouts-grid pt-md-4">
						<div className="col-lg-4 services-agile-1">
							<div className="row wthree_agile_us">
								<div className="col-lg-3 col-md-2 col-3  agile-why-text">
									<div className="wthree_features_grid text-center p-3 border rounded">
										<i className="fab fa-accusoft"></i>
									</div>
								</div>
								<div className="col-9 agile-why-text-2">
									<h4 className="text-capitalize text-white font-weight-bold mb-3">special education</h4>
									<p>Lorem ipsum magna, vehicula ut scelerisque ornare porta ete celerisque ornare.</p>
									<a className="btn mt-3 service-button p-0" href="/about" role="button">Read More
										<i className="fas fa-long-arrow-alt-right ml-1"></i>
									</a>
								</div>
							</div>
						</div>
						<div className="col-lg-4 services-agile-1 my-lg-0 my-5">
							<div className="row wthree_agile_us">
								<div className="col-lg-3 col-md-2 col-3  agile-why-text">
									<div className="wthree_features_grid text-center p-3 border rounded">
										<i className="fas fa-book"></i>
									</div>
								</div>
								<div className="col-9 agile-why-text-2">
									<h4 className="text-capitalize text-white font-weight-bold mb-3">full day session</h4>
									<p>Lorem ipsum magna, vehicula ut scelerisque ornare porta ete celerisque ornare.</p>
									<a className="btn mt-3 service-button p-0" href="/about" role="button">Read More
										<i className="fas fa-long-arrow-alt-right ml-1"></i>
									</a>
								</div>
							</div>
						</div>
						<div className="col-lg-4 services-agile-1">
							<div className="row wthree_agile_us">
								<div className="col-lg-3 col-md-2 col-3  agile-why-text">
									<div className="wthree_features_grid text-center p-3 border rounded">
										<i className="fas fa-users"></i>
									</div>
								</div>
								<div className="col-9 agile-why-text-2">
									<h4 className="text-capitalize text-white font-weight-bold mb-3">qualified teachers</h4>
									<p>Lorem ipsum magna, vehicula ut scelerisque ornare porta ete celerisque ornare.</p>
									<a className="btn mt-3 service-button p-0" href="/about" role="button">Read More
										<i className="fas fa-long-arrow-alt-right ml-1"></i>
									</a>
								</div>
							</div>
						</div>
					</div>
					<div className="row agileits-w3layouts-grid mt-5">
						<div className="col-lg-4 services-agile-1">
							<div className="row wthree_agile_us">
								<div className="col-lg-3 col-md-2 col-3  agile-why-text">
									<div className="wthree_features_grid text-center p-3 border rounded">
										<i className="far fa-calendar-alt"></i>
									</div>
								</div>
								<div className="col-9 agile-why-text-2">
									<h4 className="text-capitalize text-white font-weight-bold mb-3">events</h4>
									<p>Lorem ipsum magna, vehicula ut scelerisque ornare porta ete celerisque ornare.</p>
									<a className="btn mt-3 service-button p-0" href="/about" role="button">Read More
										<i className="fas fa-long-arrow-alt-right ml-1"></i>
									</a>
								</div>
							</div>
						</div>
						<div className="col-lg-4 services-agile-1  my-lg-0 my-5">
							<div className="row wthree_agile_us">
								<div className="col-lg-3 col-md-2 col-3  agile-why-text">
									<div className="wthree_features_grid text-center p-3 border rounded">
										<i className="fas fa-graduation-cap"></i>
									</div>
								</div>
								<div className="col-9 agile-why-text-2">
									<h4 className="text-capitalize text-white font-weight-bold mb-3">pre classNamees</h4>
									<p>Lorem ipsum magna, vehicula ut scelerisque ornare porta ete celerisque ornare.</p>
									<a className="btn mt-3 service-button p-0" href="/about" role="button">Read More
										<i className="fas fa-long-arrow-alt-right ml-1"></i>
									</a>
								</div>
							</div>
						</div>
						<div className="col-lg-4 services-agile-1">
							<div className="row wthree_agile_us">
								<div className="col-lg-3 col-md-2 col-3  agile-why-text">
									<div className="wthree_features_grid text-center p-3 border rounded">
										<i className="far fa-clock"></i>
									</div>
								</div>
								<div className="col-9 agile-why-text-2">
									<h4 className="text-capitalize text-white font-weight-bold mb-3">24/7 supports</h4>
									<p>Lorem ipsum magna, vehicula ut scelerisque ornare porta ete celerisque ornare.</p>
									<a className="btn mt-3 service-button p-0" href="/about" role="button">Read More
										<i className="fas fa-long-arrow-alt-right ml-1"></i>
									</a>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div> 
			<div className="why-choose-agile py-5">
				<div className="container py-xl-5 py-lg-3">
					<h3 className="title text-capitalize font-weight-light text-white text-center mb-5">what we
						<span className="font-weight-bold">do</span>
					</h3>
					<div className="row agileits-w3layouts-grid pt-md-4">
						<div className="col-lg-4 services-agile-1">
							<div className="row wthree_agile_us">
								<div className="col-lg-3 col-md-2 col-3  agile-why-text">
									<div className="wthree_features_grid text-center p-3 border rounded">
										<i className="fab fa-accusoft"></i>
									</div>
								</div>
								<div className="col-9 agile-why-text-2">
									<h4 className="text-capitalize text-white font-weight-bold mb-3">special education</h4>
									<p>IT Desk offered courses like Advance Excel , Tally GST , C, C++, Java , C# , PHP , Python etc.</p>
									<a className="btn mt-3 service-button p-0" href="/about" role="button">Read More
										<i className="fas fa-long-arrow-alt-right ml-1"></i>
									</a>
								</div>
							</div>
						</div>
						<div className="col-lg-4 services-agile-1 my-lg-0 my-5">
							<div className="row wthree_agile_us">
								<div className="col-lg-3 col-md-2 col-3  agile-why-text">
									<div className="wthree_features_grid text-center p-3 border rounded">
										<i className="fas fa-book"></i>
									</div>
								</div>
								<div className="col-9 agile-why-text-2">
									<h4 className="text-capitalize text-white font-weight-bold mb-3">full day session</h4>
									<p>IT Desk offered advance courses like Android , Advance Java , Machine Learning , Data Science , Digital Marketing , Ethical Hacking etc.</p>
									<a className="btn mt-3 service-button p-0" href="/about" role="button">Read More
										<i className="fas fa-long-arrow-alt-right ml-1"></i>
									</a>
								</div>
							</div>
						</div>
						<div className="col-lg-4 services-agile-1">
							<div className="row wthree_agile_us">
								<div className="col-lg-3 col-md-2 col-3  agile-why-text">
									<div className="wthree_features_grid text-center p-3 border rounded">
										<i className="fas fa-users"></i>
									</div>
								</div>
								<div className="col-9 agile-why-text-2">
									<h4 className="text-capitalize text-white font-weight-bold mb-3">qualified teachers</h4>
									<p>IT Desk 20 years vast experience in training field online and offline training, on demand we provide online training for students</p>
									<a className="btn mt-3 service-button p-0" href="/about" role="button">Read More
										<i className="fas fa-long-arrow-alt-right ml-1"></i>
									</a>
								</div>
							</div>
						</div>
					</div>
					<div className="row agileits-w3layouts-grid mt-5">
						<div className="col-lg-4 services-agile-1">
							<div className="row wthree_agile_us">
								<div className="col-lg-3 col-md-2 col-3  agile-why-text">
									<div className="wthree_features_grid text-center p-3 border rounded">
										<i className="far fa-calendar-alt"></i>
									</div>
								</div>
								<div className="col-9 agile-why-text-2">
									<h4 className="text-capitalize text-white font-weight-bold mb-3">events</h4>
									<p> IT DESK has provided training to over 10 Thousands+ students and professionals of various countries.</p>
									<a className="btn mt-3 service-button p-0" href="/about" role="button">Read More
										<i className="fas fa-long-arrow-alt-right ml-1"></i>
									</a>
								</div>
							</div>
						</div>
						<div className="col-lg-4 services-agile-1  my-lg-0 my-5">
							<div className="row wthree_agile_us">
								<div className="col-lg-3 col-md-2 col-3  agile-why-text">
									<div className="wthree_features_grid text-center p-3 border rounded">
										<i className="fas fa-graduation-cap"></i>
									</div>
								</div>
								<div className="col-9 agile-why-text-2">
									<h4 className="text-capitalize text-white font-weight-bold mb-3">pre classNamees</h4>
									<p>ESK has expanded its network and become the topmost leading franchise network.</p>
									<a className="btn mt-3 service-button p-0" href="/about" role="button">Read More
										<i className="fas fa-long-arrow-alt-right ml-1"></i>
									</a>
								</div>
							</div>
						</div>
						<div className="col-lg-4 services-agile-1">
							<div className="row wthree_agile_us">
								<div className="col-lg-3 col-md-2 col-3  agile-why-text">
									<div className="wthree_features_grid text-center p-3 border rounded">
										<i className="far fa-clock"></i>
									</div>
								</div>
								<div className="col-9 agile-why-text-2">
									<h4 className="text-capitalize text-white font-weight-bold mb-3">24/7 supports</h4>
									<p>? Get in touch with our support and sales team via email or talk to them using our toll-free numbers.</p>
									<a className="btn mt-3 service-button p-0" href="/about" role="button">Read More
										<i className="fas fa-long-arrow-alt-right ml-1"></i>
									</a>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>*/}




			{/* <div className="video-choose-agile py-5">
				<div className="container py-xl-5 py-lg-3">
					<div className="row">
						<div className="col-lg-7 video">
							<h3 className="title text-capitalize font-weight-light text-dark text-center mb-5">our
								<span className="font-weight-bold">video</span>
							</h3>
							<iframe src="assests/images/abtvid1.mp4"></iframe> 
							<video controls width="100%">
								<source src="assests/images/abtvid1.mp4" type="video/webm" />
								<source src="/video-example.mp4" type="video/mp4"/> 
								Sorry, your browser doesn't support videos.
							</video>
						</div>
						<div className="col-lg-5 events">
							<h3 className="title text-capitalize font-weight-light text-dark text-center mb-5">upcoming
								<span className="font-weight-bold">events</span>
							</h3>
							<div className="events-w3ls">
								<div className="d-flex">
									<div className="col-sm-2 col-3 events-up p-3 text-center">
										<h5 className="text-white font-weight-bold">28
											<span className="border-top font-weight-light pt-2 mt-2">May</span>
										</h5>
									</div>
									<div className="col-sm-10 col-9 events-right">
										<h4 className="text-dark">Admission Fair Spring </h4>
										<ul className="list-unstyled">
											<li className="my-2">
												<i className="far fa-clock mr-2"></i>5.00 pm - 7.30 pm</li>
											<li>
												<i className="fas fa-map-marker mr-2"></i>25 Newyork City.</li>
										</ul>
									</div>
								</div>
							</div>
							<div className="d-flex my-4">
								<div className="col-sm-2 col-3 events-up p-3 text-center">
									<h5 className="text-white font-weight-bold">28
										<span className="border-top font-weight-light pt-2 mt-2">May</span>
									</h5>
								</div>
								<div className="col-sm-10 col-9 events-right">
									<h4 className="text-dark">Admission Fair Spring </h4>
									<ul className="list-unstyled">
										<li className="my-2">
											<i className="far fa-clock mr-2"></i>5.00 pm - 7.30 pm</li>
										<li>
											<i className="fas fa-map-marker mr-2"></i>25 Newyork City.</li>
									</ul>
								</div>
							</div>
							<div className="d-flex">
								<div className="col-sm-2 col-3 events-up p-3 text-center">
									<h5 className="text-white font-weight-bold">28
										<span className="border-top font-weight-light pt-2 mt-2">May</span>
									</h5>
								</div>
								<div className="col-sm-10 col-9 events-right">
									<h4 className="text-dark">Admission Fair Spring </h4>
									<ul className="list-unstyled">
										<li className="my-2">
											<i className="far fa-clock mr-2"></i>5.00 pm - 7.30 pm</li>
										<li>
											<i className="fas fa-map-marker mr-2"></i>25 Newyork City.</li>
									</ul>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>


			<div className="testimonials py-5">
				<div className="container py-xl-5 py-lg-3">
					<h3 className="title text-capitalize font-weight-light text-white text-center mb-5">what our
						<span className="font-weight-bold">people says</span>
					</h3>
					<div className="mis-stage">
						<p>Founder's Message <br />
							After building our brand recognition in the CAD industry with over 170+ centers around India, we decided to continue on the path that lead to the creation of IT desk. The objective of the IT desk is to enhance the students' skill sets by offering training from the most basic to the most sophisticated levels. Our highly trained training team allows us to provide the most effective training in the industry. We stay abreast of the ever-changing technology scene and present them with a variety of educational options.
						</p>


					</div>
				</div>
			</div> */}

			{/* <div className="news-section py-5">
				<div className="container py-xl-5 py-lg-3">
					<h3 className="title text-capitalize font-weight-light text-dark text-center mb-5">our lastest
						<span className="font-weight-bold">news</span>
					</h3>
					<div className="row news-grids-w3l pt-md-4">
						<div className="col-md-4 news-grid">
							<a href="blog.html">
								<img src="assests/images/n1.jpg" className="img-fluid" alt="" />
							</a>
							<div className="news-text">
								<div className="news-events-agile event-colo1 py-2 px-3">
									<h5 className="float-left">
										<a href="blog.html" className="text-white">07 May</a>
									</h5>
									<div className="post-img float-right">
										<ul>
											<li>
												<a href="#">
													<i className="far fa-comments text-white"></i>
												</a>
											</li>
											<li className="mx-3">
												<a href="#">
													<i className="far fa-heart text-white"></i>
												</a>
											</li>
											<li>
												<a href="#">
													<i className="fas fa-share text-white"></i>
												</a>
											</li>
										</ul>
									</div>
									<div className="clearfix"></div>
								</div>
								<div className="detail-bottom">
									<h6 className="my-3">
										<a href="blog.html" className="text-dark">
											Eiusmod tempor incididunt ut labore sed do eiusmod
										</a>
									</h6>
									<p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore sed do eiusmod
										tempor incididunt ut labore</p>
								</div>
							</div>
						</div>
						<div className="col-md-4 news-grid my-md-0 my-sm-5 my-4">
							<a href="blog.html">
								<img src="assests/images/n2.jpg" className="img-fluid" alt="" />
							</a>
							<div className="news-text">
								<div className="news-events-agile py-2 px-3">
									<h5 className="float-left">
										<a href="blog.html" className="text-white">07 May</a>
									</h5>
									<div className="post-img float-right">
										<ul>
											<li>
												<a href="#">
													<i className="far fa-comments text-white"></i>
												</a>
											</li>
											<li className="mx-3">
												<a href="#">
													<i className="far fa-heart text-white"></i>
												</a>
											</li>
											<li>
												<a href="#">
													<i className="fas fa-share text-white"></i>
												</a>
											</li>
										</ul>
									</div>
									<div className="clearfix"></div>
								</div>
								<div className="detail-bottom">
									<h6 className="my-3">
										<a href="blog.html" className="text-dark">
											Eiusmod tempor incididunt ut labore sed do eiusmod
										</a>
									</h6>
									<p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore sed do eiusmod
										tempor incididunt ut labore</p>
								</div>
							</div>
						</div>
						<div className="col-md-4 news-grid">
							<a href="blog.html">
								<img src="assests/images/n3.jpg" className="img-fluid" alt="" />
							</a>
							<div className="news-text">
								<div className="news-events-agile event-colo3 py-2 px-3">
									<h5 className="float-left">
										<a href="blog.html" className="text-white">07 May</a>
									</h5>
									<div className="post-img float-right">
										<ul>
											<li>
												<a href="#">
													<i className="far fa-comments text-white"></i>
												</a>
											</li>
											<li className="mx-3">
												<a href="#">
													<i className="far fa-heart text-white"></i>
												</a>
											</li>
											<li>
												<a href="#">
													<i className="fas fa-share text-white"></i>
												</a>
											</li>
										</ul>
									</div>
									<div className="clearfix"></div>
								</div>
								<div className="detail-bottom">
									<h6 className="my-3">
										<a href="blog.html" className="text-dark">
											Eiusmod tempor incididunt ut labore sed do eiusmod
										</a>
									</h6>
									<p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore sed do eiusmod
										tempor incididunt ut labore</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div> */}
			{/* <div className="news-section py-5">
				<div className="container py-xl-5 py-lg-3">
					<h3 className="title text-capitalize font-weight-light text-dark text-center mb-5">our lastest
						<span className="font-weight-bold">News</span>
					</h3>
					<div className="row news-grids-w3l pt-md-4">
						<div className="col-md-4 news-grid">
							<a href="blog.html">
								<img src="assests/images/background.jpeg" className="img-fluid" alt="" />
							</a>
							<div className="news-text">
								<div className="news-events-agile event-colo1 py-2 px-3">
									<h5 className="float-left">
										<a href="blog.html" className="text-white">07 May</a>
									</h5>
									<div className="post-img float-right">
										<ul>
											<li>
												<a href="#">
													<i className="far fa-comments text-white"></i>
												</a>
											</li>
											<li className="mx-3">
												<a href="#">
													<i className="far fa-heart text-white"></i>
												</a>
											</li>
											<li>
												<a href="#">
													<i className="fas fa-share text-white"></i>
												</a>
											</li>
										</ul>
									</div>
									<div className="clearfix"></div>
								</div>
								<div className="detail-bottom">
									<h6 className="my-3">
										<a href="blog.html" className="text-dark">
											Internship
										</a>
									</h6>
									<p>An internship is a structured and supervised work experience that allows individuals, typically students or recent graduates, to gain practical exposure and insights into their chosen fields of study or career paths.</p>
								</div>
							</div>
						</div>
						<div className="col-md-4 news-grid my-md-0 my-sm-5 my-4">
							<a href="blog.html">
								<img src="assests/images/background.jpeg" className="img-fluid" alt="" />
							</a>
							<div className="news-text">
								<div className="news-events-agile py-2 px-3">
									<h5 className="float-left">
										<a href="blog.html" className="text-white">07 May</a>
									</h5>
									<div className="post-img float-right">
										<ul>
											<li>
												<a href="#">
													<i className="far fa-comments text-white"></i>
												</a>
											</li>
											<li className="mx-3">
												<a href="#">
													<i className="far fa-heart text-white"></i>
												</a>
											</li>
											<li>
												<a href="#">
													<i className="fas fa-share text-white"></i>
												</a>
											</li>
										</ul>
									</div>
									<div className="clearfix"></div>
								</div>
								<div className="detail-bottom">
									<h6 className="my-3">
										<a href="blog.html" className="text-dark">
											Courses
										</a>
									</h6>
									<p>Courses are structured educational programs designed to impart knowledge, skills, and expertise in a specific subject or field of study. They are offered at various academic levels and cater to individuals with diverse interests and career goals.</p>
								</div>
							</div>
						</div>
						<div className="col-md-4 news-grid">
							<a href="blog.html">
								<img src="assests/images/background.jpeg" className="img-fluid" alt="" />
							</a>
							<div className="news-text">
								<div className="news-events-agile event-colo3 py-2 px-3">
									<h5 className="float-left">
										<a href="blog.html" className="text-white">07 May</a>
									</h5>
									<div className="post-img float-right">
										<ul>
											<li>
												<a href="#">
													<i className="far fa-comments text-white"></i>
												</a>
											</li>
											<li className="mx-3">
												<a href="#">
													<i className="far fa-heart text-white"></i>
												</a>
											</li>
											<li>
												<a href="#">
													<i className="fas fa-share text-white"></i>
												</a>
											</li>
										</ul>
									</div>
									<div className="clearfix"></div>
								</div>
								<div className="detail-bottom">
									<h6 className="my-3">
										<a href="blog.html" className="text-dark">
											Placement
										</a>
									</h6>
									<p>Placement refers to the process of assisting individuals, usually students or job seekers, in finding suitable employment opportunities that align with their skills, qualifications, and career aspirations. </p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div> */}




			{/* <div className="brands-w3ls">
				<div className="container py-xl-3">
					<ul className="list-unstyled">
						<li>
							<i className="fab fa-supple"></i>
						</li>
						<li>
							<i className="fab fa-angrycreative"></i>
						</li>
						<li>
							<i className="fab fa-aviato"></i>
						</li>
						<li>
							<i className="fab fa-aws"></i>
						</li>
						<li>
							<i className="fab fa-cpanel"></i>
						</li>
						<li>
							<i className="fab fa-hooli"></i>
						</li>
						<li>
							<i className="fab fa-node"></i>
						</li>
					</ul>
				</div>
			</div> */}


			{/* >

	<!-- Js files -->
	<!-- JavaScript -->
	<script src="js/jquery-2.2.3.min.js"></script>
	<!-- Default-JavaScript-File -->
	<script src="js/bootstrap.js"></script>
	<!-- Necessary-JavaScript-File-For-Bootstrap -->

	<!-- testimonial-plugin -->
	<script src="js/mislider.js"></script>
	<script>
		jQuery(function ($) {
			var slider = $('.mis-stage').miSlider({
				//  The height of the stage in px. Options: false or positive integer. false = height is calculated using maximum slide heights. Default: false
				stageHeight: 320,
				//  Number of slides visible at one time. Options: false or positive integer. false = Fit as many as possible.  Default: 1
				slidesOnStage: false,
				//  The location of the current slide on the stage. Options: 'left', 'right', 'center'. Defualt: 'left'
				slidePosition: 'center',
				//  The slide to start on. Options: 'beg', 'mid', 'end' or slide number starting at 1 - '1','2','3', etc. Defualt: 'beg'
				slideStart: 'mid',
				//  The relative percentage scaling factor of the current slide - other slides are scaled down. Options: positive number 100 or higher. 100 = No scaling. Defualt: 100
				slideScaling: 150,
				//  The vertical offset of the slide center as a percentage of slide height. Options:  positive or negative number. Neg value = up. Pos value = down. 0 = No offset. Default: 0
				offsetV: -5,
				//  Center slide contents vertically - Boolean. Default: false
				centerV: true,
				//  Opacity of the prev and next button navigation when not transitioning. Options: Number between 0 and 1. 0 (transparent) - 1 (opaque). Default: .5
				navButtonsOpacity: 1,
			});
		});
	</script>
	<!-- //testimonial-plugin -->

	<!-- smooth scrolling -->
	<script src="js/SmoothScroll.min.js"></script>
	<!-- //smooth scrolling -->

	<!-- move-top -->
	<script src="js/move-top.js"></script>
	<!-- easing -->
	<script src="js/easing.js"></script>
	<!--  necessary snippets for few javascript files -->
	<script src="js/edulearn.js"></script>

	<!-- //Js files -- */}
		</Fragment>
	);
};

export default About;