import React, { Fragment } from 'react'

const Gallery = () => {
    return (
        <Fragment>
            

            <div className="banner-agile-2">

                <div className="navigation-w3ls">
                    {/* <nav className="navbar navbar-expand-lg navbar-light bg-light sticky-nav">
                        <button className="navbar-toggler mx-auto" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                            aria-expanded="false" aria-label="Toggle navigation">
                            <span className="navbar-toggler-icon"></span>
                        </button>
                        <div className="collapse navbar-collapse text-center" id="navbarSupportedContent">
                            <ul className="navbar-nav justify-content-center">
                                <li className="nav-item">
                                    <a className="nav-link text-white" href="/">Home
                                        <span className="sr-only">(current)</span>
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link text-white" href="/about">About Us</a>
                                </li>
                                <li className="nav-item dropdown">
                                    <a className="nav-link dropdown-toggle text-white" href="#" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        Courses
                                    </a>
                                    <div className="dropdown-menu">
                                        <a className="dropdown-item" href="language.html">Language</a>
                                        <a className="dropdown-item" href="communication.html">Communication</a>
                                        <a className="dropdown-item" href="business.html">Business</a>
                                        <a className="dropdown-item" href="software.html">Software</a>
                                        <a className="dropdown-item" href="social_media.html">Social Media</a>
                                        <a className="dropdown-item" href="photography.html">Photography</a>
                                        <a className="dropdown-item" href="course_details.html">Course Details</a>
                                        <div className="dropdown-divider"></div>
                                        <a className="dropdown-item" href="form.html">Apply Now</a>
                                    </div>
                                </li>
                                
                                <li className="nav-item active">
                                    <a className="nav-link text-white" href="/gallery">Gallery</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link text-white" href="/contactus">Contact Us</a>
                                </li>
                            </ul>
                        </div>
                    </nav> */}
                </div>

            </div>

            <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                        <a href="/">Home</a>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">Gallery</li>
                </ol>
            </nav>

            <section className="gallery py-5">
                <div className="container py-xl-5 py-lg-3">
                    <h3 className="title text-capitalize font-weight-light text-dark text-center mb-5">our
                        <span className="font-weight-bold">gallery</span>
                    </h3>
                    <div className="inner-sec pt-md-4">
                        <div className="row proj_gallery_grid">
                            <div className="col-sm-4 section_1_gallery_grid">
                                <a href="images/ab.jpg">
                                    <div className="section_1_gallery_grid1">
                                        <img src="images/ab.jpg" alt=" " className="img-fluid" />
                                        <div className="proj_gallery_grid1_pos">
                                            <h3>Edulearn</h3>
                                            <p>Add some text here</p>
                                        </div>
                                    </div>
                                </a>
                            </div>
                            <div className="col-sm-4 section_1_gallery_grid my-sm-0 my-4">
                                <a href="images/n2.jpg">
                                    <div className="section_1_gallery_grid1">
                                        <img src="assests/images/logo1.jpeg" alt=" " className="img-fluid" />
                                        <div className="proj_gallery_grid1_pos">
                                            <h3>Edulearn</h3>
                                            <p>Add some text here</p>
                                        </div>
                                    </div>
                                </a>
                            </div>
                            <div className="col-sm-4 section_1_gallery_grid">
                                <a href="images/n3.jpg">
                                    <div className="section_1_gallery_grid1">
                                        <img src="assests/images/c4.jpg" alt=" " className="img-fluid" />
                                        <div className="proj_gallery_grid1_pos">
                                            <h3>Edulearn</h3>
                                            <p>Add some text here</p>
                                        </div>
                                    </div>
                                </a>
                            </div>
                        </div>
                        <div className="row proj_gallery_grid mt-4">
                            <div className="col-sm-4 section_1_gallery_grid">
                                <a href="images/am1.jpg">
                                    <div className="section_1_gallery_grid1">
                                        <img src="images/am1.jpg" alt=" " className="img-fluid" />
                                        <div className="proj_gallery_grid1_pos">
                                            <h3>Edulearn</h3>
                                            <p>Add some text here</p>
                                        </div>
                                    </div>
                                </a>
                            </div>
                            <div className="col-sm-4 section_1_gallery_grid  my-sm-0 my-4">
                                <a href="images/am2.jpg">
                                    <div className="section_1_gallery_grid1">
                                        <img src="images/am2.jpg" alt=" " className="img-fluid" />
                                        <div className="proj_gallery_grid1_pos">
                                            <h3>Edulearn</h3>
                                            <p>Add some text here</p>
                                        </div>
                                    </div>
                                </a>
                            </div>
                            <div className="col-sm-4 section_1_gallery_grid">
                                <a href="images/ap-2.jpg">
                                    <div className="section_1_gallery_grid1">
                                        <img src="images/ap-2.jpg" alt=" " className="img-fluid" />
                                        <div className="proj_gallery_grid1_pos">
                                            <h3>Edulearn</h3>
                                            <p>Add some text here</p>
                                        </div>
                                    </div>
                                </a>
                            </div>
                        </div>
                        <div className="row proj_gallery_grid mt-4">
                            <div className="col-sm-4 section_1_gallery_grid">
                                <a href="images/am4.jpg">
                                    <div className="section_1_gallery_grid1">
                                        <img src="images/am4.jpg" alt=" " className="img-fluid" />
                                        <div className="proj_gallery_grid1_pos">
                                            <h3>Edulearn</h3>
                                            <p>Add some text here</p>
                                        </div>
                                    </div>
                                </a>
                            </div>
                            <div className="col-sm-4 section_1_gallery_grid  my-sm-0 my-4">
                                <a href="images/n1.jpg">
                                    <div className="section_1_gallery_grid1">
                                        <img src="images/n1.jpg" alt=" " className="img-fluid" />
                                        <div className="proj_gallery_grid1_pos">
                                            <h3>Edulearn</h3>
                                            <p>Add some text here</p>
                                        </div>
                                    </div>
                                </a>
                            </div>
                            <div className="col-sm-4 section_1_gallery_grid">
                                <a href="images/am3.jpg">
                                    <div className="section_1_gallery_grid1">
                                        <img src="images/am3.jpg" alt=" " className="img-fluid" />
                                        <div className="proj_gallery_grid1_pos">
                                            <h3>Edulearn</h3>
                                            <p>Add some text here</p>
                                        </div>
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>



            <div className="brands-w3ls">
                <div className="container py-xl-3">
                    <ul className="list-unstyled">
                        <li>
                            <i className="fab fa-supple"></i>
                        </li>
                        <li>
                            <i className="fab fa-angrycreative"></i>
                        </li>
                        <li>
                            <i className="fab fa-aviato"></i>
                        </li>
                        <li>
                            <i className="fab fa-aws"></i>
                        </li>
                        <li>
                            <i className="fab fa-cpanel"></i>
                        </li>
                        <li>
                            <i className="fab fa-hooli"></i>
                        </li>
                        <li>
                            <i className="fab fa-node"></i>
                        </li>
                    </ul>
                </div>
            </div>



            {/* <footer>
                <div className="container py-4">
                    <div className="row py-xl-5 py-sm-3">
                        <div className="col-lg-6 map">
                            <h2 className="contact-title text-capitalize text-white font-weight-light mb-sm-4 mb-3">our
                                <span className="font-weight-bold">directions</span>
                            </h2>
                            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3023.495758873587!2d-74.0005340845242!3d40.72911557933012!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c25991b7473067%3A0x59fbd02f7b519ce8!2s550+LaGuardia+Pl%2C+New+York%2C+NY+10012%2C+USA!5e0!3m2!1sen!2sin!4v1516166447283"></iframe>
                            <div className="conta-posi-w3ls p-4 rounded">
                                <h5 className="text-white font-weight-bold mb-3">Address</h5>
                                <p>25095 Blue Ravine Rd,
                                    <span>Diamonds street,</span> California, USA</p>
                            </div>
                        </div>
                        <div className="col-lg-6 contact-agileits-w3layouts mt-lg-0 mt-4">
                            <h4 className="contact-title text-capitalize text-white font-weight-light mb-sm-4 mb-3">get in
                                <span className="font-weight-bold">touch</span>
                            </h4>
                            <p className="conta-para-style border-left pl-4">If you have any questions about the services we provide simply use the form below. We try and respond to all queries
                                and comments within 24 hours.</p>
                            <div className="subscribe-w3ls my-xl-5 my-4">
                                <h6 className="text-white text-capitalize mb-4">subscribe our newsletter</h6>
                                <form action="#" method="post" className="subscribe_form">
                                    <input className="form-control" type="email" name="email" placeholder="Enter your email..." required="" />
                                    <button type="submit" className="btn btn-primary submit">Submit</button>
                                </form>
                            </div>
                            <p className="para-agileits-w3layouts text-white">
                                <i className="fas fa-map-marker pr-3"></i>25095 Blue Ravine Rd,USA</p>
                            <p className="para-agileits-w3layouts text-white my-sm-3 my-2">
                                <i className="fas fa-phone pr-3"></i>032 625 4592</p>
                            <p className="para-agileits-w3layouts">
                                <i className="far fa-envelope-open pr-2"></i>
                                <a href="mailto:mail@example.com" className=" text-white">info@example.com</a>
                            </p>
                        </div>
                    </div>
                </div>
                <div className="copyright-agiles py-3">
                    <div className="container">
                        <div className="row">
                            <p className="col-lg-8 copy-right-grids text-white text-lg-left text-center mt-lg-1">© 2018 Edulearn. All Rights Reserved | Design by
                                <a href="https://w3layouts.com/" target="_blank">W3layouts</a>
                            </p>

                            <div className="social-icons text-lg-right text-center col-lg-4 mt-lg-0 mt-3">
                                <ul className="list-unstyled">
                                    <li>
                                        <a href="#" className="fab fa-facebook-f icon-border facebook rounded-circle"> </a>
                                    </li>
                                    <li className="mx-2">
                                        <a href="#" className="fab fa-twitter icon-border twitter rounded-circle"> </a>
                                    </li>
                                    <li>
                                        <a href="#" className="fab fa-google-plus-g icon-border googleplus rounded-circle"> </a>
                                    </li>
                                    <li className="ml-2">
                                        <a href="#" className="fas fa-rss icon-border rss rounded-circle"> </a>
                                    </li>
                                </ul>
                            </div>

                        </div>
                    </div>
                </div>
            </footer> */}


            {/* 
	<!-- Js files -->
	<!-- JavaScript -->
	<script src="js/jquery-2.2.3.min.js"></script>
	<!-- Default-JavaScript-File -->
	<script src="js/bootstrap.js"></script>
	<!-- Necessary-JavaScript-File-For-Bootstrap -->

	<!-- simpleLightbox -->
	<link href="css/simpleLightbox.css" rel='stylesheet' type='text/css' />
	<script src="js/simpleLightbox.js"></script>
	<script>
		$('.proj_gallery_grid a').simpleLightbox();
	</script>
	<!-- //simpleLightbox -->

	<!-- smooth scrolling -->
	<script src="js/SmoothScroll.min.js"></script>
	<!-- //smooth scrolling -->

	<!-- move-top -->
	<script src="js/move-top.js"></script>
	<!-- easing -->
	<script src="js/easing.js"></script>
	<!--  necessary snippets for few javascript files -->
	<script src="js/edulearn.js"></script>

	<!-- //Js files --> */}

        </Fragment>
    )
}

export default Gallery
