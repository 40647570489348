import React, { useState,Fragment } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Login =  ({ onLogin }) => {


    const [formData, setFormData] = useState({
		email: '',
		password: ''
	  });
	
	  const navigate = useNavigate();
	
	  const handleChange = (e) => {
		setFormData({ ...formData, [e.target.name]: e.target.value });
	  };
	
    const handleSubmit = async (e) => {
      e.preventDefault();
      try {
        const response = await axios.post('/api/login', formData);
        const { role } = response.data;
        toast.success('Login successful!', {
          position: 'top-right',
          autoClose: 2000,
          onClose: () => {
            if (role === 'admin') {
              onLogin(true); // Call onLogin callback with true for admin login
              navigate('/');
              
            }
          }
        });
      } catch (error) {
        console.error(error);
        toast.error('Login failed. Please check your credentials.', { position: 'top-right' });
      }
    };
    
    const handleLogout = () => {
      // Perform logout actions here
      navigate('/');
    };
    return (
        <Fragment>

                 


            

            <div className="login-w3ls py-5">
                <div className="container py-xl-5 py-lg-3">
                    <h3 className="title text-capitalize font-weight-light text-dark text-center mb-5">Login
                        <span className="font-weight-bold">now</span>
                    </h3>

                    <div className="sub-main-w3 pt-md-4">



                        <form onSubmit={handleSubmit}  >
                            <div className="form-style-agile form-group">
                                <label>
                                    username
                                    <i className="fas fa-user"></i>
                                </label>
								<input type="email" name="email" className="form-control" placeholder="Email" value={formData.email} onChange={handleChange} required />
                            </div>
                            <div className="form-style-agile form-group">
                                <label>
                                    Password
                                    <i className="fas fa-unlock-alt"></i>
                                </label>
                                <input type="password" name="password"  className="form-control" placeholder="Password" value={formData.password} onChange={handleChange} required />
                            </div>

                            <ul className="list-unstyled list-login">
                              
                                <li className="float-right">
                                    <a href="/" className="text-right text-white text-capitalize">forgot password?</a>
                                </li>
                            </ul>

                            {/* <input type="submit" value="Log In"  /> */}
							<button type="submit">Login</button>
                            <p className="text-center dont-do mt-4 text-white">Don't have an account?
                                <a href="/register" className="text-white  font-weight-bold">
                                    Register Now</a>
                            </p>
                        </form>


						<ToastContainer position="top-right" autoClose={5000} />
                        
                    </div>

                </div>
            </div>

            {/* <div className="brands-w3ls py-md-5 py-4">
                <div className="container py-xl-3">
                    <ul className="list-unstyled">
                        <li>
                            <i className="fab fa-supple"></i>
                        </li>
                        <li>
                            <i className="fab fa-angrycreative"></i>
                        </li>
                        <li>
                            <i className="fab fa-aviato"></i>
                        </li>
                        <li>
                            <i className="fab fa-aws"></i>
                        </li>
                        <li>
                            <i className="fab fa-cpanel"></i>
                        </li>
                        <li>
                            <i className="fab fa-hooli"></i>
                        </li>
                        <li>
                            <i className="fab fa-node"></i>
                        </li>
                    </ul>
                </div>
            </div> */}



        </Fragment>
    )
}

export default Login







