import React from 'react';
import CountUp from 'react-countup';
//import CountUp from 'react-countup/build/CountUp';

// import $ from 'jquery';
// import "jquery-ui-dist/jquery-ui"

const Stats = () => {
    // useEffect(()=> {

    //     // (function($){
    //         $(window).on("load",function(){
    //             $(document).scrollzipInit();
    //             $(document).rollerInit();
    //         });
    //         $(window).on("load scroll resize", function(){
    //             $('.numscroller').scrollzip({
    //                 showFunction    :   function() {
    //                                         numberRoller($(this).attr('data-slno'));
    //                                     },
    //                 wholeVisible    :     false,
    //             });
    //         });
    //         $.fn.scrollzipInit=function(){
    //             $('body').prepend("<div style='position:fixed;top:0px;left:0px;width:0;height:0;' id='scrollzipPoint'></div>" );
    //         };
    //         $.fn.rollerInit=function(){
    //             var i=0;
    //             $('.numscroller').each(function() {
    //                 i++;
    //                $(this).attr('data-slno',i); 
    //                $(this).addClass("roller-title-number-"+i);
    //             });        
    //         };
    //         $.fn.scrollzip = function(options){
    //             var settings = $.extend({
    //                 showFunction    : null,
    //                 hideFunction    : null,
    //                 showShift       : 0,
    //                 wholeVisible    : false,
    //                 hideShift       : 0,
    //             }, options);
    //             return this.each(function(i,obj){
    //                 $(this).addClass('scrollzip');
    //                 if ( $.isFunction( settings.showFunction ) ){
    //                     if(
    //                         !$(this).hasClass('isShown')&&
    //                         ($(window).outerHeight()+$('#scrollzipPoint').offset().top-settings.showShift)>($(this).offset().top+((settings.wholeVisible)?$(this).outerHeight():0))&&
    //                         ($('#scrollzipPoint').offset().top+((settings.wholeVisible)?$(this).outerHeight():0))<($(this).outerHeight()+$(this).offset().top-settings.showShift)
    //                     ){
    //                         $(this).addClass('isShown');
    //                         settings.showFunction.call( this );
    //                     }
    //                 }
    //                 if ( $.isFunction( settings.hideFunction ) ){
    //                     if(
    //                         $(this).hasClass('isShown')&&
    //                         (($(window).outerHeight()+$('#scrollzipPoint').offset().top-settings.hideShift)<($(this).offset().top+((settings.wholeVisible)?$(this).outerHeight():0))||
    //                         ($('#scrollzipPoint').offset().top+((settings.wholeVisible)?$(this).outerHeight():0))>($(this).outerHeight()+$(this).offset().top-settings.hideShift))
    //                     ){
    //                         $(this).removeClass('isShown');
    //                         settings.hideFunction.call( this );
    //                     }
    //                 }
    //                 return this;
    //             });
    //         };
    //         function numberRoller(slno){
    //                 var min=$('.roller-title-number-'+slno).attr('data-min');
    //                 var max=$('.roller-title-number-'+slno).attr('data-max');
    //                 var timediff=$('.roller-title-number-'+slno).attr('data-delay');
    //                 var increment=$('.roller-title-number-'+slno).attr('data-increment');
    //                 var numdiff=max-min;
    //                 var timeout=(timediff*1000)/numdiff;
    //                 //if(numinc<10){
    //                     //increment=Math.floor((timediff*1000)/10);
    //                 //}//alert(increment);
    //                 numberRoll(slno,min,max,increment,timeout);
                    
    //         }
    //         function numberRoll(slno,min,max,increment,timeout){//alert(slno+"="+min+"="+max+"="+increment+"="+timeout);
    //             if(min<=max){
    //                 $('.roller-title-number-'+slno).html(min);
    //                 min=parseInt(min)+parseInt(increment);
    //                 setTimeout(function(){numberRoll(eval(slno),eval(min),eval(max),eval(increment),eval(timeout))},timeout);
    //             }else{
    //                 $('.roller-title-number-'+slno).html(max);
    //             }
    //         }
    //     // })($);

    // },[])
  return (
    <div className="stats-w3layouts py-5">
		<div className="container py-xl-5 py-lg-3">
      
			<div className="stats-info">
				<div className="row">
					<div className="col-md-3 col- 6 stats-grid-w3-agile text-center px-xl-5 px-3">
						
                        <div className='numscroller font-weight-bold my-2' data-slno='1' data-min='0' data-max='2280' data-delay='.5' data-increment="1"><CountUp end={2280} duration={20} suffix={'+'} /></div>
						<p className="text-uppercase text-white border-top pt-4 mt-3">happy students</p>
					</div>
					<div className="col-md-3  stats-grid-w3-agile text-center  px-xl-5 px-3">
						<div className='numscroller font-weight-bold my-2' data-slno='1' data-min='0' data-max='80' data-delay='.5' data-increment="1"><CountUp end={80} duration={20} suffix={'+'} /></div>
						<p className="text-uppercase text-white border-top pt-4 mt-3">approved courses</p>
					</div>
					<div className="col-md-3  stats-grid-w3-agile text-center mt-md-0 mt-5  px-xl-5 px-3">
						<div className='numscroller font-weight-bold my-2' data-slno='1' data-min='0' data-max='1200' data-delay='.5' data-increment="1"><CountUp end={1200} duration={20} suffix={'+'} /></div>
						<p className="text-uppercase text-white border-top pt-4 mt-3">certified teachers</p>
					</div>
					<div className="col-md-3  stats-grid-w3-agile text-center mt-md-0 mt-5  px-xl-5 px-3">
						<div className='numscroller font-weight-bold my-2' data-slno='1' data-min='0' data-max='2200' data-delay='.5' data-increment="1"><CountUp end={2200} duration={20} suffix={'+'} /></div>
						<p className="text-uppercase text-white border-top pt-4 mt-3">graduate students</p>
					</div>
				</div>
			</div>
		</div>
	</div>
  )
}

export default Stats