import React, { Fragment } from 'react';


const Placement = () => {
  return (
    <Fragment>
      <div className="banner-agile-2">
        {/* <div className="navigation-w3ls">
					<nav className="navbar navbar-expand-lg navbar-light bg-light sticky-nav">
						<button className="navbar-toggler mx-auto" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
							aria-expanded="false" aria-label="Toggle navigation">
							<span className="navbar-toggler-icon"></span>
						</button>
						<div className="collapse navbar-collapse text-center" id="navbarSupportedContent">
							<ul className="navbar-nav justify-content-center">
								<li className="nav-item">
									<a className="nav-link text-white" href="/">Home
										<span className="sr-only">(current)</span>
									</a>
								</li>
								<li className="nav-item active">
									<a className="nav-link text-white" href="/about">About Us</a>
								</li>
								<li className="nav-item dropdown">
									<a className="nav-link dropdown-toggle text-white" href="#" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
										Courses
									</a>
									<div className="dropdown-menu">
										<a className="dropdown-item" href="language.html">Language</a>
										<a className="dropdown-item" href="communication.html">Communication</a>
										<a className="dropdown-item" href="business.html">Business</a>
										<a className="dropdown-item" href="software.html">Software</a>
										<a className="dropdown-item" href="social_media.html">Social Media</a>
										<a className="dropdown-item" href="photography.html">Photography</a>
										<a className="dropdown-item" href="course_details.html">Course Details</a>
										<div className="dropdown-divider"></div>
										<a className="dropdown-item" href="form.html">Apply Now</a>
									</div>
								</li>
								
								<li className="nav-item">
									<a className="nav-link text-white" href="/gallery">Gallery</a>
								</li>
								<li className="nav-item">
									<a className="nav-link text-white" href="/contactus">Contact Us</a>
								</li>
							</ul>
						</div>
					</nav>
				</div> */}
        <div className="navigation-w3ls">
          <nav className="navbar navbar-expand-lg navbar-light bg-light sticky-nav">
            <button className="navbar-toggler mx-auto" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
              aria-expanded="false" aria-label="Toggle navigation">
              <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse text-center" id="navbarSupportedContent">
              <ul className="navbar-nav justify-content-center">
                <li className="nav-item active">

                  <a className="nav-link text-white" href="/">Home
                    <span className="sr-only">(current)</span>
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link text-white" href="/about">About Us</a>
                </li>
                <li className="nav-item">
                  <a className="nav-link text-white" href="/allprogram">All Programs</a>
                </li>
                <li className="nav-item">
                  <a className="nav-link text-white" href="/Onlineprogram">Online Programs</a>
                </li>
                <li className="nav-item">
                  <a className="nav-link text-white" href="/internship">Internship</a>
                </li>
                <li className="nav-item">
                  <a className="nav-link text-white" href="/placement">Placement</a>
                </li>

                {/* <li className="nav-item dropdown">
                  <a className="nav-link dropdown-toggle text-white" href="#" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    Courses
                  </a>
                </li> */}

                {/* <li className="nav-item">
                  <a className="nav-link text-white" href="/couses">courses</a>
                </li> */}
                <li className="nav-item">
                  <a className="nav-link text-white" href="/contactus">Contactus</a>
                </li>
              </ul>
            </div>
          </nav>
        </div>
      </div>
      <nav aria-label="breadcrumb">
        <ol className="breadcrumb" style={{ fontSize: "14px", fontFamily:"'Open Sans', sans-serif",padding : (".5em",".5em"), backgroundColor: "#e9ecef"}}>
          <li className="breadcrumb-item">
            <a href="/">Home/</a>
          </li>
         <h3><li className="breadcrumb-item active" aria-current="page">Placement</li></h3> 
        </ol>
      </nav>





      <div class="tabs-wrapper blog-pages-wrapper section-space--ptb_100">
        <div class="container">
          <div class="row">
            <div class="col-lg-3 order-lg-1 order-1">
              <div class="page-sidebar-content-wrapper page-sidebar-left  small-mt__40 tablet-mt__40">

                <div class="sidebar-widget widget-blog-recent-post wow move-up animated" style={{ visibility: "visible" }}>
                  <ul class="nav" role="tablist">
                    <li class="tab__item nav-item active">
                      <a class="nav-link active" id="nav-tab1" data-toggle="tab" href="#tab_list_03" role="tab" aria-selected="true">Our Placements </a>
                    </li>
                    <li class="tab__item nav-item">
                      <a class="nav-link" id="nav-tab1" data-toggle="tab" href="#tab_list_01" role="tab" aria-selected="true">Placement Process</a>
                    </li>
                    <li class="tab__item nav-item">
                      <a class="nav-link" id="nav-tab1" data-toggle="tab" href="#tab_list_02" role="tab" aria-selected="true">Our Recruiters</a>
                    </li>
                    <li class="tab__item nav-item">
                      <a class="nav-link" href="/contactus" target="_blank">Career & Placement Cell </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="col-lg-9 order-lg-2 order-2">
              <div class="tab-content ht-tab__content">
                <div class="tab-pane fade show active" id="tab_list_03" role="tabpanel">
                  <div class="feature-images-wrapper bg-gray">
                    <div class="row">
                      <div class="col-lg-12">
                        <div class="section-title-wrap text-center">
                          {/* <h3 class="heading"><span class="text-color-primary" style={{ fontSize: "19px" }}> Our Placements</span></h3> */}

                          <h3 className="title text-capitalize font-weight-light text-dark text-center mb-5"> Our 
                        <span className="font-weight-bold"> Placements</span>
                    </h3>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-12">
                        <div class="feature-images__one">
                          <div class="row">
                            <div class="col-md-6 mb-4 aos-init aos-animate" data-aos="fade-up" data-aos-once="true">
                              <section class="d-flex align-items-center">
                                <figure class="mr-3 mb-0">
                                  <img src="assests/images/pl1.jpg" alt="Ms.LAVINA YOGI" />
                                </figure>
                                <article class="p-3">
                                  <h5 class="mb-2 text-green" style={{ fontSize: "16px" }}>Ms.LAVINA YOGI</h5>
                                  <p class="mb-2"><strong>Course :</strong> JAVA</p>
                                  <p class="mb-0"><strong>Name Of Company :</strong>IT Desk </p>
                                </article>
                              </section>
                            </div>
                            <div class="col-md-6 mb-4 aos-init aos-animate" data-aos="fade-up" data-aos-once="true">
                              <section class="d-flex align-items-center">
                                <figure class="mr-3 mb-0">
                                  <img src="assests/images/pl3.jpg" alt="Mr. ROHIT SAXENA" />
                                </figure>
                                <article class="p-3">
                                  <h5 class="mb-2 text-green" style={{ fontSize: "16px" }}>Mr. ROHIT SAXENA</h5>
                                  <p class="mb-2"><strong>Course :</strong> AutoCAD,PYTHON (Master Diploma)</p>
                                  <p class="mb-0"><strong>Name Of Company :</strong>IT Desk </p>
                                </article>
                              </section>
                            </div>
                            <div class="col-md-6 mb-4 aos-init aos-animate" data-aos="fade-up" data-aos-once="true">
                              <section class="d-flex align-items-center">
                                <figure class="mr-3 mb-0">
                                  <img src="assests/images/pl4.jpg" alt="Mr. SURESH SHARMA" />
                                </figure>
                                <article class="p-3">
                                  <h5 class="mb-2 text-green"  style={{fontSize:"16px"}}>Mr. SURESH SHARMA</h5>
                                  <p class="mb-2"><strong>Course :</strong> C Sharp</p>
                                  <p class="mb-0"><strong>Name Of Company :</strong>IT Desk </p>
                                </article>
                              </section>
                            </div>
                            <div class="col-md-6 mb-4 aos-init aos-animate" data-aos="fade-up" data-aos-once="true">
                              <section class="d-flex align-items-center">
                                <figure class="mr-3 mb-0">
                                  <img src="assests/images/PL6.jpg" alt="Mr. RAGHU NANDAN SHARMA" />
                                </figure>
                                <article class="p-3">
                                  <h5 class="mb-2 text-green" style={{ fontSize: "16px" }}>Mr. RAGHU NANDAN SHARMA</h5>
                                  <p class="mb-2"><strong>Course :</strong> Fullstack wrb developer</p>
                                  <p class="mb-0"><strong>Name Of Company :</strong>IT Desk </p>
                                </article>
                              </section>
                            </div>
                            <div class="col-md-6 mb-4 aos-init aos-animate" data-aos="fade-up" data-aos-once="true">
                              <section class="d-flex align-items-center">
                                <figure class="mr-3 mb-0">
                                  <img src="assests/images/PL7.jpg" alt="Ms. LOKESH SHARMA" />
                                </figure>
                                <article class="p-3">
                                  <h5 class="mb-2 text-green"  style={{fontSize:"16px"}}>Ms. LOKESH SHARMA</h5>
                                  <p class="mb-2"><strong>Course :</strong> .NET</p>
                                  <p class="mb-0"><strong>Name Of Company :</strong>IT Desk </p>
                                </article>
                              </section>
                            </div>
                            <div class="col-md-6 mb-4 aos-init aos-animate" data-aos="fade-up" data-aos-once="true">
                              <section class="d-flex align-items-center">
                                <figure class="mr-3 mb-0">
                                  <img src="assests/images/PL8.jpg" alt="ER. LAXMIKANT PRAJAPATI" />
                                </figure>
                                <article class="p-3">
                                  <h5 class="mb-2 text-green" style={{ fontSize: "16px" }}>ER. LAXMI PRAJAPATI</h5>
                                  <p class="mb-2"><strong>Course :</strong> Android</p>
                                  <p class="mb-0"><strong>Name Of Company :</strong>IT Desk </p>
                                </article>
                              </section>
                            </div>
                            {/* <div class="col-md-6 mb-4 aos-init aos-animate" data-aos="fade-up" data-aos-once="true">
                              <section class="d-flex align-items-center">
                                <figure class="mr-3 mb-0">
                                  <img src="https://itdeskindia.com/wp-content/uploads/2021/01/ektasharma.png" alt="Ms. EKTA SHARMA" />
                                </figure>
                                <article class="p-3">
                                  <h5 class="mb-2 text-green"  style={{fontSize:"16px"}}>Ms. EKTA SHARMA</h5>
                                  <p class="mb-2"><strong>Course :</strong> Sketch Up</p>
                                  <p class="mb-0"><strong>Name Of Company :</strong>IT Desk </p>
                                </article>
                              </section>
                            </div>
                            <div class="col-md-6 mb-4 aos-init aos-animate" data-aos="fade-up" data-aos-once="true">
                              <section class="d-flex align-items-center">
                                <figure class="mr-3 mb-0">
                                  <img src="https://itdeskindia.com/wp-content/uploads/2021/01/rajeshmehara.png" alt="Mr. RAJESH MAHERA" />
                                </figure>
                                <article class="p-3">
                                  <h5 class="mb-2 text-green" style={{ fontSize: "16px" }}>Mr. RAJESH MAHERA</h5>
                                  <p class="mb-2"><strong>Course :</strong> AutoCAD, Staad Pro</p>
                                  <p class="mb-0"><strong>Name Of Company :</strong>IT Desk </p>
                                </article>
                              </section>
                            </div>
                            <div class="col-md-6 mb-4 aos-init aos-animate" data-aos="fade-up" data-aos-once="true">
                              <section class="d-flex align-items-center">
                                <figure class="mr-3 mb-0">
                                  <img src="https://itdeskindia.com/wp-content/uploads/2021/01/lalit.png" alt="Mr. LALIT" />
                                </figure>
                                <article class="p-3">
                                  <h5 class="mb-2 text-green"  style={{fontSize:"16px"}}>Mr. LALIT</h5>
                                  <p class="mb-2"><strong>Course :</strong> AutoCAD, Staad Pro, 3Ds Max</p>
                                  <p class="mb-0"><strong>Name Of Company :</strong>IT Desk </p>
                                </article>
                              </section>
                            </div>
                            <div class="col-md-6 mb-4 aos-init aos-animate" data-aos="fade-up" data-aos-once="true">
                              <section class="d-flex align-items-center">
                                <figure class="mr-3 mb-0">
                                  <img src="https://itdeskindia.com/wp-content/uploads/2021/01/subhamsukala.png" alt="Mr. SHUBHAM SHUKLA" />
                                </figure>
                                <article class="p-3">
                                  <h5 class="mb-2 text-green" style={{ fontSize: "16px" }}>Mr. SHUBHAM SHUKLA</h5>
                                  <p class="mb-2"><strong>Course :</strong> AutoCAD, 3Ds Max</p>
                                  <p class="mb-0"><strong>Name Of Company :</strong>IT Desk </p>
                                </article>
                              </section>
                            </div> */}


                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="tab-pane fade" id="tab_list_01" role="tabpanel">
                  <div class="feature-images-wrapper">
                    <div class="row">
                      <div class="col-lg-12">
                        <div class="section-title-wrap text-center">
                          <h3 class="heading"><span class="text-color-primary" style={{ fontSize: "16px" }}> Placement Process</span></h3>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-12">
                        <div class="feature-images__one">
                          <div class="row">
                            <p>“Network has grown to more than 20+ cities in 15 states of India.”</p>
                            <p>IT DESK is a leading training network of India in the field of Computer Science and Information Technology. Incorporated in year 2007, IT DESK is a registered trademark of M/S Parshi training and technical services Pvt. Ltd. Since the inception, IT DESK has developed as a leading franchise network and our network has grown to more than 20+ cities in 15 states of India. We have started operations in the overseas market as well.</p>
                            <h6 class="label problem-label">IT DESK Presentation</h6>
                            <p>IT DESK focuses on student and works on development of his/her knowledge and skills.Various facilities are provided to them i.e. student kit, student panel, books and course material, Live projects, industrial visits, certification&amp; Placement alerts. Certification process is initiated only after receiving feedback and project submission at all level.</p>
                            <h6 class="label problem-label">More than 20+ cities in 15 states of India.</h6>
                            <p>IT DESK focuses on student and works on development of his/her knowledge and skills.Various facilities are provided to them i.e. student kit, student panel, books and course material, Live projects, industrial visits, certification&amp; Placement alerts. Certification process is initiated only after receiving feedback and project submission at all level.</p>

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="tab-pane fade" id="tab_list_02" role="tabpanel">
                  <div class="feature-images-wrapper">
                    <div class="row">
                      <div class="col-lg-12">
                        <div class="section-title-wrap text-center">
                          <h3 class="heading"><span class="text-color-primary" style={{ fontSize: "19px" }}> Our Recruiters</span></h3>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-12">
                        <ul class="list-inline d-flex flex-wrap">
                          <li data-aos="fade-up" data-aos-once="true" class="aos-init aos-animate"><img src="https://itdeskindia.com/wp-content/uploads/2021/01/eight.png" alt="Logo 15" /></li>
                          <li data-aos="fade-up" data-aos-once="true" class="aos-init aos-animate"><img src="https://itdeskindia.com/wp-content/uploads/2021/01/five.png" alt="Logo 14" /></li>
                          <li data-aos="fade-up" data-aos-once="true" class="aos-init aos-animate"><img src="https://itdeskindia.com/wp-content/uploads/2021/01/four.png" alt="Logo 13" /></li>
                          <li data-aos="fade-up" data-aos-once="true" class="aos-init aos-animate"><img src="https://itdeskindia.com/wp-content/uploads/2021/01/nine.png" alt="Logo 12" /></li>
                          <li data-aos="fade-up" data-aos-once="true" class="aos-init aos-animate"><img src="https://itdeskindia.com/wp-content/uploads/2021/01/one.png" alt="Logo 11" /></li>
                          <li data-aos="fade-up" data-aos-once="true" class="aos-init aos-animate"><img src="https://itdeskindia.com/wp-content/uploads/2021/01/rec1.png" alt="Logo 10" /></li>
                          <li data-aos="fade-up" data-aos-once="true" class="aos-init aos-animate"><img src="https://itdeskindia.com/wp-content/uploads/2021/01/rec3.png" alt="Logo 9" /></li>
                          <li data-aos="fade-up" data-aos-once="true" class="aos-init aos-animate"><img src="https://itdeskindia.com/wp-content/uploads/2021/01/rec2.png" alt="Logo 8" /></li>
                          <li data-aos="fade-up" data-aos-once="true" class="aos-init aos-animate"><img src="https://itdeskindia.com/wp-content/uploads/2021/01/rec4.png" alt="Logo 7" /></li>
                          <li data-aos="fade-up" data-aos-once="true" class="aos-init aos-animate"><img src="https://itdeskindia.com/wp-content/uploads/2021/01/rec6.png" alt="Logo 6" /></li>
                          <li data-aos="fade-up" data-aos-once="true" class="aos-init aos-animate"><img src="https://itdeskindia.com/wp-content/uploads/2021/01/seven.png" alt="Logo 5" /></li>
                          <li data-aos="fade-up" data-aos-once="true" class="aos-init aos-animate"><img src="https://itdeskindia.com/wp-content/uploads/2021/01/six.png" alt="Logo 4" /></li>
                          <li data-aos="fade-up" data-aos-once="true" class="aos-init aos-animate"><img src="https://itdeskindia.com/wp-content/uploads/2021/01/ten.png" alt="Logo 3" /></li>
                          <li data-aos="fade-up" data-aos-once="true" class="aos-init aos-animate"><img src="https://itdeskindia.com/wp-content/uploads/2021/01/two.png" alt="Logo 2" /></li>
                          <li data-aos="fade-up" data-aos-once="true" class="aos-init aos-animate"><img src="https://itdeskindia.com/wp-content/uploads/2021/01/Pentagon-Space.png" alt="Logo 1" /></li>

                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="tab-pane fade" id="tab_list_04" role="tabpanel">
                  <h2>asdasdsad</h2>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </Fragment>
  );
}

export default Placement;
