import React, { Fragment } from 'react'

const MernStack = () => {
    return (
        <Fragment>
            {/* <div class="botam-navbar">
   <p style="margin-left: 15px; color: #fff">To avail the weekly free training offer <br/>Sign up Now.</p>
   <a href="#corseform" class="active">To know course fee.</a>
   <a href="#corseform" class="active">Know More</a>
</div> */}
            <div id="main-wrapper">
                <div class="site-wrapper-reveal">
                    <div class="about-banner-wrap vision-space bg-img" style={{backgroundColor: "darkturquoise", height: "600px"}}>
                        <div class="container">
                            <div class="row">
                                <div class="col-lg-6 section-space--pt_100">
                                    <h1 style={{color: "#fff"}}>Mern Stack Development</h1>
                                    <p style={{color:" #fff"}}>MEAN is an acronym for MongoDB, Express.js and Angularjs, all of which function upon Node.js</p>
                                </div>
                                <div class="col-lg-2">
                                </div>
                                <div class="col-lg-4">
                                    <div class="form-container mx-auto mx-xl-0 ml-xl-auto border" id="corseform">
                                        <div id="signup-form">
                                            <form id="basic-form" name="yoursite_wp_mail_from_name" method="post" />
                                            <input type="hidden" name="courseId" value="1132" />
                                            <input type="hidden" name="category" value="16" />
                                            <div class="form-group">
                                                <label>Name:</label>
                                                <input type="text" id="fname" name="fname" class="form-control" placeholder="Enter Your Name" value="" required="" />
                                            </div>
                                            <div class="form-group">
                                                <label>Email:</label>
                                                <input type="email" id="email" name="email" class="form-control" placeholder="Enter Your Email Address" value="" required="" />
                                            </div>
                                            <div class="form-group">
                                                <label>Mobile:</label>
                                                <input type="number" id="phone" name="phone" class="form-control" placeholder="Enter Your mobile number" value="" required="" />
                                            </div>
                                            <div class="form-group mb-0">
                                                <div class="form-row">
                                                    <div class="form-group col-6">
                                                        <label class="control-label">State</label>
                                                        <select id="states" name="states" class="form-control" required="">
                                                            <option value="">SELECT STATE</option>
                                                            <option value="2">Andhra Pradesh</option>
                                                            <option value="5">Bihar</option>
                                                            <option value="12">Gujarat</option>
                                                            <option value="13">Haryana</option>
                                                            <option value="14">Himachal Pradesh</option>
                                                            <option value="16">Jharkhand</option>
                                                            <option value="17">Karnataka</option>
                                                            <option value="21">Maharashtra</option>
                                                            <option value="28">Punjab</option>
                                                            <option value="29">Rajasthan</option>
                                                            <option value="31">Tamil Nadu</option>
                                                            <option value="32">Telangana</option>
                                                            <option value="35">Uttar Pradesh</option>
                                                        </select>
                                                    </div>
                                                    <div class="form-group col-6 city">
                                                        <label class="control-label">Center</label>
                                                        <select name="city" id="city" class="form-control center" required="">
                                                            <option value="">SELECT CENTRE</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="form-group mb-0">
                                                <button type="submit" id="btn" class="btn btn-primary contest_register_now" name="register_users">Apply Now</button>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <nav aria-label="breadcrumb">
        <ol class="breadcrumb" >f
          <li class="breadcrumb-item" >
            <a href="/" >Home</a>
          </li>
          <li class="breadcrumb-item active" aria-current="page">MernStack</li>
        </ol>
      </nav>




            <div class="vision-overview ">
                <div class="container">
                    <div class="section-title-wrap text-center">
                        <br/>
                        <br/>
                        <h3 class="heading"><span class="text-color-primary" style={{fontSize: "21px"}}> Training Highlights</span></h3>
                    </div>
                    <div class="row">
                        <div class="col-12 col-lg-4">
                            <div class="item-container d-flex align-items-center">
                                <div class="image-container">
                                    <img src="https://itdeskindia.com/wp-content/uploads/2021/02/1.png" class="lazy-load" />
                                </div>
                                <div class="content-container">
                                    <h3 class="title">Instructor Led Training</h3>
                                    <p class="description"></p>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-lg-4">
                            <div class="item-container d-flex align-items-center">
                                <div class="image-container">
                                    <img src="https://itdeskindia.com/wp-content/uploads/2021/02/2.png" class="lazy-load" />
                                </div>
                                <div class="content-container">
                                    <h3 class="title">Weekly Doubt clearing Session</h3>
                                    <p class="description"></p>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-lg-4">
                            <div class="item-container d-flex align-items-center">
                                <div class="image-container">
                                    <img src="https://itdeskindia.com/wp-content/uploads/2021/02/offline-revision-min.png" class="lazy-load" />
                                </div>
                                <div class="content-container">
                                    <h3 class="title">Lifetime Assistance for Revision</h3>
                                    <p class="description"></p>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-lg-4">
                            <div class="item-container d-flex align-items-center">
                                <div class="image-container">
                                    <img src="https://itdeskindia.com/wp-content/uploads/2021/02/LOGIN.jpg" class="lazy-load" />
                                </div>
                                <div class="content-container">
                                    <h3 class="title">Lifetime Login Access to Student Dashboard</h3>
                                    <p class="description"></p>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-lg-4">
                            <div class="item-container d-flex align-items-center">
                                <div class="image-container">
                                    <img src="https://itdeskindia.com/wp-content/uploads/2021/02/certificate-icon-10.png" class="lazy-load" />
                                </div>
                                <div class="content-container">
                                    <h3 class="title">World-Wide Valid Certification</h3>
                                    <p class="description"></p>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-lg-4">
                            <div class="item-container d-flex align-items-center">
                                <div class="image-container">
                                    <img src="https://itdeskindia.com/wp-content/uploads/2021/02/books-min.png" class="lazy-load" />
                                </div>
                                <div class="content-container">
                                    <h3 class="title">Study Material, Assignments and Projects</h3>
                                    <p class="description"></p>
                                </div>
                            </div>
                        </div>

                    </div>
                    <br /> <br />
                    <div class="section-title-wrap text-center">
                    <br/>
                    <br/>
                        <h3 class="heading"><span class="text-color-primary" style={{fontSize: "21px"}}> Course Highlights</span></h3>
                    </div>
                    {/* <div class="row">
                        <div class="d-flex flex-row flex-lg-column align-items-lg-center mx-auto">
                            <div class="dashed-border">
                                <img class="d-block d-lg-none" src="https://itdeskindia.com/wp-content/themes/itdesk_theme/assets/images/dotted_line_mobile.svg" />
                                <img class="d-none d-lg-block" src="https://itdeskindia.com/wp-content/themes/itdesk_theme/assets/images/dotted_line_desktop.svg" />
                            </div>
                            <div class="d-flex flex-column flex-lg-row justify-content-lg-center">

                                <div class="item-container d-flex flex-lg-column align-items-center">
                                    <div class="number-container">
                                        <div class="number-box">1</div>
                                        <div class="arrow-container">
                                            <div class="arrow arrow-left"></div>
                                            <div class="arrow arrow-right"></div>
                                        </div>
                                    </div>
                                    <div class="content-container">
                                        <p class="activity">MongoDB</p>
                                    </div>
                                </div>
                                <div class="item-container d-flex flex-lg-column align-items-center">
                                    <div class="number-container">
                                        <div class="number-box">2</div>
                                        <div class="arrow-container">
                                            <div class="arrow arrow-left"></div>
                                            <div class="arrow arrow-right"></div>
                                        </div>
                                    </div>
                                    <div class="content-container">
                                        <p class="activity">Express</p>
                                    </div>
                                </div>
                                <div class="item-container d-flex flex-lg-column align-items-center">
                                    <div class="number-container">
                                        <div class="number-box">3</div>
                                        <div class="arrow-container">
                                            <div class="arrow arrow-left"></div>
                                            <div class="arrow arrow-right"></div>
                                        </div>
                                    </div>
                                    <div class="content-container">
                                        <p class="activity">Angular</p>
                                    </div>
                                </div>
                                <div class="item-container d-flex flex-lg-column align-items-center">
                                    <div class="number-container">
                                        <div class="number-box">4</div>
                                        <div class="arrow-container">
                                            <div class="arrow arrow-left"></div>
                                            <div class="arrow arrow-right"></div>
                                        </div>
                                    </div>
                                    <div class="content-container">
                                        <p class="activity">Nodejs</p>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div> */}

                    <div class="row">
                        <div class="col-lg-12 col-12">
                            {/* <!-- <h3 class="heading" style="font-size: 21px;">Course Overview</h3> --> */}
                            <h3 class="heading"><span class="text-color-primary" style={{fontSize: "21px"}}>Course Overview</span></h3>
                            <div class="vision-content">
                            <br/>
                                <h2><strong>Mean Stack Development: A Comprehensive Guide to Courses and Training</strong></h2>
                                <p><strong>Introduction</strong></p>
                                <br/>
                                <p>In the fast-paced world of web development, staying up-to-date with the latest technologies is crucial. Mean Stack Development is one such technology that has gained immense popularity among developers. In this article, we will delve into the intricacies of Mean Stack Development, explore the courses and training options available, and understand the benefits of pursuing this field. So, let&#8217;s embark on this exciting journey of discovering Mean Stack Development!</p>
                                <h2><strong>Table of Contents</strong></h2>
                                <ol style={{fontSize:'15px', fontfamily: 'Open Sans sans-serif'}}>
                                <br/>
                                    <li>What is Mean Stack Development?</li>
                                    <li>The Components of Mean Stack
                                        <ul>
                                            <li>2.1 MongoDB</li>
                                            <li>2.2 Express.js</li>
                                            <li>2.3 AngularJS</li>
                                            <li>2.4 Node.js</li>
                                        </ul>
                                    </li>
                                    <br/>
                                    <li>Advantages of Mean Stack Development</li>
                                    <li>Mean Stack Development Courses
                                        <ul>
                                        <br/>
                                            <li>4.1 Online Courses</li>
                                            <li>4.2 In-Person Training</li>
                                        </ul>
                                    </li>
                                    <li>Choosing the Right Mean Stack Development Institute</li>
                                    <li>What to Expect in a Mean Stack Development Class</li>
                                    <li>Understanding Mean Stack Development Fees</li>
                                    <li>Why Choose IT Desk for Mean Stack Development Training in Jaipur</li>
                                </ol>
                                <br/>
                                <br/>
                                <h2><strong>What is Mean Stack Development?</strong></h2>
                                <p>Mean Stack Development is a powerful and versatile web development framework that utilizes four key technologies: MongoDB, Express.js, AngularJS, and Node.js. By combining these technologies, Mean Stack allows developers to build dynamic and robust web applications. This full-stack framework offers the advantage of using a single language, JavaScript, across the entire application, making the development process smoother and more efficient.</p>
                                <h2><strong>The Components of Mean Stack</strong></h2>
                                <br/>
                                <p><strong>MongoDB</strong></p>
                                <p>MongoDB is a NoSQL database that stores data in JSON-like documents. Its flexible schema allows for easy data manipulation and seamless integration with Node.js applications. MongoDB&#8217;s scalability and high performance make it an ideal choice for modern web development projects.</p>
                                <p><strong>Express.js</strong></p>
                                <br/>
                                <p>Express.js is a lightweight and fast web application framework for Node.js. It simplifies the process of building server-side applications and APIs. With its numerous middleware, Express.js enables developers to add functionality and features to their applications effortlessly.</p>
                                <p><strong>AngularJS</strong></p>
                                <p>AngularJS is a powerful front-end JavaScript framework that allows developers to create dynamic and interactive user interfaces. Its data binding and dependency injection features streamline the development process, resulting in highly responsive and user-friendly applications.</p>
                                <p><strong>Node.js</strong></p>
                                <br/>
                                <p>Node.js is a server-side JavaScript runtime environment that allows developers to execute code outside of a web browser. It provides a non-blocking, event-driven architecture, making it efficient for building scalable and real-time applications.</p>
                                <h2><strong>Advantages of Mean Stack Development Course</strong></h2>
                                <p>Mean Stack Development Course offers several benefits that make it a preferred choice for web development projects:</p>
                                <ul>
                                <br/>
                                    <li><strong>Simplified Development</strong>: Using a single language (JavaScript) for both front-end and back-end development reduces complexity and eases the learning curve.</li>
                                    <li><strong>Code Reusability</strong>: Developers can reuse code components across different layers of the application, saving time and effort.</li>
                                    <li><strong>Real-time Web Applications</strong>: Mean Stack&#8217;s event-driven architecture enables the development of real-time applications that respond quickly to user interactions.</li>
                                    <li><strong>Isomorphic Code</strong>: Mean Stack allows developers to write isomorphic code, meaning the same code can run on both the client and server sides.</li>
                                    <li><strong>Large Community Support</strong>: With its growing popularity, Mean Stack has a vast community of developers who actively contribute to its improvement and provide support.</li>
                                </ul>
                                <br/>
                                <h2><strong>Mean Stack Development Courses</strong></h2>
                                <p>To master Mean Stack Development Course, aspiring developers have multiple learning options:</p>
                                <p><strong>Online Courses</strong></p>
                                <br/>
                                <p>Numerous online platforms offer comprehensive Mean Stack Development courses. These courses provide flexibility, allowing learners to study at their own pace and from the comfort of their homes. Some platforms even offer certifications upon course completion, adding value to learners&#8217; resumes. An IT Desk India provides online Mean Stack Development Courses by experts.</p>
                                <p><strong>In-Person Training &amp; Offline Course</strong></p>
                                <br/>
                                <p>For those who prefer hands-on learning and personalized guidance, in-person Mean Stack Development training can be an excellent choice<strong>. IT Desk India Training Institute</strong> provides <strong>Mean Stack Development Courses in Jaipur</strong> Centers provides instructor-led courses, allowing learners to interact directly with experienced professionals.</p>
                                <h3><strong>Choosing the Right Mean StackDevelopment Training Institute</strong></h3>
                                <p>When selecting an IT Desk <strong>Mean Stack Development Training Institute</strong>, consider the following factors:</p>
                                <ul>
                                    <li><strong>Curriculum</strong>: Ensure the institute&#8217;s curriculum covers all essential topics related to Mean Stack Development.</li>
                                    <li><strong>Faculty</strong>: Research the qualifications and experience of the faculty members to ensure quality education.</li>
                                    <li><strong>Reviews and Testimonials</strong>: Read reviews and testimonials from previous students to gauge the institute&#8217;s reputation.</li>
                                    <li><strong>Placement Assistance</strong>: Look for institutes that offer placement assistance to help kickstart your career.</li>
                                </ul>
                                <br/>
                                <h3><strong>What to Expect in a Mean Stack Development Class in Jaipur</strong></h3>
                                <p>In a typical Mean Stack Development class in Jaipur, you can expect:</p>
                                <ul>
                                    <li><strong>Practical Projects</strong>: Hands-on projects to apply the knowledge gained during the course.</li>
                                    <li><strong>Live Coding Sessions</strong>: Live coding sessions to understand real-world development scenarios.</li>
                                    <li><strong>Interactive Learning</strong>: Engaging in discussions and Q&amp;A sessions with instructors and peers.</li>
                                </ul>
                                <br/>
                                <h3><strong>Understanding Mean Stack Development Fees</strong></h3>
                                <p>The fees for Mean Stack Development courses in Jaipur can vary based on factors such as the institute&#8217;s reputation, course duration, and mode of training (online or in-person). However, investing in quality education and training is essential for a successful career in Mean Stack Development. IT Desk Training Institute offers low fees for the Mean Stack Development Course.</p>
                                <h3><strong>The Importance of Mean Stack Development Certification</strong></h3>
                                <br/>
                                <p>Obtaining a Mean Stack Development certification demonstrates your expertise and commitment to the field. Employers often value certified professionals, making certification an excellent addition to your resume.</p>
                                <p><em><strong>IT DESK</strong></em><em> certificate provides a great way for students to get recognition in the industry and helps them grab the best job opportunities. </em>IT DESK Certificates are ISO verified which makes it highly reputed compared to the other local brands and helps the student get prioritized while going for job opportunities in the industry.</p>
                                <h3><strong>Why Choose IT Desk for Mean Stack Development Training in Jaipur</strong></h3>
                                <br/>
                                <p>IT Desk is a renowned institute in Jaipur, offering top-notch Mean Stack Development courses. With experienced faculty, a comprehensive curriculum, and placement assistance, IT Desk ensures you gain the skills and knowledge needed to thrive in the industry.</p>
                                <p><strong>IT DESK</strong> provides professional software training on a vast array of courses in the fields of <strong>Computer Science and Information Technology</strong>. Since its founding in 2016, <strong>IT DESK</strong> has provided training to over 10 Thousands+ students and professionals from various countries.</p>
                                <p>Since its inception, in a very short span of time, with the qualitative services of training and well-designed training modules, <strong>IT DESK</strong> has expanded its network and become the topmost leading franchise network. <em><strong>IT DESK proudly owns 35+ franchise centers in various parts of the country</strong></em>. Furthermore, we are now expanding our network center in various countries in the world.</p>
                            </div>
                            <br/>
                            <br/>



                        </div>
                    </div>
                </div>
            </div>

            {/* <div class="accordion-wrapper">
                <div class="container">
                    <h3 class="heading"><span class="text-color-primary" style={{fontSize: "21px"}}>Course Contents</span></h3>
                    <!--  <p>The studio was keen to create an identity that would not interfere with any of the existing brands, settling on a helicoid system that can be easily adapted to “work with any colour system”. </p> -->
                    <div class="row ">
                        <div class="col-lg-8">
                            <div class="faq-two-wrapper section-space--mt_40">
                                <div id="accordion_two">
                                    <div class="card">
                                        <div class="card-header" id="heading__110">
                                            <h5 class="mb-0">
                                                <button class="btn-link collapsed" data-toggle="collapse" data-target="#collapse__110" aria-expanded="false" aria-controls="collapse__110">
                                                    Nodejs<span>
                                                        <i class="far fa-caret-circle-down"></i>
                                                        <i class="far fa-caret-circle-right"></i>  </span>
                                                </button>
                                            </h5>
                                        </div>
                                        <div id="collapse__110" class="collapse" aria-labelledby="heading__110" data-parent="#accordion_two">
                                            <div class="card-body">
                                                <p><p>Introduction to Nodejs</p>
                                                    <ul>
                                                        <li>What is nodejs</li>
                                                        <li>A word on eventloop</li>
                                                        <li>Event driven architecture</li>
                                                        <li>Blocking vs non-blocking code</li>
                                                        <li>Setup nodejs</li>
                                                        <li>Node REPL</li>
                                                        <li>Debugging nodejs app</li>
                                                    </ul>
                                                    <p>Modules in JS</p>
                                                    <ul>
                                                        <li>Understanding exports and require</li>
                                                        <li>Creating modules</li>
                                                        <li>Importing modules</li>
                                                        <li>Quick tour on npm</li>
                                                        <li>Installing 3rd party modules</li>
                                                    </ul>
                                                    <p>Events &amp; streams</p>
                                                    <ul>
                                                        <li>Significance of Events</li>
                                                        <li>EventEmitter classs</li>
                                                        <li>Emitting and listening to events</li>
                                                        <li>Types of streams</li>
                                                        <li>Composing streams using pipe</li>
                                                    </ul>
                                                    <p>Working with Process and File System</p>
                                                    <ul>
                                                        <li>Process object</li>
                                                        <li>Handling exceptions at process level</li>
                                                        <li>Listening to process events</li>
                                                        <li>Working with file system using fs module</li>
                                                        <li>Creating, Copying, Deleting files</li>
                                                        <li>Watching for file changes</li>
                                                    </ul>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="card">
                                        <div class="card-header" id="heading__111">
                                            <h5 class="mb-0">
                                                <button class="btn-link collapsed" data-toggle="collapse" data-target="#collapse__111" aria-expanded="false" aria-controls="collapse__111">
                                                    Express Js<span>
                                                        <i class="far fa-caret-circle-down"></i>
                                                        <i class="far fa-caret-circle-right"></i>  </span>
                                                </button>
                                            </h5>
                                        </div>
                                        <div id="collapse__111" class="collapse" aria-labelledby="heading__111" data-parent="#accordion_two">
                                            <div class="card-body">
                                                <p><p>Expressjs Introduction &amp; Setup</p>
                                                    <ul>
                                                        <li>Installing express</li>
                                                        <li>Little about routes</li>
                                                        <li>Serving static files with express</li>
                                                        <li>Express application generator</li>
                                                        <li>What is expressjs</li>
                                                    </ul>
                                                    <p>Routing in Depth</p>
                                                    <ul>
                                                        <li style={{listStyleType: "none"}}>
                                                            <ul>
                                                                <li>Route Methods</li>
                                                                <li>Route Paths</li>
                                                                <li>Parametrized Routes</li>
                                                                <li>Route Handlers</li>
                                                                <li>Express Router</li>
                                                            </ul>
                                                        </li>
                                                    </ul>
                                                    <p>&nbsp;</p>
                                                    <p>Middleware</p>
                                                    <ul>
                                                        <li style={{listStyleType: "none"}}>
                                                            <ul>
                                                                <li style={{listStyleType: "none"}}>
                                                                    <ul>
                                                                        <li>Types of middleware</li>
                                                                        <li>Application, Router,Error,Builtin</li>
                                                                        <li>Error middleware</li>
                                                                        <li>Bodyparser, cookie parser, session management</li>
                                                                        <li>Express Router</li>
                                                                    </ul>
                                                                </li>
                                                            </ul>
                                                        </li>
                                                    </ul>
                                                    <p>&nbsp;</p>
                                                    <p>Expressjs security<br />
                                                        Debugging in Express<br />
                                                        Scaling nodejs applications</p>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="card">
                                        <div class="card-header" id="heading__112">
                                            <h5 class="mb-0">
                                                <button class="btn-link collapsed" data-toggle="collapse" data-target="#collapse__112" aria-expanded="false" aria-controls="collapse__112">
                                                    MongoDB<span>
                                                        <i class="far fa-caret-circle-down"></i>
                                                        <i class="far fa-caret-circle-right"></i>  </span>
                                                </button>
                                            </h5>
                                        </div>
                                        <div id="collapse__112" class="collapse" aria-labelledby="heading__112" data-parent="#accordion_two">
                                            <div class="card-body">
                                                <p><ul>
                                                    <li>Installing MongoDB</li>
                                                    <li>The current SQL/NoSQL landscape</li>
                                                    <li>Mongo&#8217;s featureset</li>
                                                    <li>MongoDB databases,Collections and Documents</li>
                                                </ul>
                                                    <ul>
                                                        <li>CRUD Operations in Mongodb</li>
                                                        <li>Querying documents</li>
                                                        <li>Introduction to Mongoose</li>
                                                        <li>Core concepts of Mongooses</li>
                                                        <li>Extending Models</li>
                                                    </ul>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="card">
                                        <div class="card-header" id="heading__113">
                                            <h5 class="mb-0">
                                                <button class="btn-link collapsed" data-toggle="collapse" data-target="#collapse__113" aria-expanded="false" aria-controls="collapse__113">
                                                    Angular<span>
                                                        <i class="far fa-caret-circle-down"></i>
                                                        <i class="far fa-caret-circle-right"></i>  </span>
                                                </button>
                                            </h5>
                                        </div>
                                        <div id="collapse__113" class="collapse" aria-labelledby="heading__113" data-parent="#accordion_two">
                                            <div class="card-body">
                                                <p><ul>
                                                    <li>Typescript &amp; ES6</li>
                                                    <li>Scoping using Let and Const Keywords ( ES6 )</li>
                                                    <li>Power of Types</li>
                                                    <li>Explore Functions</li>
                                                    <li>Classes</li>
                                                    <li>Interfaces</li>
                                                    <li>Modules</li>
                                                    <li>Decorators ( Typescript Aspect Oriented Programming)</li>
                                                    <li>Working with Angular CLI</li>
                                                </ul>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4">

                            <div class="contact-info style-two text-left">
                                <div class="contact-list-item">
                                    <a href="tel:7378123128" class="single-contact-list">
                                        <div class="content-wrap">
                                            <div class="content">
                                                <div class="icon">
                                                    <span class="fal fa-phone"></span>
                                                </div>
                                                <div class="main-content">
                                                    <h6 class="heading">Call for advice now!</h6>
                                                    <div class="text" style={{fontSize: "22px"}}>9901371386</div>
                                                </div>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div> */}


            <div class="contact-us-section-wrappaer ">
                <div class="container">
                    <div class="row align-items-center">
                        <div class="col-lg-7 col-lg-7">
                            <div class="conact-us-wrap-one">
                                <h5 class="heading mb-20">How will you get your certificate?</h5>
                                <div class="center_details">
                                    {/* <!--   <h5 class="heading mb-20">CERTIFICATE FAQ</h5> --> */}
                                    <h2><strong>The Importance of Mean Stack Development Certification</strong></h2>
                                    <p>Obtaining a Mean Stack Development certification demonstrates your expertise and commitment to the field. Employers often value certified professionals, making certification an excellent addition to your resume.</p>
                                    <p><em><strong>IT DESK</strong></em><em> certificate provides a great way for students to get recognition in the industry and helps them grab the best job opportunities. </em>IT DESK Certificates are <strong>ISO verified</strong> which makes it highly reputed compared to the other local brands and helps the student get prioritized while going for job opportunities in the industry.</p>
                                    {/* <!-- <a class="btn btn-primary">Get Certificate</a> --> */}
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-5 col-lg-5">
                            <div class="contact-form-wrap">
                                {/* <!-- <h5 class="heading mb-20">How will you get your certificate?</h5> --> */}
                                <img src="assests/images/cert11.jpg" style={{width:" 100%", height: "370px"}} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </Fragment>
    )
}

export default MernStack
