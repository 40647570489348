import { useState, useEffect } from 'react';
//import './ContactForm.module.css';
import './ContactForm.css';



const ContactForm = () => {
    const [status, setStatus] = useState("Submit");
    const [info, setInfo] = useState({ name: '', email: '', phone: '', course: '', message: '' });

    const handleChange = (event) => {
        setInfo({
            ...info,
            [event.target.name]: event.target.value
        })
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        console.log(info);
        setStatus("Sending...");

        try {
            let response = await fetch("/api/contact", {
                method: "POST",
                headers: {
                    'Access-Control-Allow-Origin': '*',
                    'Accept': 'application/json',
                    "Content-Type": "application/json;charset=utf-8",
                },
                body: JSON.stringify(info),
            });

            if (response.ok) {
                // Reset form fields
                setInfo({ name: '', email: '', phone: '', course: '', message: '' });

                setStatus("Success"); // Update status to indicate success
            } else {
                console.error('Failed to submit form:', response.statusText);
                setStatus("Submit");
            }
        } catch (error) {
            console.error('Error submitting form:', error);
            setStatus("Submit");
        }
    };

    useEffect(() => {
        if (status === "Success") {
            // Redirect to the thank you page
            window.location.href = '/thankyou';
        }
    }, [status]);

    return (
        <div className="register">
            <div className="dsu-form">
                <div className="frmBg">
                    <small>Be a part of </small>
                    <span>IT DESK </span>
                </div>
                <div className="contact_fild">
                    <form onSubmit={handleSubmit}>
                        {/* <form id="formID" name="contact-form" method="post" action="" role="form"> */}
                        <div className="formIDfid">
                            <div className="contact-fild-box">
                                <div className="form-group">
                                    <input type="hidden" name="mydate" className="input_item" value="2023-07-28" />
                                    <input name="name" id="name" className="form-control validate[required]" placeholder="Name" data-errormessage-value-missing="*Name is required!" type="text" required value={info.name} onChange={handleChange} />
                                </div>
                            </div>
                            <div className="contact-fild-box">
                                <div className="form-group">
                                    <input className="form-control input-lg" name="location" type="hidden" value="blr.mahadevpura@itdeskindia.com" />
                                    <input name="email" id="email" className="form-control validate[required, custom[email]]" placeholder="Email" data-errormessage-value-missing="* Email is required!" type="email" required value={info.email} onChange={handleChange} />
                                </div>
                            </div>
                            <div className="contact-fild-box">
                                <div className="form-group">
                                    <input name="phone" id="phone" maxLength="12" placeholder="Phone" className="form-control validate[required, custom[phone]]" data-errormessage-value-missing="* Phone is required!" type="tel" value={info.phone} onChange={handleChange} />
                                </div>
                            </div>
                            <div className="contact-fild-box">
                                <div className="form-group">
                                    <select name="course" id="course" data-errormessage-value-missing="* Branch required!" className="form-control validate[required]" required value={info.course} onChange={handleChange}>
                                        <option value=""> Select Courses</option>

                                        <option value="Mean Stack Development">Mean Stack Development</option>
                                        <option value="Advance Java(J2EE)">Advance Java(J2EE)</option>
                                        <option value="Tally ERP">Tally ERP</option>
                                        <option value="Data Science With R Programming">Data Science With R Programming</option>
                                        <option value="PHP Course">PHP Course</option>
                                        <option value="PHP">PHP</option>
                                        <option value="Data Science with Python">Data Science with Python</option>
                                        <option value="Tableau">Tableau</option>
                                        <option value="Web Design">Web Design</option>
                                        <option value="Ethical Hacking">Ethical Hacking</option>
                                        <option value="Adv. Excel">Adv. Excel</option>
                                        <option value="Mobile Application Development (Android)">Mobile Application Development (Android)</option>
                                        <option value="ASP.NET MVC">ASP.NET MVC</option>
                                        <option value="Core Java">Core Java</option>
                                        <option value="CorelDRAW Graphics Design">CorelDRAW Graphics Design</option>
                                        <option value="Adobe Photoshop">Adobe Photoshop</option>
                                        <option value="Core Python Programming">Core Python Programming</option>
                                        <option value="C#">C#</option>
                                        <option value="C++">C++</option>
                                        <option value="C Programming">C Programming</option>
                                        <option value="C/C++">C/C++</option>
                                        <option value="Python Programming">Python Programming</option>
                                        <option value="Digital Marketing">Digital Marketing</option>
                                        <option value="Core Java Internship">Core Java Internship</option>
                                        <option value="Web Design Internship">Web Design Internship</option>

                                    </select>
                                </div>
                            </div>
                            <div className="contact-fild-box">
                                <div className="form-group">
                                    <textarea id="message" name="message" placeholder="Query" className="form-control ht validate[required]" data-errormessage-value-missing="* Query is required!" rows="2" required value={info.message} onChange={handleChange}> </textarea>
                                </div>
                            </div>
                            <div className="contact-fild-box text-center">
                                <div className="form-group">
                                    <button type="submit" id="btnSubmit" className="btn_submit" name="landing_register" >{status}</button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>

    );
};

export default ContactForm;