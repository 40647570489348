import React from 'react'

const Gallery1 = () => {
  return (
    <>
      <div className="banner-agile-2">

        <div className="navigation-w3ls">
          <nav className="navbar navbar-expand-lg navbar-light bg-light sticky-nav">
            <button className="navbar-toggler mx-auto" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
              aria-expanded="false" aria-label="Toggle navigation">
              <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse text-center" id="navbarSupportedContent">
              <ul className="navbar-nav justify-content-center">
                <li className="nav-item active">

                  <a className="nav-link text-white" href="/">Home
                    <span className="sr-only">(current)</span>
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link text-white" href="/about">About Us</a>
                </li>
                <li className="nav-item">
                  <a className="nav-link text-white" href="/allprogram">All Programs</a>
                </li>

                <li className="nav-item">
                  <a className="nav-link text-white" href="/Onlineprogram">Online Programs</a>
                </li>
                <li className="nav-item">
                  <a className="nav-link text-white" href="/gallery1">Gallery</a>
                </li>
                <li className="nav-item">
                  <a className="nav-link text-white" href="/internship">Internship</a>
                </li>
                <li className="nav-item">
                  <a className="nav-link text-white" href="/placement">Placement</a>
                </li>
                {/* 
                                <li className="nav-item dropdown">
                                    <a className="nav-link dropdown-toggle text-white" href="#" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        Courses
                                    </a>  
                                </li> */}

                {/* <li className="nav-item">
									<a className="nav-link text-white" href="/courses">courses</a>
								</li> */}
                <li className="nav-item">
                  <a className="nav-link text-white" href="/contactus">Contactus</a>
                </li>


              </ul>
            </div>
          </nav>
        </div>
      </div>
      <nav aria-label="breadcrumb" className="fixed-header">
        <ol className="breadcrumb" style={{ fontSize: "14px", fontFamily: "'Open Sans', sans-serif", padding: (".5em", ".5em"), backgroundColor: "#e9ecef" }}>
          <li className="breadcrumb-item" >
            <a href="/">Home</a>
          </li>
          <li className="breadcrumb-item active" aria-current="page" >Gallery</li>
        </ol>
      </nav>

      <div class="class">
        <div class="container">
          <div class="section-header text-center wow zoomIn" data-wow-delay="0.1s">
            <p></p>
            <h2  >Gallery</h2>
          </div>
          <div class="row">
            <div class="col-12">
              {/* <ul id="class-filter">
                    <li data-filter="*" class="filter-active">All Classes</li>
                    <li data-filter=".filter-1">Pilates Yoga</li>
                    <li data-filter=".filter-2">Hatha Yoga</li>
                    <li data-filter=".filter-3">Vinyasa yoga</li>
                </ul> */}
            </div>
          </div>
          <div class="row class-container">

            <div class="col-lg-4 col-md-6 col-12 class-item filter-1 wow fadeInUp" data-wow-delay="0.0s">
              <div class="class-wrap">
                <div class="class-img">
                  <img src="assests/image1/20863.jpg" alt="Image" className="zoom-image " style={{ height: "240px" }} />
                </div>

              </div>
            </div>
            <div class="col-lg-4 col-md-6 col-12 class-item filter-2 wow fadeInUp" data-wow-delay="0.2s" style={{ height: "260px" }}>
              <div class="class-wrap">
                <div class="class-img" style={{ height: "240px" }}>
                  <img src="assests/image1/19455 - Copy (2).jpg" alt="Image" className="zoom-image " />
                </div>

              </div>
            </div>
            <div class="col-lg-4 col-md-6 col-12 class-item filter-3 wow fadeInUp" data-wow-delay="0.4s" >
              <div class="class-wrap">
                <div class="class-img">
                  <img src="assests/image1/21742.jpg" alt="Image" className="zoom-image " style={{ height: "240px" }} />
                </div>

              </div>
            </div>


            <div class="col-lg-4 col-md-6 col-12 class-item filter-1 wow fadeInUp" data-wow-delay="0.6s">
              <div class="class-wrap">
                <div class="class-img">
                  <img src="assests/image1/21743.jpg" alt="Image" className="zoom-image " style={{ height: "260px" }} />
                </div>
                {/* <div class="class-text">
                        <div class="class-teacher">
                            <img src="assests/images/banner1.jpg""img/teacher-4.png" alt="Image"/>
                            <h3>Lilly Fry</h3>
                            <a href="">+</a>
                        </div>
                        <h2>Hatha Yoga</h2>
                        <div class="class-meta">
                            <p><i class="far fa-calendar-alt"></i>Sun, Tue, Thu</p>
                            <p><i class="far fa-clock"></i>9:00 - 10:00</p>
                        </div>
                    </div> */}
              </div>
            </div>
            <div class="col-lg-4 col-md-6 col-12 class-item filter-3 wow fadeInUp" data-wow-delay="1s" style={
              { height: "300px" }
            }>
              <div class="class-wrap">
                <div class="class-img">
                  <img src="assests/image1/22478 - Copy (2).jpg" alt="Image" className="zoom-image " style={{ height: "260px" }} />
                </div>
                {/* <div class="class-text">
                        <div class="class-teacher">
                            <img src="assests/images/banner1.jpg""img/teacher-6.png" alt="Image"/>
                            <h3>Millie Harper</h3>
                            <a href="">+</a>
                        </div>
                        <h2>Vinyasa yoga</h2>
                        <div class="class-meta">
                            <p><i class="far fa-calendar-alt"></i>Sun, Tue, Thu</p>
                            <p><i class="far fa-clock"></i>9:00 - 10:00</p>
                        </div>
                    </div> */}
              </div>
            </div>
            <div class="col-lg-4 col-md-6 col-12 class-item filter-2 wow fadeInUp" data-wow-delay="0.8s">
              <div class="class-wrap">
                <div class="class-img">
                  <img src="assests/image1/22483.jpg" alt="Image" className="zoom-image " style={{ height: "260px" }} />
                </div>
                {/* <div class="class-text">
                        <div class="class-teacher">
                            <img src="assests/images/banner1.jpg""img/teacher-5.png" alt="Image"/>
                            <h3>Olivia Yates</h3>
                            <a href="">+</a>
                        </div>
                        <h2>Kundalini Yoga</h2>
                        <div class="class-meta">
                            <p><i class="far fa-calendar-alt"></i>Sun, Tue, Thu</p>
                            <p><i class="far fa-clock"></i>9:00 - 10:00</p>
                        </div>
                    </div> */}
              </div>
            </div>
            <div class="col-lg-4 col-md-6 col-12 class-item filter-3 wow fadeInUp" data-wow-delay="1s">
              <div class="class-wrap">
                <div class="class-img">
                  <img src="assests/image1/22627.jpg" alt="Image" className="zoom-image " style={{ height: "260px" }} />
                </div>
                {/* <div class="class-text">
                        <div class="class-teacher">
                            <img src="assests/images/banner1.jpg""img/teacher-6.png" alt="Image"/>
                            <h3>Millie Harper</h3>
                            <a href="">+</a>
                        </div>
                        <h2>Vinyasa yoga</h2>
                        <div class="class-meta">
                            <p><i class="far fa-calendar-alt"></i>Sun, Tue, Thu</p>
                            <p><i class="far fa-clock"></i>9:00 - 10:00</p>
                        </div>
                    </div> */}
              </div>
            </div>
            <div class="col-lg-4 col-md-6 col-12 class-item filter-1 wow fadeInUp" data-wow-delay="0.6s">
              <div class="class-wrap">
                <div class="class-img">
                  <img src="assests/image1/29782.jpg" alt="Image" className="zoom-image " style={{ height: "260px" }} />
                </div>
                {/* <div class="class-text">
                        <div class="class-teacher">
                            <img src="assests/images/banner1.jpg""img/teacher-4.png" alt="Image"/>
                            <h3>Lilly Fry</h3>
                            <a href="">+</a>
                        </div>
                        <h2>Hatha Yoga</h2>
                        <div class="class-meta">
                            <p><i class="far fa-calendar-alt"></i>Sun, Tue, Thu</p>
                            <p><i class="far fa-clock"></i>9:00 - 10:00</p>
                        </div>
                    </div> */}
              </div>
            </div>
            <div class="col-lg-4 col-md-6 col-12 class-item filter-2 wow fadeInUp" data-wow-delay="0.8s">
              <div class="class-wrap">
                <div class="class-img">
                  <img src="assests/image1/32984.jpg" alt="Image" className="zoom-image " style={{ height: "260px" }} />
                </div>
                {/* <div class="class-text">
                        <div class="class-teacher">
                            <img src="assests/images/banner1.jpg""img/teacher-5.png" alt="Image"/>
                            <h3>Olivia Yates</h3>
                            <a href="">+</a>
                        </div>
                        <h2>Kundalini Yoga</h2>
                        <div class="class-meta">
                            <p><i class="far fa-calendar-alt"></i>Sun, Tue, Thu</p>
                            <p><i class="far fa-clock"></i>9:00 - 10:00</p>
                        </div>
                    </div> */}
              </div>
            </div>
            <div class="col-lg-4 col-md-6 col-12 class-item filter-3 wow fadeInUp" data-wow-delay="1s">
              <div class="class-wrap">
                <div class="class-img">
                  <img src="assests/image1/35995.jpg" alt="Image" className="zoom-image " style={{ height: "260px" }} />
                </div>
                {/* <div class="class-text">
                        <div class="class-teacher">
                            <img src="assests/images/banner1.jpg""img/teacher-6.png" alt="Image"/>
                            <h3>Millie Harper</h3>
                            <a href="">+</a>
                        </div>
                        <h2>Vinyasa yoga</h2>
                        <div class="class-meta">
                            <p><i class="far fa-calendar-alt"></i>Sun, Tue, Thu</p>
                            <p><i class="far fa-clock"></i>9:00 - 10:00</p>
                        </div>
                    </div> */}
              </div>
            </div>


            <div class="col-lg-4 col-md-6 col-12 class-item filter-3 wow fadeInUp" data-wow-delay="1s">
              <div class="class-wrap">
                <div class="class-img">
                  <img src="assests/image1/35998.jpg" alt="Image" className="zoom-image " style={{ height: "260px" }} />
                </div>
                {/* <div class="class-text">
                        <div class="class-teacher">
                            <img src="assests/images/banner1.jpg""img/teacher-6.png" alt="Image"/>
                            <h3>Millie Harper</h3>
                            <a href="">+</a>
                        </div>
                        <h2>Vinyasa yoga</h2>
                        <div class="class-meta">
                            <p><i class="far fa-calendar-alt"></i>Sun, Tue, Thu</p>
                            <p><i class="far fa-clock"></i>9:00 - 10:00</p>
                        </div>
                    </div> */}
              </div>
            </div>
            <div class="col-lg-4 col-md-6 col-12 class-item filter-3 wow fadeInUp" data-wow-delay="1s">
              <div class="class-wrap">
                <div class="class-img">
                  <img src="assests/image1/36770.jpg" alt="Image" className="zoom-image " style={{ height: "260px" }} />
                </div>
                {/* <div class="class-text">
                        <div class="class-teacher">
                            <img src="assests/images/banner1.jpg""img/teacher-6.png" alt="Image"/>
                            <h3>Millie Harper</h3>
                            <a href="">+</a>
                        </div>
                        <h2>Vinyasa yoga</h2>
                        <div class="class-meta">
                            <p><i class="far fa-calendar-alt"></i>Sun, Tue, Thu</p>
                            <p><i class="far fa-clock"></i>9:00 - 10:00</p>
                        </div>
                    </div> */}
              </div>
            </div>
            <div class="col-lg-4 col-md-6 col-12 class-item filter-3 wow fadeInUp" data-wow-delay="1s">
              <div class="class-wrap">
                <div class="class-img">
                  <img src="assests/image1/36847.jpg" alt="Image" className="zoom-image " />
                </div>
                {/* <div class="class-text">
                        <div class="class-teacher">
                            <img src="assests/images/banner1.jpg""img/teacher-6.png" alt="Image"/>
                            <h3>Millie Harper</h3>
                            <a href="">+</a>
                        </div>
                        <h2>Vinyasa yoga</h2>
                        <div class="class-meta">
                            <p><i class="far fa-calendar-alt"></i>Sun, Tue, Thu</p>
                            <p><i class="far fa-clock"></i>9:00 - 10:00</p>
                        </div>
                    </div> */}
              </div>
            </div>
            <div class="col-lg-4 col-md-6 col-12 class-item filter-3 wow fadeInUp" data-wow-delay="1s">
              <div class="class-wrap">
                <div class="class-img">
                  <img src="assests\image1\84528413-57cc-47dd-ac0c-d119826593da.jfif" alt="Image" className="zoom-image " />
                </div>
                {/* <div class="class-text">
                        <div class="class-teacher">
                            <img src="assests/images/banner1.jpg""img/teacher-6.png" alt="Image"/>
                            <h3>Millie Harper</h3>
                            <a href="">+</a>
                        </div>
                        <h2>Vinyasa yoga</h2>
                        <div class="class-meta">
                            <p><i class="far fa-calendar-alt"></i>Sun, Tue, Thu</p>
                            <p><i class="far fa-clock"></i>9:00 - 10:00</p>
                        </div>
                    </div> */}
              </div>
            </div>
            <div class="col-lg-4 col-md-6 col-12 class-item filter-3 wow fadeInUp" data-wow-delay="1s">
              <div class="class-wrap">
                <div class="class-img">
                  <img src="assests/image1/2ef21b68-50b3-4c9a-8f73-2d33e3c3bc0a.jfif" alt="Image" className="zoom-image " />
                </div>
                {/* <div class="class-text">
                        <div class="class-teacher">
                            <img src="assests/images/banner1.jpg""img/teacher-6.png" alt="Image"/>
                            <h3>Millie Harper</h3>
                            <a href="">+</a>
                        </div>
                        <h2>Vinyasa yoga</h2>
                        <div class="class-meta">
                            <p><i class="far fa-calendar-alt"></i>Sun, Tue, Thu</p>
                            <p><i class="far fa-clock"></i>9:00 - 10:00</p>
                        </div>
                    </div> */}
              </div>
            </div>
            <div class="col-lg-4 col-md-6 col-12 class-item filter-3 wow fadeInUp" data-wow-delay="1s">
              <div class="class-wrap">
                <div class="class-img">
                  <img src="assests/image1/8bd0b8fb-e912-4aa8-ba86-6d59b03598ae.jfif" alt="Image" className="zoom-image " />
                </div>
                {/* <div class="class-text">
                        <div class="class-teacher">
                            <img src="assests/images/banner1.jpg""img/teacher-6.png" alt="Image"/>
                            <h3>Millie Harper</h3>
                            <a href="">+</a>
                        </div>
                        <h2>Vinyasa yoga</h2>
                        <div class="class-meta">
                            <p><i class="far fa-calendar-alt"></i>Sun, Tue, Thu</p>
                            <p><i class="far fa-clock"></i>9:00 - 10:00</p>
                        </div>
                    </div> */}
              </div>
            </div>
            <div class="col-lg-4 col-md-6 col-12 class-item filter-3 wow fadeInUp" data-wow-delay="1s">
              <div class="class-wrap">
                <div class="class-img">
                  <img src="assests/image1/9cfcd35d-4770-4ea7-9b0f-84aefdb5ca7a.jfif" alt="Image" className="zoom-image " />
                </div>
                {/* <div class="class-text">
                        <div class="class-teacher">
                            <img src="assests/images/banner1.jpg""img/teacher-6.png" alt="Image"/>
                            <h3>Millie Harper</h3>
                            <a href="">+</a>
                        </div>
                        <h2>Vinyasa yoga</h2>
                        <div class="class-meta">
                            <p><i class="far fa-calendar-alt"></i>Sun, Tue, Thu</p>
                            <p><i class="far fa-clock"></i>9:00 - 10:00</p>
                        </div>
                    </div> */}
              </div>
            </div>
            <div class="col-lg-4 col-md-6 col-12 class-item filter-3 wow fadeInUp" data-wow-delay="1s">
              <div class="class-wrap">
                <div class="class-img">
                  <img src="assests/image1/ac6313fc-cf2f-4133-9235-994e57c89961.jfif" alt="Image" className="zoom-image " />
                </div>
                {/* <div class="class-text">
                        <div class="class-teacher">
                            <img src="assests/images/banner1.jpg""img/teacher-6.png" alt="Image"/>
                            <h3>Millie Harper</h3>
                            <a href="">+</a>
                        </div>
                        <h2>Vinyasa yoga</h2>
                        <div class="class-meta">
                            <p><i class="far fa-calendar-alt"></i>Sun, Tue, Thu</p>
                            <p><i class="far fa-clock"></i>9:00 - 10:00</p>
                        </div>
                    </div> */}
              </div>
            </div>

            <div class="col-lg-4 col-md-6 col-12 class-item filter-3 wow fadeInUp" data-wow-delay="1s">
              <div class="class-wrap">
                <div class="class-img">
                  <img src="assests/image1/ad7b4494-c8ff-4cb5-a0c1-8b84801260b2.jfif" alt="Image" className="zoom-image " style={{ height: "260px" }} />
                </div>
                {/* <div class="class-text">
                        <div class="class-teacher">
                            <img src="assests/images/banner1.jpg""img/teacher-6.png" alt="Image"/>
                            <h3>Millie Harper</h3>
                            <a href="">+</a>
                        </div>
                        <h2>Vinyasa yoga</h2>
                        <div class="class-meta">
                            <p><i class="far fa-calendar-alt"></i>Sun, Tue, Thu</p>
                            <p><i class="far fa-clock"></i>9:00 - 10:00</p>
                        </div>
                    </div> */}
              </div>
            </div>



            <div class="col-lg-4 col-md-6 col-12 class-item filter-3 wow fadeInUp" data-wow-delay="1s">
              <div class="class-wrap">
                <div class="class-img">
                  <img src="assests/image1/c87add60-beeb-4a24-b6fa-195ebfc294ae.jfif" alt="Image" className="zoom-image " style={{ height: "260px" }} />
                </div>
                {/* <div class="class-text">
                        <div class="class-teacher">
                            <img src="assests/images/banner1.jpg""img/teacher-6.png" alt="Image"/>
                            <h3>Millie Harper</h3>
                            <a href="">+</a>
                        </div>
                        <h2>Vinyasa yoga</h2>
                        <div class="class-meta">
                            <p><i class="far fa-calendar-alt"></i>Sun, Tue, Thu</p>
                            <p><i class="far fa-clock"></i>9:00 - 10:00</p>
                        </div>
                    </div> */}
              </div>
            </div>

            <div class="col-lg-4 col-md-6 col-12 class-item filter-3 wow fadeInUp" data-wow-delay="1s">
              <div class="class-wrap">
                <div class="class-img">
                  <img src="assests/image1/c9179c88-fefd-495d-bb2d-c84184ac7b52.jfif" alt="Image" className="zoom-image " style={{ height: "260px" }} />
                </div>
                {/* <div class="class-text">
                        <div class="class-teacher">
                            <img src="assests/images/banner1.jpg""img/teacher-6.png" alt="Image"/>
                            <h3>Millie Harper</h3>
                            <a href="">+</a>
                        </div>
                        <h2>Vinyasa yoga</h2>
                        <div class="class-meta">
                            <p><i class="far fa-calendar-alt"></i>Sun, Tue, Thu</p>
                            <p><i class="far fa-clock"></i>9:00 - 10:00</p>
                        </div>
                    </div> */}
              </div>
            </div>


            <div class="col-lg-4 col-md-6 col-12 class-item filter-3 wow fadeInUp" data-wow-delay="1s" style={{ height: "300px" }}>
              <div class="class-wrap">
                <div class="class-img">
                  <img src="assests/image1/de1aa23e-e33b-4b6b-956e-cb082009563f.jfif" alt="Image" className="zoom-image " style={{ height: "300px" }} />
                </div>
                {/* <div class="class-text">
                        <div class="class-teacher">
                            <img src="assests/images/banner1.jpg""img/teacher-6.png" alt="Image"/>
                            <h3>Millie Harper</h3>
                            <a href="">+</a>
                        </div>
                        <h2>Vinyasa yoga</h2>
                        <div class="class-meta">
                            <p><i class="far fa-calendar-alt"></i>Sun, Tue, Thu</p>
                            <p><i class="far fa-clock"></i>9:00 - 10:00</p>
                        </div>
                    </div> */}
              </div>
            </div>





















            <div class="col-lg-4 col-md-6 col-12 class-item filter-3 wow fadeInUp" data-wow-delay="1s" style={{ height: "300px" }}>
              <div class="class-wrap">
                <div class="class-img">
                  <img src="assests/image1/IMG_20230728_154207.jpg" alt="Image" className="zoom-image " style={{ height: "300px" }} />
                </div>
                {/* <div class="class-text">
                        <div class="class-teacher">
                            <img src="assests/images/banner1.jpg""img/teacher-6.png" alt="Image"/>
                            <h3>Millie Harper</h3>
                            <a href="">+</a>
                        </div>
                        <h2>Vinyasa yoga</h2>
                        <div class="class-meta">
                            <p><i class="far fa-calendar-alt"></i>Sun, Tue, Thu</p>
                            <p><i class="far fa-clock"></i>9:00 - 10:00</p>
                        </div>
                    </div> */}
              </div>
            </div>

            <div class="col-lg-4 col-md-6 col-12 class-item filter-3 wow fadeInUp" data-wow-delay="1s" style={{ height: "300px" }}>
              <div class="class-wrap">
                <div class="assests/image1/IMG_20230728_154511.jpg" alt="Image" className="zoom-image " style={{ height: "300px" }} />
              </div>
              {/* <div class="class-text">
                        <div class="class-teacher">
                            <img src="assests/images/banner1.jpg""img/teacher-6.png" alt="Image"/>
                            <h3>Millie Harper</h3>
                            <a href="">+</a>
                        </div>
                        <h2>Vinyasa yoga</h2>
                        <div class="class-meta">
                            <p><i class="far fa-calendar-alt"></i>Sun, Tue, Thu</p>
                            <p><i class="far fa-clock"></i>9:00 - 10:00</p>
                        </div>
                    </div> */}
            </div>
          </div>




          <div class="col-lg-4 col-md-6 col-12 class-item filter-3 wow fadeInUp" data-wow-delay="1s" style={{ height: "300px" }}>
            <div class="class-wrap">
              <div class="class-img">
                <img src="assests/image1/IMG_20230728_154511.jpg" alt="Image" className="zoom-image " style={{ height: "300px" }} />
              </div>
              {/* <div class="class-text">
                        <div class="class-teacher">
                            <img src="assests/images/banner1.jpg""img/teacher-6.png" alt="Image"/>
                            <h3>Millie Harper</h3>
                            <a href="">+</a>
                        </div>
                        <h2>Vinyasa yoga</h2>
                        <div class="class-meta">
                            <p><i class="far fa-calendar-alt"></i>Sun, Tue, Thu</p>
                            <p><i class="far fa-clock"></i>9:00 - 10:00</p>
                        </div>
                    </div> */}
            </div>
          </div>


          <div class="col-lg-4 col-md-6 col-12 class-item filter-3 wow fadeInUp" data-wow-delay="1s">
            <div class="class-wrap">
              <div class="class-img">
                <img src="assests/image1/IMG_20230728_155014.jpg" alt="Image" className="zoom-image " style={{ height: "300px" }} />
              </div>
              {/* <div class="class-text">
                        <div class="class-teacher">
                            <img src="assests/images/banner1.jpg""img/teacher-6.png" alt="Image"/>
                            <h3>Millie Harper</h3>
                            <a href="">+</a>
                        </div>
                        <h2>Vinyasa yoga</h2>
                        <div class="class-meta">
                            <p><i class="far fa-calendar-alt"></i>Sun, Tue, Thu</p>
                            <p><i class="far fa-clock"></i>9:00 - 10:00</p>
                        </div>
                    </div> */}
            </div>
          </div>

          <div class="col-lg-4 col-md-6 col-12 class-item filter-3 wow fadeInUp" data-wow-delay="1s">
            <div class="class-wrap">
              <div class="class-img">
                <img src="assests/image1/IMG_20230729_115606.jpg" alt="Image" className="zoom-image " style={{ height: "300px" }} />
              </div>
              {/* <div class="class-text">
                        <div class="class-teacher">
                            <img src="assests/images/banner1.jpg""img/teacher-6.png" alt="Image"/>
                            <h3>Millie Harper</h3>
                            <a href="">+</a>
                        </div>
                        <h2>Vinyasa yoga</h2>
                        <div class="class-meta">
                            <p><i class="far fa-calendar-alt"></i>Sun, Tue, Thu</p>
                            <p><i class="far fa-clock"></i>9:00 - 10:00</p>
                        </div>
                    </div> */}
            </div>
          </div>


          <div class="col-lg-4 col-md-6 col-12 class-item filter-3 wow fadeInUp" data-wow-delay="1s">
            <div class="class-wrap">
              <div class="class-img">
                <img src="assests/image1/IMG_20230729_115732.jpg" alt="Image" className="zoom-image " style={{ height: "300px" }} />
              </div>
              {/* <div class="class-text">
                        <div class="class-teacher">
                            <img src="assests/images/banner1.jpg""img/teacher-6.png" alt="Image"/>
                            <h3>Millie Harper</h3>
                            <a href="">+</a>
                        </div>
                        <h2>Vinyasa yoga</h2>
                        <div class="class-meta">
                            <p><i class="far fa-calendar-alt"></i>Sun, Tue, Thu</p>
                            <p><i class="far fa-clock"></i>9:00 - 10:00</p>
                        </div>
                    </div> */}
            </div>
          </div>

          <div class="col-lg-4 col-md-6 col-12 class-item filter-3 wow fadeInUp" data-wow-delay="1s">
            <div class="class-wrap">
              <div class="class-img">
                <img src="assests/image1/IMG_20230729_115910.jpg" alt="Image" className="zoom-image " style={{ height: "300px" }} />
              </div>
              {/* <div class="class-text">
                        <div class="class-teacher">
                            <img src="assests/images/banner1.jpg""img/teacher-6.png" alt="Image"/>
                            <h3>Millie Harper</h3>
                            <a href="">+</a>
                        </div>
                        <h2>Vinyasa yoga</h2>
                        <div class="class-meta">
                            <p><i class="far fa-calendar-alt"></i>Sun, Tue, Thu</p>
                            <p><i class="far fa-clock"></i>9:00 - 10:00</p>
                        </div>
                    </div> */}
            </div>
          </div>


          <div class="col-lg-4 col-md-6 col-12 class-item filter-3 wow fadeInUp" data-wow-delay="1s">
            <div class="class-wrap">
              <div class="class-img">
                <img src="assests/image1/IMG_20230729_132142.jpg" alt="Image" className="zoom-image " style={{ height: "300px" }} />
              </div>
              {/* <div class="class-text">
                        <div class="class-teacher">
                            <img src="assests/images/banner1.jpg""img/teacher-6.png" alt="Image"/>
                            <h3>Millie Harper</h3>
                            <a href="">+</a>
                        </div>
                        <h2>Vinyasa yoga</h2>
                        <div class="class-meta">
                            <p><i class="far fa-calendar-alt"></i>Sun, Tue, Thu</p>
                            <p><i class="far fa-clock"></i>9:00 - 10:00</p>
                        </div>
                    </div> */}
            </div>
          </div>



          <div class="col-lg-4 col-md-6 col-12 class-item filter-3 wow fadeInUp" data-wow-delay="1s">
            <div class="class-wrap">
              <div class="class-img">
                <img src="assests/image1/IMG_20230729_132142.jpg" alt="Image" className="zoom-image " style={{ height: "300px" }} />
              </div>
              {/* <div class="class-text">
                        <div class="class-teacher">
                            <img src="assests/images/banner1.jpg""img/teacher-6.png" alt="Image"/>
                            <h3>Millie Harper</h3>
                            <a href="">+</a>
                        </div>
                        <h2>Vinyasa yoga</h2>
                        <div class="class-meta">
                            <p><i class="far fa-calendar-alt"></i>Sun, Tue, Thu</p>
                            <p><i class="far fa-clock"></i>9:00 - 10:00</p>
                        </div>
                    </div> */}
            </div>
          </div>


          <div class="col-lg-4 col-md-6 col-12 class-item filter-3 wow fadeInUp" data-wow-delay="1s">
            <div class="class-wrap">
              <div class="class-img">
                <img src="assests/image1/IMG_20230816_135000.jpg" alt="Image" className="zoom-image " style={{ height: "300px" }} />
              </div>
              {/* <div class="class-text">
                        <div class="class-teacher">
                            <img src="assests/images/banner1.jpg""img/teacher-6.png" alt="Image"/>
                            <h3>Millie Harper</h3>
                            <a href="">+</a>
                        </div>
                        <h2>Vinyasa yoga</h2>
                        <div class="class-meta">
                            <p><i class="far fa-calendar-alt"></i>Sun, Tue, Thu</p>
                            <p><i class="far fa-clock"></i>9:00 - 10:00</p>
                        </div>
                    </div> */}
            </div>
          </div>




        </div>
      </div>



      {/* <div class="class">
        <div class="container">
          <div class="section-header text-center wow zoomIn" data-wow-delay="0.1s">
            <p>Our </p>
            <h2  >Videos</h2>
          </div>
          <div class="row">
            <div class="col-12">

            </div>
          </div>
          <div class="row class-container">

            <div className="col-lg-4 col-md-6 col-12 class-item filter-1 wow fadeInUp" data-wow-delay="0.0s">
              <div className="class-wrap">
                <video controls style={{ width: "100%", height: "auto" }}>
                  <source src="assests\image1\video_20230914_101845.mp4" type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              </div>
            </div>



            <div className="col-lg-4 col-md-6 col-12 class-item filter-1 wow fadeInUp" data-wow-delay="0.0s">
              <div className="class-wrap">
                <video controls style={{ width: "100%", height: "auto" }}>
                  <source src="img/New folder (2)/video 11_cropped.mp4" type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-12 class-item filter-1 wow fadeInUp" data-wow-delay="0.0s">
              <div className="class-wrap">
                <video controls style={{ width: "100%", height: "400px" }}>
                  <source src="img/New folder (2)/video15.mp4" type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              </div>
            </div>










          </div>
        </div>
      </div> */}




    </>
  )
}

export default Gallery1
