import React, { Fragment } from 'react'

const Python = () => {
    return (
        <Fragment>
            <div class="header-area">

                <div class="header-top-bar-info bg-gray-black d-none d-lg-block">
                    <div class="container">
                        <div class="row">
                            <div class="col-md-12">
                                <div class="top-bar-wrap">
                                    <div class="top-bar-left">
                                        <ul class="top-bar-info">

                                            <li class="info-item newTop">
                                                <a href="https://itdeskindia.com/" class="info-link" target="_blank">
                                                    <strong>IT DESK</strong>
                                                    {/* <!-- <span class="ht-btn ht-btn-md"><strong>IT DESK</strong></span> --> */}
                                                </a>
                                            </li>
                                            <li class="info-item newTop slash"></li>
                                            <li class="info-item newTop slash"></li>

                                            <li class="info-item newTop">
                                                <a href="https://creativedeskindia.com/" class="info-link" target="_blank">
                                                    <strong>Creative Desk</strong>
                                                    {/* <!-- <span class="ht-btn ht-btn-md"><strong>Creative Desk</strong></span> --> */}
                                                </a>
                                            </li>
                                            <li class="info-item newTop slash"></li>
                                            <li class="info-item newTop slash"></li>
                                            <li class="info-item newTop">
                                                <a href="tel:7378123128" class="info-link">
                                                    <i class="info-icon fa fa-phone"></i>
                                                    <span class="info-text"><strong>7378123128</strong></span>
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                    <div class="top-bar-right">
                                        <ul class="top-bar-info">
                                            <li class="info-item">
                                                <a href="https://itdeskindia.com/about-us/" class="info-link">
                                                    <span class="info-text"><strong>About Us</strong></span>
                                                </a>
                                            </li>
                                            <li class="info-item slash">|</li>
                                            <li class="info-item">
                                                <a href="https://itdeskindia.com/career/" class="info-link">
                                                    <span class="info-text"><strong>Career</strong></span>
                                                </a>
                                            </li>
                                            <li class="info-item slash">|</li>
                                            <li class="info-item">
                                                <a href="https://itdeskindia.com/placements/" class="info-link">
                                                    <span class="info-text"><strong>Placements</strong></span>
                                                </a>
                                            </li>
                                            <li class="info-item slash">|</li>
                                            <li class="info-item">
                                                <a href="https://itdeskindia.com/categories/internship-program/" class="info-link">
                                                    <span class="info-text"><strong>Internship</strong></span>
                                                </a>
                                            </li>
                                            <li class="info-item slash">|</li>
                                            <li class="info-item dropdown ">
                                                <a href="javascript::" class="info-link">
                                                    <span class="info-text loginbtn"><strong>Login</strong></span>
                                                </a>
                                                {/* <ul class="dropdown-content">
                                                    <li><a href="https://itdeskindia.com/student/" target="_blank">Student Login</a></li>
                                                    <li><a href="https://itdeskindia.com/itdesk/" target="_blank">Partner Login</a></li>
                                                </ul> */}
                                            </li>

                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div class="header-bottom-wrap header-sticky bg-white">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="header position-relative">


                                <div class="header__logo">
                                    <a href="https://itdeskindia.com">
                                        <img src="https://itdeskindia.com/wp-content/uploads/2021/03/it-desk-logo.png" class="img-fluid caddeskLogo" alt="" />
                                    </a>
                                </div>
                                <div class="header-right">

                                    <div class="header__navigation menu-style-three d-none d-xl-block">
                                        <nav class="navigation-menu">
                                            <ul>
                                                {/* <!-- <li>
                                                    <a href=""><span>Home</span></a>
                                                </li> --> */}
                                                <li class="has-children">
                                                    <a href="javascript::"><span class="all-prograambtn">All programs</span></a>

                                                    <div class="row megamenu megamenu--mega">
                                                        <div class="col-12">
                                                            <div class="col-3" style={{float: "left"}}>
                                                                <a href="https://itdeskindia.com/categories/trending-courses/"><h2 class="page-list-title" style={{fontSize: "15px"}}>Trending Courses</h2></a>
                                                                <ul id="ajax-posts0">
                                                                    <li><a href="https://itdeskindia.com/course/data-science-with-python-course-in-jaipur/"><span style={{color:"black"}}>Data Science with Python</span></a></li>
                                                                    <li><a href="https://itdeskindia.com/course/data-science-with-r-programming-course-in-jaipur/"><span  style={{color:"black"}}>Data Science With R Programming</span></a></li>
                                                                    <li><a href="https://itdeskindia.com/course/digital-marketing-course-training-in-jaipur/"><span style={{color:"black"}}>Digital Marketing</span></a></li>
                                                                    <li><a href="https://itdeskindia.com/course/mean-stack-development-course-training-in-jaipur/"><span  style={{color:"black"}}>Mean Stack Development</span></a></li>

                                                                </ul>
                                                                <ul>
                                                                    <li>
                                                                        <a href="javascript::" id="more_posts" data-category="16" data-id="ajax-posts0" data-page="2" style={{color: "#ec8437"}}>More
                                                                        </a>
                                                                    </li>
                                                                </ul>

                                                            </div>
                                                            <div class="col-3" style={{float: "left"}}>
                                                                <a href="https://itdeskindia.com/categories/cs-it/"><h2 class="page-list-title" style={{fontSize: "15px"}}>CS/IT</h2></a>
                                                                <ul id="ajax-posts1">
                                                                    <li><a href="https://itdeskindia.com/course/adobe-photoshop-course-training-in-jaipur/"><span  style={{color:"black"}}>Adobe Photoshop</span></a></li>
                                                                    <li><a href="https://itdeskindia.com/course/adv-excel-course-training-in-jaipur/"><span  style={{color:"black"}}>Adv. Excel</span></a></li>
                                                                    <li><a href="https://itdeskindia.com/course/advance-java-j2ee-course-training-in-jaipur/"><span  style={{color:"black"}}>Advance Java(J2EE)</span></a></li>
                                                                    <li><a href="https://itdeskindia.com/course/asp-net-mvc-course-training-in-jaipur/"><span  style={{color:"black"}}>ASP.NET MVC</span></a></li>

                                                                </ul>
                                                                <ul>
                                                                    <li>
                                                                        <a href="javascript::" id="more_posts" data-category="12" data-id="ajax-posts1" data-page="2" style={{color:" #ec8437"}}>More
                                                                        </a>
                                                                    </li>
                                                                </ul>

                                                            </div>
                                                            <div class="col-3" style={{float: "left"}}>
                                                                <a href="https://itdeskindia.com/categories/internship-program/"><h2 class="page-list-title" style={{fontSize: "15px"}}>Internships</h2></a>
                                                                <ul id="ajax-posts2">
                                                                    <li><a href="https://itdeskindia.com/course/advance-java-j2ee-course-training-in-jaipur/"><span  style={{color:"black"}}>Advance Java(J2EE)</span></a></li>
                                                                    <li><a href="https://itdeskindia.com/course/c-programming-internship-in-jaipur/"><span style={{color:"black"}}>C/C++</span></a></li>
                                                                    <li><a href="https://itdeskindia.com/course/core-java-internship-in-jaipur/"><span  style={{color:"black"}}>Core Java Internship</span></a></li>
                                                                    <li><a href="https://itdeskindia.com/course/digital-marketing-course-training-in-jaipur/"><span  style={{color:"black"}}>Digital Marketing</span></a></li>

                                                                </ul>
                                                                <ul>
                                                                    <li>
                                                                        <a href="javascript::" id="more_posts" data-category="5" data-id="ajax-posts2" data-page="2" style={{color: "#ec8437"}}>More
                                                                        </a>
                                                                    </li>
                                                                </ul>

                                                            </div>
                                                            <div class="col-3" style={{float: "left"}}>
                                                                <h2 class="page-list-title" style={{fontSize: "15px"}}>OTHER PROGRAMS</h2>
                                                                <ul>
                                                                    <li><a href="https://itdeskindia.com/college-training/"><span  style={{color:"black"}}>College training</span></a></li>
                                                                    <li><a href="https://itdeskindia.com/corporate-training/"><span  style={{color:"black"}}>Corporate training</span></a></li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </li>
                                                <li>
                                                    <a href="https://www.caddeskindia.com/online-training/" target="_blank"><span>Online programs</span></a>
                                                </li>
                                                <li class="has-children has-children--multilevel-submenu">
                                                    <a href="javascript::"><span>Student’s corner</span></a>
                                                    <ul class="submenu">
                                                        <li><a href="https://itdeskindia.com/certificate-verification/"><span>Certificate verification</span></a></li>
                                                        <li><a href="https://itdeskindia.com/notification-offers-schemes/"><span>Notification Offers & Schemes</span></a></li>
                                                    </ul>
                                                </li>

                                                {/* <!--  <li>
                                                <a href="/events/"><span>Events</span></a>
                                            </li> --> */}
                                                <li>
                                                    <a href="https://itdeskindia.com/franchise/"><span>Franchise</span></a>
                                                </li>
                                                <li>
                                                    <a href="https://itdeskindia.com/our-networks/"><span>Locate Centre</span></a>
                                                </li>
                                            </ul>
                                        </nav>
                                    </div>
                                    <div class="serching">
                                        <select class="form-control" id="seacrhCourse">
                                            <option value="">Search course</option>
                                            <option value="https://itdeskindia.com/course/mean-stack-development-course-training-in-jaipur/">Mean Stack</option>
                                            <option value="https://itdeskindia.com/course/advance-java-j2ee-course-training-in-jaipur/">Advance Ja</option>
                                            <option value="https://itdeskindia.com/course/tally-erp-course-training-in-jaipur/">Tally ERP</option>
                                            <option value="https://itdeskindia.com/course/data-science-with-r-programming-course-in-jaipur/">Data Scien</option>
                                            <option value="https://itdeskindia.com/course/php-course-training-in-jaipur/">PHP Course</option>
                                            <option value="https://itdeskindia.com/course/php-internship-in-jaipur/">PHP Intern</option>
                                            <option value="https://itdeskindia.com/course/data-science-with-python-course-in-jaipur/">Data Scien</option>
                                            <option value="https://itdeskindia.com/course/tableau-course-training-institute-in-jaipur/">Tableau</option>
                                            <option value="https://itdeskindia.com/course/web-design-course-training-in-jaipur/">Web Design</option>
                                            <option value="https://itdeskindia.com/course/ethical-hacking-course-in-jaipur/">Ethical Ha</option>
                                            <option value="https://itdeskindia.com/course/adv-excel-course-training-in-jaipur/">Adv. Excel</option>
                                            <option value="https://itdeskindia.com/course/mobile-application-development-android-course-training-in-jaipur/">Mobile App</option>
                                            <option value="https://itdeskindia.com/course/asp-net-mvc-course-training-in-jaipur/">ASP.NET MV</option>
                                            <option value="https://itdeskindia.com/course/core-java-course-training-in-jaipur/">Core Java</option>
                                            <option value="https://itdeskindia.com/course/coreldraw-graphics-design-course-training-in-jaipur/">CorelDRAW </option>
                                            <option value="https://itdeskindia.com/course/adobe-photoshop-course-training-in-jaipur/">Adobe Phot</option>
                                            <option value="https://itdeskindia.com/course/python-course-in-jaipur/">Core Pytho</option>
                                            <option value="https://itdeskindia.com/course/c-sap-training-in-jaipur/">C#</option>
                                            <option value="https://itdeskindia.com/course/c-plus-plus-course-training-in-jaipur/">C++</option>
                                            <option value="https://itdeskindia.com/course/c-programming/">C Programm</option>
                                            <option value="https://itdeskindia.com/course/c-programming-internship-in-jaipur/">C/C++</option>
                                            <option value="https://itdeskindia.com/course/core-python-programming-internship-in-jaipur/">Python Pro</option>
                                            <option value="https://itdeskindia.com/course/digital-marketing-course-training-in-jaipur/">Digital Ma</option>
                                            <option value="https://itdeskindia.com/course/core-java-internship-in-jaipur/">Core Java </option>
                                            <option value="https://itdeskindia.com/course/web-design-internship-jaipur/">Web Design</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        






        </Fragment >
    )
}

export default Python
