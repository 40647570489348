import React, { Fragment } from 'react'

const Footer = () => {
  return (
   <Fragment>
    
    <footer>
                <div className="container py-4">
                    <div className="row py-xl-5 py-sm-3">
                        <div className="col-lg-6 map">
                            <h2 className="contact-title text-capitalize text-white font-weight-light mb-sm-4 mb-3">our
                                <span className="font-weight-bold">directions</span>
                            </h2>
                            {/* <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15550.267934575879!2d77.6987375!3d12.9995245!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae11c247a3c025%3A0xb8afde30270c76bb!2sITDESK%20MAHADEVPURA!5e0!3m2!1sen!2sin!4v1690535063147!5m2!1sen!2sin"></iframe> */}
                            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3887.5670664397067!2d77.69618407397932!3d12.999519214267812!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae11e5beced50b%3A0x6dfbb6145c02cc8b!2sExcerpt%20Technologies%20Private%20Limited!5e0!3m2!1sen!2sin!4v1689927971916!5m2!1sen!2sin"></iframe>
                            {/* <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3023.495758873587!2d-74.0005340845242!3d40.72911557933012!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c25991b7473067%3A0x59fbd02f7b519ce8!2s550+LaGuardia+Pl%2C+New+York%2C+NY+10012%2C+USA!5e0!3m2!1sen!2sin!4v1516166447283"></iframe> */}
                            <div className="conta-posi-w3ls p-4 rounded">
                                <h5 className="text-white font-weight-bold mb-3">Address</h5>
                                <p># B133/1, 2nd Floor, ITI Estate, Whitefield Road
                                    <span>Mahadevpura,</span> Bengaluru - 560048</p>
                            </div>
                        </div>
                        <div className="col-lg-6 contact-agileits-w3layouts mt-lg-0 mt-4">
                            <h4 className="contact-title text-capitalize text-white font-weight-light mb-sm-4 mb-3">get in
                                <span className="font-weight-bold">touch</span>
                            </h4>
                            <p className="conta-para-style border-left pl-4">If you have any questions about the services we provide simply use the form below. We try and respond to all queries
                                and comments within 24 hours.</p>
                            <br />
                            <br />
                            <br />
                            <p className="para-agileits-w3layouts text-white"  >
                                <i className="fas fa-map-marker pr-3" ></i>IT Desk Mahadevapura</p>
                            <p className="para-agileits-w3layouts  my-sm-3 my-2 text-white">
                                <i className="fas fa-phone pr-3"></i><a onclick="return gtag_report_conversion('tel:9900502404');" href="tel:9900502404">+91 9900502404 /</a>

<a onclick="return gtag_report_conversion('tel:9901371386');" href="tel:9901371386">9901371386</a> </p>
                            
                            <p className="para-agileits-w3layouts">
                                <i className="far fa-envelope-open pr-2"></i>
                                <a href="mailto:info@itdeskmahadevpura.com" className=" text-blue"> info@itdeskmahadevpura.com</a>
                            </p>
                        </div>
                    </div>
                </div>
                <div className="copyright-agiles py-3">
                    <div className="container">
                        <div className="row">
                            <p className="col-lg-8 copy-right-grids text-white text-lg-left text-center mt-lg-1">© 2023 IT Desk Mahadevpura.                                 <a href="/" target="_blank">  All Rights Reserved</a>
                            </p>

                            <div className="social-icons text-lg-right text-center col-lg-4 col-5 mt-lg-0 mt-3">
                                <ul className="list-unstyled">
                                    <li>
                                        <a href="#" className="fab fa-facebook-f icon-border facebook rounded-circle"> </a>
                                    </li>
                                    <li className="mx-2">
                                    <a href="#" className="fab fa-instagram icon-border instagram rounded-circle"> </a>
                                    </li>
                                    <li>
                                        <a href="#" className="fab fa-google-plus-g icon-border googleplus rounded-circle"> </a>
                                    </li>
                                    <li className="ml-2">
                                    <a href="#" className="fab fa-youtube icon-border youtube rounded-circle"> </a>
                                    </li>
                                </ul>
                            </div>

                        </div>
                    </div>
                </div>
            </footer>

   </Fragment>
  )
}

export default Footer


