import React from 'react';
import TagManager from 'react-gtm-module';

import './App.css';
import { BrowserRouter as Router, Routes, Route ,Navigate,useNavigate} from 'react-router-dom';

import About from './pages/About';
import Index from './pages/Index';
import Gallery from './pages/Gallery';
import Contactus from './pages/Contactus';
import Footer from './components/Footer';
import Header from './components/Header';
import Courses from './pages/Courses';
import Scrollbutton from './components/Scrollbutton';
import WhatsAppButton from './components/WhatsAppButton';
import Allprogram from './pages/Allprogram';
import Internship from './pages/Internship';
import Placement from './pages/Placement';
import Onlineprogram from './pages/Onlineprogram';
import TrendingCourses from './pages/TrendingCourses';

import { useState,useEffect } from 'react';
// import Navbar from './components/Navbar';
import Corejava from './components/Corejava';
import DigitalMarketing from './components/DigitalMarketing';
import DataSciencewithPython from './components/DataSciencewithPython';
import MernStack from './components/MernStack';
import Net from './components/Net';
import EhicalHacking from './components/EhicalHacking';
import Fullstack from './components/Fullstack';
import Csharp from './components/Csharp';
import Cprogramming from './components/Cprogramming';
import Android from './components/Android';
import Php from './components/Php';
import Selenium from './components/Selenium';
import Aws from './components/Aws';
import MySql from './components/MySql';
import Python from './components/Python';
import Aexcel from './pages/Aexcel';
import Msoffice from './pages/Msoffice';
import Sap from './pages/Sap';
import Tally from './pages/Tally';
import Gallery1 from './pages/Gallery1';
import Login  from './components/Login';
import Register1 from './components/Register1';
import Dashboard from './pages/Dashboard'
import Certificate from './pages/Certificate';
import CertificateGenerator from './components/CertificateGenerator';
import Thankyou from './pages/ThankYou'


const gtmId = 'AW-11280666360';
TagManager.initialize({ gtmId });
TagManager.initialize('G-G9DPDN91EW');
TagManager.initialize('GTM-PZVQS6CR');



const ProtectedRoute = ({ element, isLoggedIn, path }) => {
	return isLoggedIn ? element : <Navigate to="/login" />;
  };

	
  
const App = () => {
	
	const [isLoggedIn, setIsLoggedIn] = useState(false);
	const [isAdmin, setIsAdmin] = useState(false);
  
	useEffect(() => {
	  // Check if admin is logged in from sessionStorage
	  const isAdminLoggedIn = sessionStorage.getItem('isAdminLoggedIn');
	  if (isAdminLoggedIn) {
		setIsAdmin(true);
		setIsLoggedIn(true); // Assuming admin login also means user is logged in
	  }
	}, []);
  
	const handleLogin = () => {
	  // Your login logic here
	  setIsLoggedIn(true);
	  setIsAdmin(true);
	  // Store admin login state in sessionStorage
	  sessionStorage.setItem('isAdminLoggedIn', 'true');
	};
  
	const handleLogout = () => {
	  // Your logout logic here
	  setIsLoggedIn(false);
	  setIsAdmin(false);
	  sessionStorage.removeItem('isAdminLoggedIn');
	};
  


 
return (
	
	<div className="App">
		<Router>
			
			<WhatsAppButton/>
			<Scrollbutton/>
			<Header  isLoggedIn={isLoggedIn} onLogout={handleLogout} />
			{/* <Navbar/> */}
			
		
			<Routes>
				<Route path='/'element={<Index/>}/>
				<Route path='/about'element={<About/>}/>
				<Route path='/gallery'element={<Gallery/>}/>
				{/* <Route path='/courses'element={<Courses/>}/> */}
				<Route path='/contactus'element={<Contactus/>}/>
				<Route path='/allprogram'element={<Allprogram/>}/>
				<Route path='/internship'element={<Internship/>}/>
				<Route path='/placement'element={<Placement/>}/>
				<Route path='/onlineprogram'element={<Onlineprogram/>}/>
				<Route path='/trendingCouses'element={<TrendingCourses/>}/>
		
				
				<Route path='/corejava'element={<Corejava/>}/>
				<Route path='/digitalmarketing'element={<DigitalMarketing/>}/>
				<Route path='/python'element={<DataSciencewithPython/>}/>
				<Route path='/mernstack'element={<MernStack/>}/>
				<Route path='/.net'element={<Net/>}/>
				<Route path='/ethicalhacking'element={<EhicalHacking/>}/>
				<Route path='/fullstack'element={<Fullstack/>}/>
				<Route path='/csharp'element={<Csharp/>}/>
				<Route path='/cprogramming'element={<Cprogramming/>}/>
				<Route path='/android'element={<Android/>}/>
				<Route path='/php'element={<Php/>}/>
				<Route path='/selenium'element={<Selenium/>}/>
				<Route path='/aws'element={<Aws/>}/>
				<Route path='/mysql'element={<MySql/>}/>
				<Route path='/mpython'element={<Python/>}/>
				<Route path='/aexcel'element={<Aexcel />}/>
				<Route path='/msoffice'element={<Msoffice /> }/>
				<Route path='/sap'element={<Sap/>}/>
				<Route path='/tally'element={<Tally/>}/>
				<Route path='/gallery1'element={<Gallery1 />}/>
				<Route path="/certii" element={<Certificate />} />

				<Route path="/login" element={<Login onLogin={handleLogin} />} />

				<Route path='/register'element={<Register1 />}/>
			
				<Route path="/dashboard" element={<ProtectedRoute element={<Dashboard />} isLoggedIn={isLoggedIn} />} />
				<Route path='/thankyou' element={<Thankyou />}/>
				
			</Routes>
			<Footer/>
			
		</Router>
	</div>
)
}

export default App;


