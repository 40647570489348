import React, { Fragment } from 'react'

const DataSciencewithPython = () => {
  return (
    <Fragment>
      {/* <div class="botam-navbar">
        <p style="margin-left: 15px; color: #fff">To avail the weekly free training offer <br />Sign up Now.</p>
        <a href="#corseform" class="active">To know course fee.</a>
        <a href="#corseform" class="active">Know More</a>
      </div> */}
      <div id="main-wrapper">
        <div class="site-wrapper-reveal">
          <div class="about-banner-wrap vision-space bg-img" style={{ backgroundColor: "darkturquoise", height: "600px" }}>
            <div class="container">
              <div class="row">
                <div class="col-lg-6 section-space--pt_100">
                  <h1 style={{ color: "#fff" }}>Data Science with Python</h1>
                  <p style={{ color: "#fff" }}>Data science with Python performs data analysis, data visualization, web scraping, and etc.</p>
                </div>
                <div class="col-lg-2">
                </div>
                <div class="col-lg-4 col-12">
                  <div class="form-container mx-auto mx-xl-0 ml-xl-auto border" id="corseform">
                    <div id="signup-form">
                      <form id="basic-form" name="yoursite_wp_mail_from_name" method="post" />
                      <input type="hidden" name="courseId" value="766" />
                      <input type="hidden" name="category" value="16" />
                      <div class="form-group">
                        <label>Name:</label>
                        <input type="text" id="fname" name="fname" class="form-control" placeholder="Enter Your Name" value="" required="" />
                      </div>
                      <div class="form-group">
                        <label>Email:</label>
                        <input type="email" id="email" name="email" class="form-control" placeholder="Enter Your Email Address" value="" required="" />
                      </div>
                      <div class="form-group">
                        <label>Mobile:</label>
                        <input type="number" id="phone" name="phone" class="form-control" placeholder="Enter Your mobile number" value="" required="" />
                      </div>
                      <div class="form-group mb-0">
                        <div class="form-row">
                          <div class="form-group col-6">
                            <label class="control-label">State</label>
                            <select id="states" name="states" class="form-control" required="">
                              <option value="">SELECT STATE</option>
                              <option value="2">Andhra Pradesh</option>
                              <option value="5">Bihar</option>
                              <option value="12">Gujarat</option>
                              <option value="13">Haryana</option>
                              <option value="14">Himachal Pradesh</option>
                              <option value="16">Jharkhand</option>
                              <option value="17">Karnataka</option>
                              <option value="21">Maharashtra</option>
                              <option value="28">Punjab</option>
                              <option value="29">Rajasthan</option>
                              <option value="31">Tamil Nadu</option>
                              <option value="32">Telangana</option>
                              <option value="35">Uttar Pradesh</option>
                            </select>
                          </div>
                          <div class="form-group col-6 city">
                            <label class="control-label">Center</label>
                            <select name="city" id="city" class="form-control center" required="">
                              <option value="">SELECT CENTRE</option>
                            </select>
                          </div>
                        </div>
                      </div>
                      <div class="form-group mb-0">
                        <button type="submit" id="btn" class="btn btn-primary contest_register_now" name="register_users">Apply Now</button>
                      </div>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb" >
          <li class="breadcrumb-item" >
            <a href="/" >Home</a>
          </li>
          <li class="breadcrumb-item active" aria-current="page">Python</li>
        </ol>
      </nav>




      <div class="vision-overview ">
        <div class="container">
          <div class="section-title-wrap text-center">
            <br/>
            <br/>

            <h3 class="heading"><span class="text-color-primary" style={{ fontSize: "21px" }}> Training Highlights</span></h3>
          </div>
          <div class="row">
            <div class="col-12 col-lg-4">
              <div class="item-container d-flex align-items-center">
                <div class="image-container">
                  <img src="https://itdeskindia.com/wp-content/uploads/2021/02/1.png" class="lazy-load" />
                </div>
                <div class="content-container">
                  <h3 class="title">Instructor Led Training</h3>
                  <p class="description"></p>
                </div>
              </div>
            </div>
            <div class="col-12 col-lg-4">
              <div class="item-container d-flex align-items-center">
                <div class="image-container">
                  <img src="https://itdeskindia.com/wp-content/uploads/2021/02/2.png" class="lazy-load" />
                </div>
                <div class="content-container">
                  <h3 class="title">Weekly Doubt clearing Session</h3>
                  <p class="description"></p>
                </div>
              </div>
            </div>
            <div class="col-12 col-lg-4">
              <div class="item-container d-flex align-items-center">
                <div class="image-container">
                  <img src="https://itdeskindia.com/wp-content/uploads/2021/02/offline-revision-min.png" class="lazy-load" />
                </div>
                <div class="content-container">
                  <h3 class="title">Lifetime Assistance for Revision</h3>
                  <p class="description"></p>
                </div>
              </div>
            </div>
            <div class="col-12 col-lg-4">
              <div class="item-container d-flex align-items-center">
                <div class="image-container">
                  <img src="https://itdeskindia.com/wp-content/uploads/2021/02/LOGIN.jpg" class="lazy-load" />
                </div>
                <div class="content-container">
                  <h3 class="title">Lifetime Login Access to Student Dashboard</h3>
                  <p class="description"></p>
                </div>
              </div>
            </div>
            <div class="col-12 col-lg-4">
              <div class="item-container d-flex align-items-center">
                <div class="image-container">
                  <img src="https://itdeskindia.com/wp-content/uploads/2021/02/certificate-icon-10.png" class="lazy-load" />
                </div>
                <div class="content-container">
                  <h3 class="title">World-Wide Valid Certification</h3>
                  <p class="description"></p>
                </div>
              </div>
            </div>
            <div class="col-12 col-lg-4">
              <div class="item-container d-flex align-items-center">
                <div class="image-container">
                  <img src="https://itdeskindia.com/wp-content/uploads/2021/02/books-min.png" class="lazy-load" />
                </div>
                <div class="content-container">
                  <h3 class="title">Study Material, Assignments and Projects</h3>
                  <p class="description"></p>
                </div>
              </div>
            </div>

          </div>
          <br /> <br />
          <div class="section-title-wrap text-center">
          <br/>
            <h3 class="heading"><span class="text-color-primary" style={{ fontSize: "21px" }}> Course Highlights</span></h3>
          </div>
          {/* <div class="row">
            <div class="d-flex flex-row flex-lg-column align-items-lg-center mx-auto">
              <div class="dashed-border">
                <img class="d-block d-lg-none" src="https://itdeskindia.com/wp-content/themes/itdesk_theme/assets/images/dotted_line_mobile.svg" />
                <img class="d-none d-lg-block" src="https://itdeskindia.com/wp-content/themes/itdesk_theme/assets/images/dotted_line_desktop.svg" />
              </div>
              <div class="d-flex flex-column flex-lg-row justify-content-lg-center">

                <div class="item-container d-flex flex-lg-column align-items-center">
                  <div class="number-container">
                    <div class="number-box">1</div>
                    <div class="arrow-container">
                      <div class="arrow arrow-left"></div>
                      <div class="arrow arrow-right"></div>
                    </div>
                  </div>
                  <div class="content-container">
                    <p class="activity">Numpy and Scipy</p>
                  </div>
                </div>
                <div class="item-container d-flex flex-lg-column align-items-center">
                  <div class="number-container">
                    <div class="number-box">2</div>
                    <div class="arrow-container">
                      <div class="arrow arrow-left"></div>
                      <div class="arrow arrow-right"></div>
                    </div>
                  </div>
                  <div class="content-container">
                    <p class="activity">Data Visualization with Matplotlib</p>
                  </div>
                </div>
                <div class="item-container d-flex flex-lg-column align-items-center">
                  <div class="number-container">
                    <div class="number-box">3</div>
                    <div class="arrow-container">
                      <div class="arrow arrow-left"></div>
                      <div class="arrow arrow-right"></div>
                    </div>
                  </div>
                  <div class="content-container">
                    <p class="activity">Supervised & Unsupervised Learning</p>
                  </div>
                </div>
                <div class="item-container d-flex flex-lg-column align-items-center">
                  <div class="number-container">
                    <div class="number-box">4</div>
                    <div class="arrow-container">
                      <div class="arrow arrow-left"></div>
                      <div class="arrow arrow-right"></div>
                    </div>
                  </div>
                  <div class="content-container">
                    <p class="activity">Spark</p>
                  </div>
                </div>

              </div>
            </div>
          </div> */}

          <div class="row">
            <div class="col-lg-12 col-12">
            <br/>
            <br/>
              <h3 class="heading" style={{ fontSize: "21px" }}>Course Overview</h3>
              <br/>
              <h3 class="heading"><span class="text-color-primary" style={{ fontSize: "21px" }}>Course Overview</span></h3>
              <div class="vision-content">
                <h2>Data Science with Python Course: Unlocking the Power of Data</h2>
                <p>Are you ready to embark on an exciting journey into the world of data science? Look no further than the Data Science with Python course at IT Desk India in Jaipur. This comprehensive training program is designed to equip you with the skills and knowledge needed to excel in the field of data science using Python. In this article, we will explore the course details, the benefits of learning data science with Python, and why IT Desk India is the perfect training institute for your educational needs.</p>
                <p><strong>Table of Contents</strong></p>
                <ul style={{fontSize:'15px', fontfamily: 'Open Sans sans-serif'}}>
                  <li>Introduction to Data Science with Python Course</li>
                  <li>The Importance of Data Science in Today&#8217;s World</li>
                  <li>Benefits of Data Science with Python Course Training</li>
                  <li>Course Curriculum and Training Approach</li>
                  <li>Industry-Relevant Projects and Case Studies</li>
                  <li>Experienced Faculty and Mentors</li>
                  <li>Hands-on Practical Sessions</li>
                  <li>Flexible Learning Options</li>
                  <li>Certification Program and Recognition</li>
                  <li>Placement Assistance and Career Support</li>
                  <li>Data Science with Python Course Training Institute</li>
                  <li>Data Science with Python Course Fees &amp; Class</li>
                  <li>IT Desk India: A Trusted Training Institute</li>
                  <li>FAQs</li>
                </ul>
                <br/>
                <br/>
                <h2><strong>Introduction to Data Science with Python Course</strong></h2>
                <p>Data Science is a multidisciplinary field that combines various techniques, algorithms, and tools to extract insights and knowledge from structured and unstructured data. Python, a versatile programming language, has gained immense popularity among data scientists due to its simplicity, extensive libraries, and powerful analytical capabilities. The Data Science with Python course in Jaipur offered by IT Desk India provides a comprehensive introduction to the fundamental concepts and practical applications of data science using Python.</p>
                <br/>
                <p><strong>The Importance of Data Science in Today&#8217;s World</strong></p>
                <p>In today&#8217;s data-driven era, organizations across industries are leveraging data science to drive informed decision-making, optimize processes, and gain a competitive edge. The ability to analyze and interpret large volumes of data has become crucial for businesses to identify patterns, trends, and valuable insights. By acquiring data science skills with Python, you will position yourself at the forefront of this digital transformation and unlock numerous career opportunities.</p>
                <br/>
                <h2><strong>Benefits of Data Science with Python Course Training</strong></h2>
                <p>Learning data science with Python Course Training offers several advantages for aspiring data scientists:</p>
                <p><strong>Versatility:</strong> Python provides a wide range of libraries and tools specifically designed for data analysis, machine learning, and visualization. Its versatility enables data scientists to solve complex problems efficiently.</p>

                <br/>
                <p><strong>Industry Demand:</strong> Python is one of the most sought-after programming languages in the data science field. Proficiency in Python opens doors to a plethora of job opportunities with attractive salaries.</p>
                <br/>
                <p><strong>Ease of Use:</strong> Python&#8217;s clean and intuitive syntax makes it easy to learn and understand, even for beginners. Its simplicity allows data scientists to focus more on solving problems rather than grappling with complex code.</p>
                <p><strong>Vibrant Community:</strong> Python boasts a vibrant and active community of developers and data scientists. This community provides excellent support, resources, and opportunities for collaboration.</p>
                <br/>
                <p><strong>Course Curriculum and Data Science Course Training Approach</strong></p>
                <p>The Data Science with Python course at <strong>IT Desk India</strong> covers a comprehensive curriculum that encompasses both theoretical concepts and hands-on practical exercises. The Data Science course Training is designed to provide a solid foundation in data science principles, Python programming, data manipulation, exploratory data analysis, machine learning algorithms, and data visualization techniques. Through a practical-oriented approach, you will gain the necessary skills to tackle real-world data science challenges.</p>
                <br/>
                <p><strong>Industry-Relevant Projects and Case Studies</strong></p>
                <p>To enhance your learning experience, the course incorporates industry-relevant projects and case studies. These real-world scenarios enable you to apply your knowledge and skills to solve practical problems encountered in various domains, such as finance, healthcare, marketing, and social media analysis. By working on these projects, you will build a robust portfolio that showcases your proficiency in data science with Python.</p>
                <br/>
                <p><strong>Experienced Faculty and Mentors</strong></p>
                <p>At IT Desk India, you will learn from experienced faculty members and industry experts who possess extensive knowledge and practical experience in data science. They will guide you throughout the course, providing valuable insights, mentoring, and personalized attention to ensure your success in mastering data science with Python.</p>
                <br/>
                <p><strong>Hands-on Practical Sessions</strong></p>
                <p>The course emphasizes hands-on practical sessions to reinforce your learning and enhance your problem-solving skills. Through interactive coding exercises, data analysis tasks, and machine learning projects, you will gain proficiency in applying Python libraries, tools, and techniques to real-world datasets. These practical sessions will empower you to confidently undertake data science projects in your future endeavors.</p>
                <br/>
                <p><strong>Flexible Learning Options</strong></p>
                <p>IT Desk India understands the diverse needs of learners. To accommodate different schedules and preferences, they offer flexible learning options. You can choose between classroom-based training, live online sessions, or a blended learning approach. This flexibility ensures that you can pursue the course at your own pace, balancing your professional and personal commitments.</p>
                <br/>
                <h2><strong>Data Science Certification Program and Recognition</strong></h2>
                <p>Upon successful completion of the Data Science with <strong>Python course Certification Program</strong>, you will receive a prestigious certification from IT Desk India. This industry-recognized certification demonstrates your expertise in data science and Python programming, enhancing your credibility and employability in the job market.</p>
                <h2><strong>Placement Assistance and Career Support</strong></h2>
                <p>IT Desk India is committed to your career success. They provide dedicated placement assistance and career support to help you secure lucrative job opportunities. Their extensive network of industry connections, interview preparation guidance, and resume-building workshops will empower you to kickstart your career as a data scientist.</p>
                <h2><strong>Data Science with Python Course Training Institute</strong></h2>
                <p>Welcome to our data science training institute in Jaipur, where we specialize in providing comprehensive courses in data science using Python. Our institute is dedicated to equipping aspiring data scientists and professionals with the skills and knowledge needed to excel in this rapidly growing field.</p>
                <br/>
                <p>Our Python-based <strong>Data Science Courses in Jaipur</strong> are designed to cater to individuals at all skill levels, from beginners with no prior programming experience to experienced programmers looking to transition into data science. Our expert instructors bring years of industry experience and a deep understanding of Python and data science concepts, ensuring that our students receive top-notch training.</p>
                <h2><strong>Data Science with Python Course Fees &amp; Class</strong></h2>
                <p>IT Desk India offers competitive course fees for Data Science with Python programs in Jaipur. They understand the financial constraints that students may face and provide scholarships and installment options to make the course accessible to a wider audience. Contact their admissions team to learn more about the fee structure and available scholarships. An IT Desk India offers a Data Science with Python Course class Online &amp; Offline by experts.</p>
                <h2><strong>IT Desk India: A Trusted Training Institute</strong></h2>
                <p>With a proven track record of delivering high-quality education and empowering thousands of students, IT Desk India stands as a trusted training institute for data science and Python courses. Their commitment to excellence, industry-relevant curriculum, and student-centric approach makes them the ideal choice for your data science journey.</p>
                <br/>
                <p><strong>IT DESK</strong> provides professional software training on a vast array of courses in the fields of <strong>Computer Science and Information Technology</strong>. Since its founding in 2016, <strong>IT DESK</strong> has provided training to over 10 Thousands+ students and professionals from various countries.</p>
                <p>Since its inception, in a very short span of time, with the qualitative services of training and well-designed training modules, <strong>IT DESK</strong> has expanded its network and become the topmost leading franchise network. <em><strong>IT DESK proudly owns 35+ franchise centers in various parts of the country</strong></em><em>.</em> Furthermore, we are now expanding our network center in various countries in the world.</p>
                <p>Embarking on a Data Science with Python course at IT Desk India in Jaipur can be a transformative step toward a rewarding career in the field of data science. By mastering Python&#8217;s data analysis and machine learning capabilities, you will possess the skills to derive meaningful insights from complex datasets and make data-driven decisions. Take the leap and unlock the power of data with the Data Science with Python course at IT Desk India.</p>
                <br/>
                <br/>
              </div>



            </div>
          </div>
        </div>
      </div>
    

      {/* <div class="accordion-wrapper">
        <div class="container">
          <h3 class="heading"><span class="text-color-primary" style={{ fontSize: "21px" }}>Course Contents</span></h3>
          <p>The studio was keen to create an identity that would not interfere with any of the existing brands, settling on a helicoid system that can be easily adapted to “work with any colour system”. </p>
          <div class="row ">
            <div class="col-lg-8">
              <div class="faq-two-wrapper section-space--mt_40">
                <div id="accordion_two">
                  <div class="card">
                    <div class="card-header" id="heading__110">
                      <h5 class="mb-0">
                        <button class="btn-link collapsed" data-toggle="collapse" data-target="#collapse__110" aria-expanded="false" aria-controls="collapse__110">
                          Introduction to Data Science using Python<span>
                            <i class="far fa-caret-circle-down"></i>
                            <i class="far fa-caret-circle-right"></i>  </span>
                        </button>
                      </h5>
                    </div>
                    <div id="collapse__110" class="collapse" aria-labelledby="heading__110" data-parent="#accordion_two">
                      <div class="card-body">
                        <p><ul>
                          <li>What is Data Science, what does a data scientist do</li>
                          <li>Various examples of Data Science in the industries</li>
                          <li>How Python is deployed for Data Science applications</li>
                          <li>Various steps in Data Science process like data wrangling, data exploration and selecting the model</li>
                          <li>Introduction to Python programming language</li>
                          <li>Important Python features, how is Python different from other programming languages</li>
                          <li>Python installation, Anaconda Python distribution for Windows, Linux and Mac</li>
                          <li>How to run a sample Python script, Python IDE working mechanism?</li>
                          <li>Running some Python basic commands</li>
                          <li>Python variables, data types and keywords.</li>
                        </ul>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="card">
                    <div class="card-header" id="heading__111">
                      <h5 class="mb-0">
                        <button class="btn-link collapsed" data-toggle="collapse" data-target="#collapse__111" aria-expanded="false" aria-controls="collapse__111">
                          Python Basics Constructs<span>
                            <i class="far fa-caret-circle-down"></i>
                            <i class="far fa-caret-circle-right"></i>  </span>
                        </button>
                      </h5>
                    </div>
                    <div id="collapse__111" class="collapse" aria-labelledby="heading__111" data-parent="#accordion_two">
                      <div class="card-body">
                        <p><ul>
                          <li>Introduction to a basic construct in Python</li>
                          <li>Understanding indentation like tabs and spaces</li>
                          <li>Python built-in data types</li>
                          <li>Basic operators in Python</li>
                          <li>Loop and control statements like break, if, for, continue, else, range() and more.</li>
                        </ul>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="card">
                    <div class="card-header" id="heading__112">
                      <h5 class="mb-0">
                        <button class="btn-link collapsed" data-toggle="collapse" data-target="#collapse__112" aria-expanded="false" aria-controls="collapse__112">
                          Maths for DS-Statistics & Probability <span>
                            <i class="far fa-caret-circle-down"></i>
                            <i class="far fa-caret-circle-right"></i>  </span>
                        </button>
                      </h5>
                    </div>
                    <div id="collapse__112" class="collapse" aria-labelledby="heading__112" data-parent="#accordion_two">
                      <div class="card-body">
                        <p><ul>
                          <li>Central Tendency</li>
                          <li>Variabiltiy</li>
                          <li>Hypothesis Testing</li>
                          <li>Anova</li>
                          <li>Correlation</li>
                          <li>Regression</li>
                          <li>Probability Definitions and Notation</li>
                          <li>Joint Probabilities</li>
                          <li>The Sum Rule, Conditional Probability, and the Product Rule</li>
                          <li>Baye’s Theorem</li>
                        </ul>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="card">
                    <div class="card-header" id="heading__113">
                      <h5 class="mb-0">
                        <button class="btn-link collapsed" data-toggle="collapse" data-target="#collapse__113" aria-expanded="false" aria-controls="collapse__113">
                          OOPs in Python <span>
                            <i class="far fa-caret-circle-down"></i>
                            <i class="far fa-caret-circle-right"></i>  </span>
                        </button>
                      </h5>
                    </div>
                    <div id="collapse__113" class="collapse" aria-labelledby="heading__113" data-parent="#accordion_two">
                      <div class="card-body">
                        <p><ul>
                          <li>Understanding the OOP paradigm like encapsulation, inheritance, polymorphism and abstraction</li>
                          <li>What are access modifiers, instances, class members</li>
                          <li>Classes and objects</li>
                          <li>Function parameter and return type functions</li>
                          <li>Lambda expressions.</li>
                        </ul>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="card">
                    <div class="card-header" id="heading__114">
                      <h5 class="mb-0">
                        <button class="btn-link collapsed" data-toggle="collapse" data-target="#collapse__114" aria-expanded="false" aria-controls="collapse__114">
                          NumPy for mathematical Calculations<span>
                            <i class="far fa-caret-circle-down"></i>
                            <i class="far fa-caret-circle-right"></i>  </span>
                        </button>
                      </h5>
                    </div>
                    <div id="collapse__114" class="collapse" aria-labelledby="heading__114" data-parent="#accordion_two">
                      <div class="card-body">
                        <p><ul>
                          <li>Introduction to mathematical computing in Python</li>
                          <li>What are arrays and matrices, array indexing, array math, Inspecting a numpy array, Numpy array manipulation</li>
                        </ul>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="card">
                    <div class="card-header" id="heading__115">
                      <h5 class="mb-0">
                        <button class="btn-link collapsed" data-toggle="collapse" data-target="#collapse__115" aria-expanded="false" aria-controls="collapse__115">
                          Scipy for mathematical Computing<span>
                            <i class="far fa-caret-circle-down"></i>
                            <i class="far fa-caret-circle-right"></i>  </span>
                        </button>
                      </h5>
                    </div>
                    <div id="collapse__115" class="collapse" aria-labelledby="heading__115" data-parent="#accordion_two">
                      <div class="card-body">
                        <p><ul>
                          <li>Introduction to scipy, building on top of numpy</li>
                          <li>What are the characteristics of scipy</li>
                          <li>Various subpackages for scipy like Signal, Integrate, Fftpack, Cluster, Optimize, Stats and more, Bayes Theorem with scipy.</li>
                        </ul>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="card">
                    <div class="card-header" id="heading__116">
                      <h5 class="mb-0">
                        <button class="btn-link collapsed" data-toggle="collapse" data-target="#collapse__116" aria-expanded="false" aria-controls="collapse__116">
                          Data Manipulation<span>
                            <i class="far fa-caret-circle-down"></i>
                            <i class="far fa-caret-circle-right"></i>  </span>
                        </button>
                      </h5>
                    </div>
                    <div id="collapse__116" class="collapse" aria-labelledby="heading__116" data-parent="#accordion_two">
                      <div class="card-body">
                        <p><ul>
                          <li>What is a data Manipulation. Using Pandas library</li>
                          <li>Numpy dependency of Pandas library</li>
                          <li>Series object in pandas</li>
                          <li>Dataframe in Pandas</li>
                          <li>Loading and handling data with Pandas</li>
                          <li>How to merge data objects</li>
                          <li>Concatenation and various types of joins on data objects, exploring dataset</li>
                        </ul>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="card">
                    <div class="card-header" id="heading__117">
                      <h5 class="mb-0">
                        <button class="btn-link collapsed" data-toggle="collapse" data-target="#collapse__117" aria-expanded="false" aria-controls="collapse__117">
                          Data Visualization with Matplotlib<span>
                            <i class="far fa-caret-circle-down"></i>
                            <i class="far fa-caret-circle-right"></i>  </span>
                        </button>
                      </h5>
                    </div>
                    <div id="collapse__117" class="collapse" aria-labelledby="heading__117" data-parent="#accordion_two">
                      <div class="card-body">
                        <p><ul>
                          <li>Introduction to Matplotlib</li>
                          <li>Using Matplotlib for plotting graphs and charts like Scatter, Bar, Pie, Line, Histogram and more</li>
                          <li>Matplotlib API</li>
                        </ul>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="card">
                    <div class="card-header" id="heading__118">
                      <h5 class="mb-0">
                        <button class="btn-link collapsed" data-toggle="collapse" data-target="#collapse__118" aria-expanded="false" aria-controls="collapse__118">
                          Machine Learning with Python<span>
                            <i class="far fa-caret-circle-down"></i>
                            <i class="far fa-caret-circle-right"></i>  </span>
                        </button>
                      </h5>
                    </div>
                    <div id="collapse__118" class="collapse" aria-labelledby="heading__118" data-parent="#accordion_two">
                      <div class="card-body">
                        <p><ul>
                          <li>Revision of topics in Python (Pandas, Matplotlib, numpy, scikit-Learn)</li>
                          <li>Introduction to machine learning</li>
                          <li>Need of Machine learning</li>
                          <li>Types of machine learning and workflow of Machine Learning</li>
                          <li>Uses Cases in Machine Learning, its various arlogrithms</li>
                          <li>What is supervised learning</li>
                          <li>What is Unsupervised Learning</li>
                        </ul>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="card">
                    <div class="card-header" id="heading__119">
                      <h5 class="mb-0">
                        <button class="btn-link collapsed" data-toggle="collapse" data-target="#collapse__119" aria-expanded="false" aria-controls="collapse__119">
                          Supervised Learning <span>
                            <i class="far fa-caret-circle-down"></i>
                            <i class="far fa-caret-circle-right"></i>  </span>
                        </button>
                      </h5>
                    </div>
                    <div id="collapse__119" class="collapse" aria-labelledby="heading__119" data-parent="#accordion_two">
                      <div class="card-body">
                        <p><ul>
                          <li>What is linear regression?</li>
                          <li>Step by step calculation of Linear Regression</li>
                          <li>Linear regression in Python</li>
                          <li>Logistic Regression</li>
                          <li>What is classification</li>
                          <li>Decision Tree, Confusion Matrix, Random Forest, Naïve Bayes classifier (Self-paced), Support Vector Machine(self-paced), xgboost (self-paced)</li>
                        </ul>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="card">
                    <div class="card-header" id="heading__1110">
                      <h5 class="mb-0">
                        <button class="btn-link collapsed" data-toggle="collapse" data-target="#collapse__1110" aria-expanded="false" aria-controls="collapse__1110">
                          Unsupervised Learning<span>
                            <i class="far fa-caret-circle-down"></i>
                            <i class="far fa-caret-circle-right"></i>  </span>
                        </button>
                      </h5>
                    </div>
                    <div id="collapse__1110" class="collapse" aria-labelledby="heading__1110" data-parent="#accordion_two">
                      <div class="card-body">
                        <p><ul>
                          <li>Introduction to unsupervised learning</li>
                          <li>Use cases of unsupervised learning</li>
                          <li>What is clustering</li>
                          <li>Types of clustering(self-paced)-Exclusive clustering, Overlapping Clustering, Hierarchical Clustering(self-paced)</li>
                          <li>What is K-means clustering</li>
                          <li>Step by step calculation of k-means algorithm</li>
                          <li>Association Rule Mining(self-paced), Market Basket Analysis(self-paced), Measures in association rule mining(self-paced)-support, confidence, lift</li>
                          <li>Apriori Algorithm</li>
                        </ul>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="card">
                    <div class="card-header" id="heading__1111">
                      <h5 class="mb-0">
                        <button class="btn-link collapsed" data-toggle="collapse" data-target="#collapse__1111" aria-expanded="false" aria-controls="collapse__1111">
                          Python Integration with Spark<span>
                            <i class="far fa-caret-circle-down"></i>
                            <i class="far fa-caret-circle-right"></i>  </span>
                        </button>
                      </h5>
                    </div>
                    <div id="collapse__1111" class="collapse" aria-labelledby="heading__1111" data-parent="#accordion_two">
                      <div class="card-body">
                        <p><ul>
                          <li>Introduction to pyspark</li>
                          <li>Who uses pyspark, need of spark with python</li>
                          <li>Pyspark installation</li>
                          <li>Pyspark fundamentals</li>
                          <li>Advantage over mapreduce, pyspark</li>
                          <li>Use-cases pyspark and demo</li>
                        </ul>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="card">
                    <div class="card-header" id="heading__1112">
                      <h5 class="mb-0">
                        <button class="btn-link collapsed" data-toggle="collapse" data-target="#collapse__1112" aria-expanded="false" aria-controls="collapse__1112">
                          Dimensionality Reduction<span>
                            <i class="far fa-caret-circle-down"></i>
                            <i class="far fa-caret-circle-right"></i>  </span>
                        </button>
                      </h5>
                    </div>
                    <div id="collapse__1112" class="collapse" aria-labelledby="heading__1112" data-parent="#accordion_two">
                      <div class="card-body">
                        <p><ul>
                          <li>Introduction to Dimensionality</li>
                          <li>Why Dimensionality Reduction</li>
                          <li>PCA</li>
                          <li>Factor Analysis</li>
                          <li>LDA</li>
                        </ul>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="card">
                    <div class="card-header" id="heading__1113">
                      <h5 class="mb-0">
                        <button class="btn-link collapsed" data-toggle="collapse" data-target="#collapse__1113" aria-expanded="false" aria-controls="collapse__1113">
                          Time Series Forecasting <span>
                            <i class="far fa-caret-circle-down"></i>
                            <i class="far fa-caret-circle-right"></i>  </span>
                        </button>
                      </h5>
                    </div>
                    <div id="collapse__1113" class="collapse" aria-labelledby="heading__1113" data-parent="#accordion_two">
                      <div class="card-body">
                        <p><ul>
                          <li>White Noise</li>
                          <li>AR model</li>
                          <li>MA model</li>
                          <li>ARMA model</li>
                          <li>ARIMA model</li>
                          <li>Stationarity</li>
                          <li>ACF &amp; PACF</li>
                        </ul>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-4">

              <div class="contact-info style-two text-left">
                <div class="contact-list-item">
                  <a href="tel:7378123128" class="single-contact-list">
                    <div class="content-wrap">
                      <div class="content">
                        <div class="icon">
                          <span class="fal fa-phone"></span>
                        </div>
                        <div class="main-content">
                          <h6 class="heading">Call for advice now!</h6>
                          <div class="text" style={{ fontSize: "21px" }}>9901371386</div>
                        </div>
                      </div>
                    </div>
                  </a>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div> */}


      <div class="contact-us-section-wrappaer ">
        <div class="container">
          <div class="row align-items-center">
            <div class="col-lg-7 col-lg-7">
              <div class="conact-us-wrap-one">
                <h5 class="heading mb-20">How will you get your certificate?</h5>
                <div class="center_details">
                  {/* <h5 class="heading mb-20">CERTIFICATE FAQ</h5>  */}
                  <p style={{ textAlign: " justify" }}>IT DESK is a pioneer in CS/IT training and most of the industries give great recognition to its certificate throughout India and abroad. IT DESK Certificates are ISO verified which makes it highly reputed comparing to the other local brands and helps the student get prioritize while going for job opportunities in the industry. IT DESK Software Training is conducted by highly knowledgeable trainers which help students to enhance their skill set by putting great emphasizes on project-based learning.</p>
                  {/* <a class="btn btn-primary">Get Certificate</a>  */}
                </div>
              </div>
            </div>
            <div class="col-lg-5 col-lg-5">
              <div class="contact-form-wrap">
                {/* <h5 class="heading mb-20">How will you get your certificate?</h5> */}
                <img src="assests/images/cert11.jpg" style={{ width: "100%", height: "370px" }} />
              </div>
            </div>
          </div>
        </div>
      </div>

    </Fragment>
  )
}

export default DataSciencewithPython
