import React, { Fragment } from 'react'

const EhicalHacking = () => {
  return (
    <Fragment>
       <div id="main-wrapper">
        <div class="site-wrapper-reveal">
          <div class="about-banner-wrap vision-space bg-img" style={{ backgroundColor: "darkturquoise", height: "600px" }}>
            <div class="container">
              <div class="row">
                <div class="col-lg-6 section-space--pt_100">
                  <h1 style={{ color: "#fff" }}> Ehical Hacking</h1>
                  <p style={{ color: "#fff" }}>A most powerful tool to grow business and increase reach worldwide.</p>
                </div>
                <div class="col-lg-2">
                </div>
                <div class="col-lg-4">
                  <div class="form-container mx-auto mx-xl-0 ml-xl-auto border" id="corseform">
                    <div id="signup-form">
                      <p style={{ color: "green" }}>Your Enquiry Successfully Submit</p>
                      <form id="basic-form" name="yoursite_wp_mail_from_name" method="post" />
                      <input type="hidden" name="courseId" value="753" />
                      <input type="hidden" name="category" value="16" />
                      <div class="form-group">
                        <label>Name:</label>
                        <input type="text" id="fname" name="fname" class="form-control" placeholder="Enter Your Name" value="" required="" />
                      </div>
                      <div class="form-group">
                        <label>Email:</label>
                        <input type="email" id="email" name="email" class="form-control" placeholder="Enter Your Email Address" value="" required="" />
                      </div>
                      <div class="form-group">
                        <label>Mobile:</label>
                        <input type="number" id="phone" name="phone" class="form-control" placeholder="Enter Your mobile number" value="" required="" />
                      </div>
                      <div class="form-group mb-0">
                        <div class="form-row">
                          <div class="form-group col-6">
                            <label class="control-label">State</label>
                            <select id="states" name="states" class="form-control" required="">
                              <option value="">SELECT STATE</option>
                              <option value="2">Andhra Pradesh</option>
                              <option value="5">Bihar</option>
                              <option value="12">Gujarat</option>
                              <option value="13">Haryana</option>
                              <option value="14">Himachal Pradesh</option>
                              <option value="16">Jharkhand</option>
                              <option value="17">Karnataka</option>
                              <option value="21">Maharashtra</option>
                              <option value="28">Punjab</option>
                              <option value="29">Rajasthan</option>
                              <option value="31">Tamil Nadu</option>
                              <option value="32">Telangana</option>
                              <option value="35">Uttar Pradesh</option>
                            </select>
                          </div>
                          <div class="form-group col-6 city">
                            <label class="control-label">Center</label>
                            <select name="city" id="city" class="form-control center" required="">
                              <option value="">SELECT CENTRE</option>
                            </select>
                          </div>
                        </div>
                      </div>
                      <div class="form-group mb-0">
                        <button type="submit" id="btn" class="btn btn-primary contest_register_now" name="register_users">Apply Now</button>
                      </div>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb" >f
          <li class="breadcrumb-item" >
            <a href="/" >Home</a>
          </li>
          <li class="breadcrumb-item active" aria-current="page">Ethical Hacking</li>
        </ol>
      </nav>



      <div class="vision-overview ">
        <div class="container">
          <div class="section-title-wrap text-center">
            <br />
            <br />
            <h3 class="heading"><span class="text-color-primary" style={{ fontSize: "21px" }}> Training Highlights</span></h3>
          </div>
          <div class="row">
            <div class="col-12 col-lg-4">
              <div class="item-container d-flex align-items-center">
                <div class="image-container">
                  <img src="https://itdeskindia.com/wp-content/uploads/2021/02/1.png" class="lazy-load" />
                </div>
                <div class="content-container">
                  <h3 class="title">Instructor Led Training</h3>
                  <p class="description"></p>
                </div>
              </div>
            </div>
            <div class="col-12 col-lg-4">
              <div class="item-container d-flex align-items-center">
                <div class="image-container">
                  <img src="https://itdeskindia.com/wp-content/uploads/2021/02/2.png" class="lazy-load" />
                </div>
                <div class="content-container">
                  <h3 class="title">Weekly Doubt clearing Session</h3>
                  <p class="description"></p>
                </div>
              </div>
            </div>
            <div class="col-12 col-lg-4">
              <div class="item-container d-flex align-items-center">
                <div class="image-container">
                  <img src="https://itdeskindia.com/wp-content/uploads/2021/02/offline-revision-min.png" class="lazy-load" />
                </div>
                <div class="content-container">
                  <h3 class="title">Lifetime Assistance for Revision</h3>
                  <p class="description"></p>
                </div>
              </div>
            </div>
            <div class="col-12 col-lg-4">
              <div class="item-container d-flex align-items-center">
                <div class="image-container">
                  <img src="https://itdeskindia.com/wp-content/uploads/2021/02/LOGIN.jpg" class="lazy-load" />
                </div>
                <div class="content-container">
                  <h3 class="title">Lifetime Login Access to Student Dashboard</h3>
                  <p class="description"></p>
                </div>
              </div>
            </div>
            <div class="col-12 col-lg-4">
              <div class="item-container d-flex align-items-center">
                <div class="image-container">
                  <img src="https://itdeskindia.com/wp-content/uploads/2021/02/certificate-icon-10.png" class="lazy-load" />
                </div>
                <div class="content-container">
                  <h3 class="title">World-Wide Valid Certification</h3>
                  <p class="description"></p>
                </div>
              </div>
            </div>
            <div class="col-12 col-lg-4">
              <div class="item-container d-flex align-items-center">
                <div class="image-container">
                  <img src="https://itdeskindia.com/wp-content/uploads/2021/02/books-min.png" class="lazy-load" />
                </div>
                <div class="content-container">
                  <h3 class="title">Study Material, Assignments and Projects</h3>
                  <p class="description"></p>
                </div>
              </div>
            </div>

          </div>
          <br /> <br />
          {/* <div class="section-title-wrap text-center">
            <h3 class="heading"><span class="text-color-primary" style={{ fontSize: "21px" }}> Course Highlights</span></h3>
          </div> */}
          {/* <div class="row">
            <div class="d-flex flex-row flex-lg-column align-items-lg-center mx-auto">
              <div class="dashed-border">
                <img class="d-block d-lg-none" src="https://itdeskindia.com/wp-content/themes/itdesk_theme/assets/images/dotted_line_mobile.svg" />
                <img class="d-none d-lg-block" src="https://itdeskindia.com/wp-content/themes/itdesk_theme/assets/images/dotted_line_desktop.svg" />
              </div>
              <div class="d-flex flex-column flex-lg-row justify-content-lg-center">

                <div class="item-container d-flex flex-lg-column align-items-center">
                  <div class="number-container">
                    <div class="number-box">1</div>
                    <div class="arrow-container">
                      <div class="arrow arrow-left"></div>
                      <div class="arrow arrow-right"></div>
                    </div>
                  </div>
                  <div class="content-container">
                    <p class="activity">SEO</p>
                  </div>
                </div>
                <div class="item-container d-flex flex-lg-column align-items-center">
                  <div class="number-container">
                    <div class="number-box">2</div>
                    <div class="arrow-container">
                      <div class="arrow arrow-left"></div>
                      <div class="arrow arrow-right"></div>
                    </div>
                  </div>
                  <div class="content-container">
                    <p class="activity">SMM</p>
                  </div>
                </div>
                <div class="item-container d-flex flex-lg-column align-items-center">
                  <div class="number-container">
                    <div class="number-box">3</div>
                    <div class="arrow-container">
                      <div class="arrow arrow-left"></div>
                      <div class="arrow arrow-right"></div>
                    </div>
                  </div>
                  <div class="content-container">
                    <p class="activity">Content Marketing</p>
                  </div>
                </div>
                <div class="item-container d-flex flex-lg-column align-items-center">
                  <div class="number-container">
                    <div class="number-box">4</div>
                    <div class="arrow-container">
                      <div class="arrow arrow-left"></div>
                      <div class="arrow arrow-right"></div>
                    </div>
                  </div>
                  <div class="content-container">
                    <p class="activity">SEM</p>
                  </div>
                </div>

              </div>
            </div>
          </div> */}

          <div class="row">
            <div class="col-lg-12 col-12">
              <br />
              <br />
              {/* <!-- <h3 class="heading" style="font-size: 21px;">Course Overview</h3> --> */}
              <h3 class="heading"><span class="text-color-primary" style={{ fontSize: "21px" }}>Course Overview</span></h3>
              <br />
              <br />
              <div class="vision-content">
                <h2><strong>Ethical HackingCourse: A Gateway to Future Success</strong></h2>
                <br />
                <p><strong>Table of Contents</strong></p>
                <ol style={{fontSize:'15px', fontfamily: 'Open Sans sans-serif'}}>
                  <br />
                  <li>Introduction</li>
                  <li>What is Ethical Hacking?</li>
                  <li>The Importance of Ethical Hacking</li>
                  <li>Advantages of Ethical Hacking</li>
                </ol>
                <p>4.1. Reach a Global Audience</p>
                <p>4.2. Cost-Effective Marketing</p>
                <p>4.3. Targeted Advertising</p>
                <p>4.4. Real-Time Data Analysis</p>
                <br />
                <p><strong>Ethical Hacking Courses and Training </strong></p>
                <p>5.1. Finding the Right Institute</p>
                <p>5.2. Curriculum and Course Structure</p>
                <p>5.3. Hands-On Learning Experience</p>
                <p>5.4. Industry Experts as Trainers</p>
                <br />
                <p><strong>IT Desk: Empowering Ethical Hacking Aspirants </strong></p>
                <p>6.1. Diverse Ethical Hacking Programs</p>
                <p>6.2. Industry-Aligned Certifications</p>
                <p>6.3. Building a Strong Digital Marketing Network</p>
                <br />
                <p><strong>Understanding Ethical Hacking Fees </strong></p>
                <p>7.1. Transparent Fee Structure</p>
                <p>7.2. Value for Money</p>
                <br />
                <p><strong>The Significance of Digital Marketing Certification </strong></p>
                <p>8.1. Adding Credibility to Your Resume</p>
                <p>8.2. Staying Ahead in the Competitive Job Market</p>
                <br />
                <p><strong>Digital Marketing Companies: </strong></p>
                <p>Shaping Business Growth</p>
                <p>9.1. Tailored Ethical Hacking Strategies</p>
                <p>9.2. Measurable Results and ROI</p>
                <p>9.3. Effective Brand Building</p>
                <br />
                <p><strong>The Future of Ethical Hacking </strong></p>
                <p>10.1. Artificial Intelligence in Marketing</p>
                <p>10.2. Personalization and Customer Experience</p>
                <p>10.3. Video Marketing Dominance</p>
                <p>10.4. Voice Search and Interactive Content</p>
                <br />
                <h2><strong>Introduction</strong></h2>
                <p>In today&#8217;s fast-paced world, where everything is turning digital, marketing strategies are no exception. Traditional marketing methods are gradually being replaced by more efficient and result-oriented techniques. Digital marketing has emerged as a powerful tool to connect businesses with their target audience in a more personalized and engaging manner. In this article, we will delve into the world of digital marketing, exploring its significance, benefits, training options, and future prospects.</p>
                <br />
                <br />

                <h2><strong>What is Ethical Hacking?</strong></h2>
                <p>Digital marketing encompasses all online marketing efforts to promote products or services using digital channels. These channels include search engines, social media platforms, email marketing, content marketing, and more. The primary objective of digital marketing is to engage potential customers, build brand awareness, generate leads, and ultimately drive conversions.</p>
                <br />
                <h2><strong>The Importance of Ethical Hacking Course</strong></h2>
                <p>In today&#8217;s highly competitive business landscape, digital marketing has become crucial for companies of all sizes. It enables businesses to reach their target audience where they spend a significant amount of time &#8211; the internet. By using various digital platforms, businesses can establish a strong online presence and connect with their customers in real time.</p>
                <br />
                <h2><strong>Advantages of Ethical Hacking Course</strong></h2>
                <p><strong>4.1. Reach a Global Audience</strong></p>

                <p>Unlike traditional marketing, Ethical Hackingbreaks down geographical barriers, allowing businesses to target a global audience easily. This accessibility expands their customer base and opens up new growth opportunities.</p>
                <br />
                <p><strong>4.2. Cost-Ethical Hacking</strong></p>
                <p>Digital marketing offers a cost-effective approach compared to traditional advertising methods. Start-ups and small businesses can utilize digital channels to reach a wider audience within their budget constraints.</p>
                <br />
                <p><strong>4.3. Targeted Advertising</strong></p>
                <p>With digital marketing, businesses can precisely target their advertisements to a specific audience based on demographics, interests, behavior, and more. This ensures that marketing efforts are focused on the right people, increasing the chances of conversion.</p>
                <br />
                <p><strong>4.4. Real-Time Data Analysis</strong></p>
                <p>One of the significant advantages of digital marketing is the ability to analyze real-time data. Marketers can track the performance of their campaigns, measure success metrics, and make necessary adjustments promptly to improve overall performance.</p>
                <br />
                <h2><strong>Digital Marketing Courses and Training in Jaipur</strong></h2>
                <p>For individuals aspiring to pursue a career in digital marketing, it is essential to undergo professional training to acquire the necessary skills and knowledge. IT Desk is the right Training Institute for Digital Marketing Courses<strong><em>. </em></strong><em><strong>IT DESK</strong></em><em> Training Course in Jaipur gives you an overview of Digital Marketing and a briefing about different digital marketing strategies which help in escalating the business for your organization or client.</em></p>
                <br />
                <h2><strong>Finding the Right Digital Marketing Institute in Jaipur</strong></h2>
                <p>When selecting a digital marketing institute in Jaipur, consider factors like reputation, faculty expertise, alumni reviews, and industry relevance. <strong>IT DESK</strong> provides professional software training on a vast array of courses in the fields of <strong>Computer Science and Information Technology</strong>. Since its founding in 2016, <strong>IT DESK</strong> has provided training to over 10 Thousands+ students and professionals from various countries.</p>
                <br />
                <p>Since its inception, in a very short span of time, with the qualitative services of training and well-designed training modules, <strong>IT DESK</strong> has expanded its network and become the topmost leading franchise network. <em><strong>IT DESK proudly owns 35+ franchise centers in various parts of the country</strong></em>. Furthermore, we are now expanding our network center in various countries in the world.</p>
                <p><strong>5.1. Curriculum and Course Structure</strong></p>

                <p>Ensure that the curriculum covers essential topics such as SEO, social media marketing, content marketing, email marketing, analytics, and more.</p>
                <br />
                <p><strong>5.2. Hands-On Learning Experience</strong></p>
                <p>Practical experience is invaluable in the field of digital marketing. Look for courses that offer hands-on training and live projects to apply theoretical knowledge.</p>
                <p><strong>5.3. Industry Experts as Trainers</strong></p>
                <p>Learning from industry experts can provide valuable insights into the latest trends and practices, preparing you for the real challenges in the digital marketing domain.</p>
                <br />
                <h2><strong>IT Desk: Empowering Digital Marketing Aspirants</strong></h2>
                <p>IT Desk stands out as a leading institute, nurturing aspiring digital marketers and empowering them with relevant skills and expertise. IT Desk is the best Training Institue in Jaipur for digital marketing because it provides scheduled classes, proper labs, and needed equipment and one of the best is that Digital Marketing experts who taking classes in an efficient manner. IT Desk boasts modern infrastructure and cutting-edge technologies, providing students with a conducive learning environment.</p>
                <h2><strong>Diverse Digital Marketing Programs &amp; Class</strong></h2>
                <p>From beginner courses to advanced specialization programs, IT Desk offers a wide range of digital marketing courses in Jaipur to cater to individual needs. IT Desk provides Digital Marketing Classes for the student by experts in an efficient manner</p>
                <br />
                <p><strong>6.1. Industry-Aligned Certifications</strong></p>
                <p>IT Desk&#8217;s certifications Course hold high industry value, enhancing the employability of their graduates.</p>
                <br />
                <p><strong>6.2. Building a Strong Digital Marketing Network </strong></p>
                <p>The institute encourages networking opportunities among students, alumni, and industry professionals, fostering a supportive community.</p>
                <br />
                <h2><strong>Understanding Digital Marketing Fees</strong></h2>
                <p>While choosing a digital marketing course, it&#8217;s essential to understand the fee structure and what it includes.</p>
                <br />
                <p><strong>7.1. Transparent Fee Structure</strong></p>
                <p>A reputable institute should provide a clear breakdown of their fees and any additional expenses. IT Desk provides the course at a very low cost by experts.</p>
                <br />
                <p><strong>7.2. Value for Money</strong></p>
                <p>Evaluate the benefits offered by the course in relation to the fees charged to ensure you get value for your investment.</p>
                <br />
                <h2><strong>The Significance of Digital Marketing Certification</strong></h2>
                <p>A digital marketing certification in Jaipur holds immense value in today&#8217;s competitive job market. IT DESK Certificates are ISO verified which makes it highly reputed compared to the other local brands and helps the student get prioritized while going for job opportunities in the industry.</p>
                <br />
                <br />
                <p><strong>8.1. Adding Credibility to Your Resume</strong></p>
                <p>Certification showcases your expertise and dedication to the field, making you a more attractive candidate to potential employers.</p>
                <br />
                <p><strong>8.2. Staying Ahead in the Competitive Job Market</strong></p>
                <p>In a rapidly evolving industry, staying updated through certifications sets you apart from other candidates.</p>
                <br />
                <h2><strong>Digital Marketing Companies: Shaping Business Growth</strong></h2>
                <p>Digital marketing companies play a crucial role in driving business growth and success.</p>
                <br />
                <p><strong>9.1. Tailored Marketing Strategies</strong></p>
                <p>These companies develop customized marketing strategies to suit individual business objectives.</p>
                <br />
                <p><strong>9.2. Measurable Results and ROI</strong></p>
                <p>Digital marketing enables businesses to track the performance of their campaigns and measure return on investment accurately.</p>
                <br />
                <p><strong>9.3. Effective Brand Building</strong></p>
                <p>Brand building is a vital aspect of digital marketing, as it helps businesses establish trust and credibility with their audience.</p>
                <br />
                <h2><strong>The Future of Digital Marketing</strong></h2>
                <p>As technology continues to advance, the future of digital marketing holds exciting possibilities.</p>
                <br />
                <p><strong>10.1. Artificial Intelligence in Marketing</strong></p>
                <p>AI-driven marketing automation will revolutionize how businesses interact with customers.</p>
                <br />
                <p><strong>10.2. Personalization and Customer Experience</strong></p>
                <p>Personalized marketing campaigns will become even more crucial in delivering a tailored customer experience.</p>
                <br />
                <p><strong>10.3. Video Marketing Dominance</strong></p>
                <p>Video content will continue to gain popularity as a highly engaging form of digital advertising.</p>
                <br />
                <p><strong>10.4. Voice Search and Interactive Content</strong></p>
                <p>Optimizing content for voice search and interactive experiences will be key to staying relevant in the future. Digital marketing has become a game-changer in the business world, offering unmatched opportunities for growth and success. Embracing digital marketing strategies and investing in professional training can pave the way for a rewarding and thriving career in this dynamic field.</p>
                <br /> <br />
              </div>



            </div>
          </div>
        </div>
      </div>

      {/* <div class="accordion-wrapper">
        <div class="container">
          <h3 class="heading"><span class="text-color-primary" style={{ fontSize: "21px" }}>Course Contents</span></h3>
          <p>The studio was keen to create an identity that would not interfere with any of the existing brands, settling on a helicoid system that can be easily adapted to “work with any colour system”. </p> -->
          <div class="row ">
            <div class="col-lg-8">
              <div class="faq-two-wrapper section-space--mt_40">
                <div id="accordion_two">
                  <div class="card">
                    <div class="card-header" id="heading__110">
                      <h5 class="mb-0">
                        <button class="btn-link collapsed" data-toggle="collapse" data-target="#collapse__110" aria-expanded="false" aria-controls="collapse__110">
                          Web Design and Development<span>
                            <i class="far fa-caret-circle-down"></i>
                            <i class="far fa-caret-circle-right"></i>  </span>
                        </button>
                      </h5>
                    </div>
                    <div id="collapse__110" class="collapse" aria-labelledby="heading__110" data-parent="#accordion_two">
                      <div class="card-body">
                        <p><ul>
                          <li>Working with HTML5</li>
                          <li>Working with CSS</li>
                          <li>Working with JavaScript</li>
                          <li>Working with Jquery</li>
                          <li>Working with Bootstrap</li>
                          <li>MySQL Database</li>
                          <li>Web Developmet PHP</li>
                        </ul>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="card">
                    <div class="card-header" id="heading__111">
                      <h5 class="mb-0">
                        <button class="btn-link collapsed" data-toggle="collapse" data-target="#collapse__111" aria-expanded="false" aria-controls="collapse__111">
                          Search Engine Optimization<span>
                            <i class="far fa-caret-circle-down"></i>
                            <i class="far fa-caret-circle-right"></i>  </span>
                        </button>
                      </h5>
                    </div>
                    <div id="collapse__111" class="collapse" aria-labelledby="heading__111" data-parent="#accordion_two">
                      <div class="card-body">
                        <p><ul>
                          <li>Introduction to Websites and Digital Marketing</li>
                          <li>SEO and Types(Off Page and On Page)</li>
                          <li>Learn best SEO Tools</li>
                        </ul>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="card">
                    <div class="card-header" id="heading__112">
                      <h5 class="mb-0">
                        <button class="btn-link collapsed" data-toggle="collapse" data-target="#collapse__112" aria-expanded="false" aria-controls="collapse__112">
                          Social Media Marketing<span>
                            <i class="far fa-caret-circle-down"></i>
                            <i class="far fa-caret-circle-right"></i>  </span>
                        </button>
                      </h5>
                    </div>
                    <div id="collapse__112" class="collapse" aria-labelledby="heading__112" data-parent="#accordion_two">
                      <div class="card-body">
                        <p><ul>
                          <li>SEO Analytics</li>
                          <li>Social Media Marketing</li>
                          <li>Build and Develop Facebook Marketing Strategies</li>
                          <li>Facebook Ads</li>
                          <li>Twitter marketing</li>
                          <li>Facebook Ads</li>
                          <li>Twitter marketing</li>
                          <li>Linked In Marketing</li>
                          <li>YouTube Marketing</li>
                        </ul>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="card">
                    <div class="card-header" id="heading__113">
                      <h5 class="mb-0">
                        <button class="btn-link collapsed" data-toggle="collapse" data-target="#collapse__113" aria-expanded="false" aria-controls="collapse__113">
                          Email Marketing<span>
                            <i class="far fa-caret-circle-down"></i>
                            <i class="far fa-caret-circle-right"></i>  </span>
                        </button>
                      </h5>
                    </div>
                    <div id="collapse__113" class="collapse" aria-labelledby="heading__113" data-parent="#accordion_two">
                      <div class="card-body">
                        <p><ul>
                          <li>Email Marketing</li>
                          <li>HTML and Text Mails</li>
                          <li>Working with Content and Landing Pages</li>
                        </ul>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="card">
                    <div class="card-header" id="heading__114">
                      <h5 class="mb-0">
                        <button class="btn-link collapsed" data-toggle="collapse" data-target="#collapse__114" aria-expanded="false" aria-controls="collapse__114">
                          Content Marketing<span>
                            <i class="far fa-caret-circle-down"></i>
                            <i class="far fa-caret-circle-right"></i>  </span>
                        </button>
                      </h5>
                    </div>
                    <div id="collapse__114" class="collapse" aria-labelledby="heading__114" data-parent="#accordion_two">
                      <div class="card-body">
                        <p><ul>
                          <li>Content Marketing</li>
                          <li>Target customers and content creations</li>
                          <li>Content Promotion, Analytics and Reporting</li>
                        </ul>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="card">
                    <div class="card-header" id="heading__115">
                      <h5 class="mb-0">
                        <button class="btn-link collapsed" data-toggle="collapse" data-target="#collapse__115" aria-expanded="false" aria-controls="collapse__115">
                          Search Engine Marketing<span>
                            <i class="far fa-caret-circle-down"></i>
                            <i class="far fa-caret-circle-right"></i>  </span>
                        </button>
                      </h5>
                    </div>
                    <div id="collapse__115" class="collapse" aria-labelledby="heading__115" data-parent="#accordion_two">
                      <div class="card-body">
                        <p><ul>
                          <li>Working with PPC</li>
                          <li>Ads on Google</li>
                          <li>PPC Keyword List</li>
                          <li>PPC Landing Paget</li>
                          <li>Build PPC</li>
                        </ul>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="card">
                    <div class="card-header" id="heading__116">
                      <h5 class="mb-0">
                        <button class="btn-link collapsed" data-toggle="collapse" data-target="#collapse__116" aria-expanded="false" aria-controls="collapse__116">
                          Web Analytics<span>
                            <i class="far fa-caret-circle-down"></i>
                            <i class="far fa-caret-circle-right"></i>  </span>
                        </button>
                      </h5>
                    </div>
                    <div id="collapse__116" class="collapse" aria-labelledby="heading__116" data-parent="#accordion_two">
                      <div class="card-body">
                        <p><ul>
                          <li>Working with Web Analytics</li>
                          <li>Web Analytics Dashboard</li>
                          <li>Google analytics</li>
                        </ul>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="card">
                    <div class="card-header" id="heading__117">
                      <h5 class="mb-0">
                        <button class="btn-link collapsed" data-toggle="collapse" data-target="#collapse__117" aria-expanded="false" aria-controls="collapse__117">
                          Self Earning<span>
                            <i class="far fa-caret-circle-down"></i>
                            <i class="far fa-caret-circle-right"></i>  </span>
                        </button>
                      </h5>
                    </div>
                    <div id="collapse__117" class="collapse" aria-labelledby="heading__117" data-parent="#accordion_two">
                      <div class="card-body">
                        <p><ul>
                          <li>Google AdSense</li>
                          <li>Freelancing</li>
                        </ul>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="card">
                    <div class="card-header" id="heading__118">
                      <h5 class="mb-0">
                        <button class="btn-link collapsed" data-toggle="collapse" data-target="#collapse__118" aria-expanded="false" aria-controls="collapse__118">
                          Working with WordPress<span>
                            <i class="far fa-caret-circle-down"></i>
                            <i class="far fa-caret-circle-right"></i>  </span>
                        </button>
                      </h5>
                    </div>
                    <div id="collapse__118" class="collapse" aria-labelledby="heading__118" data-parent="#accordion_two">
                      <div class="card-body">
                        <p></p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-4">

              <div class="contact-info style-two text-left">
                <div class="contact-list-item">
                  <a href="tel:7378123128" class="single-contact-list">
                    <div class="content-wrap">
                      <div class="content">
                        <div class="icon">
                          <span class="fal fa-phone"></span>
                        </div>
                        <div class="main-content">
                          <h6 class="heading">Call for advice now!</h6>
                          <div class="text" style={{ fontSize: "22px" }}>9901371386</div>
                        </div>
                      </div>
                    </div>
                  </a>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div> */}


      <div class="contact-us-section-wrappaer ">
        <div class="container">
          <div class="row align-items-center">
            <div class="col-lg-7 col-lg-7">
              <div class="conact-us-wrap-one">
                <br />
                <br />
                <h5 class="heading mb-20">How will you get your certificate?</h5>
                <div class="center_details">
                  {/* <!--   <h5 class="heading mb-20">CERTIFICATE FAQ</h5> --> */}
                  <p>Upon successful completion of the program, each intern receives a prestigious Digital Marketing Internship Certificate. This credential not only recognizes their achievements but also enhances their employability.</p>
                  <p style={{ textAlign: "justify" }}>IT DESK is a pioneer in CS/IT training and most of the industries give great recognition to its certificate throughout India and abroad. IT DESK Certificates are ISO verified which makes it highly reputed comparing to the other local brands and helps the student get prioritize while going for job opportunities in the industry. IT DESK Software Training is conducted by highly knowledgeable trainers which help students to enhance their skill set by putting great emphasizes on project-based learning.</p>
                  {/* <!-- <a class="btn btn-primary">Get Certificate</a> --> */}
                </div>
              </div>
            </div>
            <div class="col-lg-5 col-lg-5">
              <div class="contact-form-wrap">
                {/* <!-- <h5 class="heading mb-20">How will you get your certificate?</h5> --> */}
                <img src="assests/images/cert11.jpg" style={{ width: " 100%", height: "370px" }} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <br />
      <br />
      <br />
    </Fragment>
  )
}

export default EhicalHacking
