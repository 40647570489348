import React, { Fragment } from 'react'
import Menu from './Menu'
import 'bootstrap/dist/css/bootstrap.min.css';
import MainBanner from '../components/MainBanner'
import HomeBanner from '../components/HomeBanner';
import Testimonial from '../components/Testimonial';
import Stats from '../components/Stats';
import TestiMonials from '../components/Testimonials/Testimonials';
import Internship from './Internship'
import Placement from './Placement'
import Dashboard from '../pages/Dashboard'
// import Footer from '../components/Footer'

const Index = () => {
    return (
        <Fragment>
            <Menu />
            {/* <MainBanner /> */}

            {/*  <HomeBanner /> */}
            <div className="about py-5">
                <div className="container py-xl-5 py-lg-3">
                    <h3 className="title text-capitalize font-weight-light text-dark text-center mb-5">Welcome to
                        <span className="font-weight-bold"> IT Desk</span>
                    </h3>

                    <div className="row pt-md-4">
                        <div className="col-lg-6 about_right">
                            <h3 className="text-capitalize text-center font-weight-light font-italic">interface friendly learning at
                                <span className="font-weight-bold">ITdesk Mahadevapura</span>
                            </h3>
                            <p className="text-right my-4 pr-4 border-right">IT DESK provides professional software training <br /> on a vast array of
                                courses in the fields of Computer Science<br /> and Information Technology. Since its founding in 2016, IT DESK  <br />has provided training to over 10 Thousands+ students and professionals of various  countries..</p>
                            <div className="about_left-list">
                                <h6 className="mb-lg-3 mb-2 font-weight-bold text-dark">Our Benefits</h6>
                                <ul className="list-unstyled">
                                    <li className="mb-2 text-dark">
                                        <i className="fas fa-check mr-3"></i>Data Structure and Algorithms</li>
                                    <li className="mb-2 text-dark">
                                        <i className="fas fa-check mr-3"></i>Object Oriented Programming </li>
                                    <li className="mb-2 text-dark">
                                        <i className="fas fa-check mr-3"></i>Web development Courses</li>
                                    <li className="mb-2 text-dark">
                                        <i className="fas fa-check mr-3"></i>Mobile App Development using Android </li>
                                    <li className="mb-2 text-dark">
                                        <i className="fas fa-check mr-3"></i>Advance Java and Python </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-6 left-img-agikes mt-lg-0 mt-sm-4 mt-3 text-right">
                            <img src="assests/images/ab.jpg" alt="" className="img-fluid" />

                            <div className="about-bottom text-center p-sm-5 p-4">
                                <ul>
                                    <li>
                                        <h5>60+</h5>
                                        <p className="text-dark font-weight-bold">Teachers</p>
                                    </li>
                                    <li>
                                        <h5>2000+</h5>
                                        <p className="text-dark font-weight-bold">Students</p>
                                    </li>
                                    <li>
                                        <h5>80+</h5>
                                        <p className="text-dark font-weight-bold">Courses</p>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Stats />


            <div className="classNamees py-5">
                <div className="container py-xl-5 py-lg-3">
                    <h3 className="title text-capitalize font-weight-light text-dark text-center mb-sm-5 mb-4">choose your
                        <span className="font-weight-bold"> course</span>
                    </h3>
                    <div className="row pt-4">
                        <div className="col-lg-4 col-12 col-news-top text-center">

                            <div className="ih-item circle effect16 left_to_right mx-auto">
                                <a href="/internship" target="_blank" >
                                    <div className="img">
                                        <img src="assests/images/c1.jpg" alt="img" className="img-fluid rounded-circle" />
                                    </div>
                                    <div className="info">
                                        <h3 className="text-capitalize text-white">Internship</h3>
                                        <p>We provide Internship on Latest Trending Technologies</p>
                                    </div>
                                </a>
                            </div>
                            <h6 className="small-heading text-capitalize text-center mt-4">
                                <a href="/internship" className="text-dark" target="_blank" >Internship
                                    <i className="fas fa-long-arrow-alt-right ml-1"></i>
                                </a>
                            </h6>

                        </div>
                        <div className="col-lg-4 col-12 col-news-top text-center my-lg-0 my-sm-5 my-4">

                            <div className="ih-item circle effect16 left_to_right mx-auto">
                                <a href="/allprogram" target="_blank">
                                    <div className="img">
                                        <img src="assests/images/c2.jpg" alt="img" className="img-fluid rounded-circle" />
                                    </div>
                                    <div className="info">
                                        <h3 className="text-capitalize text-white">courses</h3>
                                        <p>We provide Basics to Professional IT Courses</p>
                                    </div>
                                </a>
                            </div>
                            <h6 className="small-heading text-capitalize text-center mt-4">
                                <a href="/allprogram" className="text-dark" target="_blank">Courses
                                    <i className="fas fa-long-arrow-alt-right ml-1"></i>
                                </a>
                            </h6>

                        </div>
                        <div className="col-lg-4 col-12 col-news-top text-center">

                            <div className="ih-item circle effect16 left_to_right mx-auto">
                                <a href="/onlineprogram" target="_blank">
                                    <div className="img">
                                        <img src="assests/images/c3.jpg" alt="img" className="img-fluid rounded-circle" />
                                    </div>
                                    <div className="info">
                                        <h3 className="text-capitalize text-white">classes</h3>
                                        <p>We train Online and Offline Courses</p>
                                    </div>
                                </a>
                            </div>
                            <h6 className="small-heading text-capitalize text-center mt-4">
                                <a href="/onlineprogram" className="text-dark" target="_blank">classes
                                    <i className="fas fa-long-arrow-alt-right ml-1"></i>
                                </a>
                            </h6>

                        </div>
                    </div>
                    <div className="row mt-5">
                        <div className="col-lg-4 col-12 col-news-top text-center">

                            <div className="ih-item circle effect16 left_to_right mx-auto">
                                <a href="/placement" target="_blank">
                                    <div className="img">
                                        <img src="assests/images/c4.jpg" alt="img" className="img-fluid rounded-circle" />
                                    </div>
                                    <div className="info">
                                        <h3 className="text-capitalize text-white">Placements </h3>
                                        <p>We do provide Placement in Startups to MNC Companies</p>
                                    </div>
                                </a>
                            </div>
                            <h6 className="small-heading text-capitalize text-center mt-4">
                                <a href="/placement" className="text-dark" target="_blank">Placements
                                    <i className="fas fa-long-arrow-alt-right ml-1"></i>
                                </a>
                            </h6>

                        </div>
                        <div className="col-lg-4 col-12 col-news-top text-center  my-lg-0 my-sm-5 my-4">

                            <div className="ih-item circle effect16 left_to_right mx-auto">
                                <a href="/contactus" target="_blank">
                                    <div className="img">
                                        <img src="assests/images/c6.jpg" alt="img" className="img-fluid rounded-circle" />
                                    </div>
                                    <div className="info">
                                        <h3 className="text-capitalize text-white">social media</h3>
                                        <p>Our digital network is Strong</p>
                                    </div>
                                </a>
                            </div>
                            <h6 className="small-heading text-capitalize text-center mt-4">
                                <a href="/contactus" className="text-dark" target="_blank">social media
                                    <i className="fas fa-long-arrow-alt-right ml-1"></i>
                                </a>
                            </h6>

                        </div>
                        <div className="col-lg-4 col-12 col-news-top text-center">

                            <div className="ih-item circle effect16 left_to_right mx-auto">
                                <a href="/about" target="_blank">
                                    <div className="img">
                                        <img src="assests/images/c5.jpg" alt="img" className="img-fluid rounded-circle" />
                                    </div>
                                    <div className="info">
                                        <h3 className="text-capitalize text-white">IT Desk</h3>
                                        <p>Develop your IT skills at IT Desk</p>
                                    </div>
                                </a>
                            </div>
                            <h6 className="small-heading text-capitalize text-center mt-4">
                                <a href="/about" className="text-dark" target="_blank">IT Desk
                                    <i className="fas fa-long-arrow-alt-right ml-1"></i>
                                </a>
                            </h6>

                        </div>
                    </div>
                </div>
            </div>

            <div className="why-choose-agile py-5">
                <div className="container py-xl-5 py-lg-3">
                    <h3 className="title text-capitalize font-weight-light text-white text-center mb-5">what we
                        <span className="font-weight-bold"> do</span>
                    </h3>
                    <div className="row agileits-w3layouts-grid pt-md-4">
                        <div className="col-lg-4 col-12 services-agile-1">
                            <div className="row wthree_agile_us">
                                <div className="col-lg-3 col-md-2 col-3  agile-why-text">
                                    <div className="wthree_features_grid text-center p-3 border rounded">
                                        <i className="fab fa-accusoft"></i>
                                    </div>
                                </div>
                                <div className="col-9 agile-why-text-2">
                                    <h4 className="text-capitalize text-white font-weight-bold mb-3">special education</h4>
                                    <p className='text-white'>IT Desk offered courses like Advance Excel , Tally GST , C, C++, Java , C# , PHP , Python etc.</p>
                                    <a className="btn mt-3 service-button p-0" href="/about" role="button">Read More
                                        <i className="fas fa-long-arrow-alt-right ml-1"></i>
                                    </a>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-12 services-agile-1 my-lg-0 my-5">
                            <div className="row wthree_agile_us">
                                <div className="col-lg-3 col-md-2 col-3  agile-why-text">
                                    <div className="wthree_features_grid text-center p-3 border rounded">
                                        <i className="fas fa-book"></i>
                                    </div>
                                </div>
                                <div className="col-9 agile-why-text-2">
                                    <h4 className="text-capitalize text-white font-weight-bold mb-3">full day session</h4>
                                    <p className='text-white'>IT Desk offered advance courses like Android , Advance Java , Machine Learning , Data Science , Digital Marketing , Ethical Hacking etc.</p>
                                    <a className="btn mt-3 service-button p-0" href="/about" role="button">Read More
                                        <i className="fas fa-long-arrow-alt-right ml-1"></i>
                                    </a>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-12 services-agile-1">
                            <div className="row wthree_agile_us">
                                <div className="col-lg-3 col-md-2 col-3  agile-why-text">
                                    <div className="wthree_features_grid text-center p-3 border rounded">
                                        <i className="fas fa-users"></i>
                                    </div>
                                </div>
                                <div className="col-9 agile-why-text-2">
                                    <h4 className="text-capitalize text-white font-weight-bold mb-3">qualified teachers</h4>
                                    <p className='text-white'>IT Desk 20 years vast experience in training field online and offline training, on demand we provide online training for students</p>
                                    <a className="btn mt-3 service-button p-0" href="/about" role="button">Read More
                                        <i className="fas fa-long-arrow-alt-right ml-1"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row agileits-w3layouts-grid mt-5">
                        <div className="col-lg-4 col-12 services-agile-1">
                            <div className="row wthree_agile_us">
                                <div className="col-lg-3 col-md-2 col-3  agile-why-text">
                                    <div className="wthree_features_grid text-center p-3 border rounded">
                                        <i className="far fa-calendar-alt"></i>
                                    </div>
                                </div>
                                <div className="col-9 agile-why-text-2">
                                    <h4 className="text-capitalize text-white font-weight-bold mb-3">events</h4>
                                    <p className='text-white'> IT DESK has provided training to over 10 Thousands+ students and professionals of various countries.</p>
                                    <a className="btn mt-3 service-button p-0" href="/about" role="button">Read More
                                        <i className="fas fa-long-arrow-alt-right ml-1"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-12 services-agile-1  my-lg-0 my-5">
                            <div className="row wthree_agile_us">
                                <div className="col-lg-3 col-md-2 col-3  agile-why-text">
                                    <div className="wthree_features_grid text-center p-3 border rounded">
                                        <i className="fas fa-graduation-cap"></i>
                                    </div>
                                </div>
                                <div className="col-9 agile-why-text-2">
                                    <h4 className="text-capitalize text-white font-weight-bold mb-3">pre classNamees</h4>
                                    <p className='text-white'>IT DESK has expanded its network and become the topmost leading franchise network.</p>
                                    <a className="btn mt-3 service-button p-0" href="/about" role="button">Read More
                                        <i className="fas fa-long-arrow-alt-right ml-1"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-12 services-agile-1">
                            <div className="row wthree_agile_us">
                                <div className="col-lg-3 col-md-2 col-3  agile-why-text">
                                    <div className="wthree_features_grid text-center p-3 border rounded">
                                        <i className="far fa-clock"></i>
                                    </div>
                                </div>
                                <div className="col-9 agile-why-text-2">
                                    <h4 className="text-capitalize text-white font-weight-bold mb-3">24/7 supports</h4>
                                    <p className='text-white'>Get in touch with our support and sales team via email or talk to them using our toll-free numbers.</p>
                                    <a className="btn mt-3 service-button p-0" href="/about" role="button">Read More
                                        <i className="fas fa-long-arrow-alt-right ml-1"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="video-choose-agile py-5">
                <div className="container py-xl-5 py-lg-3">
                    <div className="row">
                        <div className="col-lg-7 video">
                            <h3 className="title text-capitalize font-weight-light text-dark text-center mb-5">our
                                <span className="font-weight-bold"> video</span>
                            </h3>
                            {/* <iframe src="https://player.vimeo.com/video/58123228"></iframe> */}
                            <video controls width="100%">
                                <source src="assests/images/abtvid1.mp4" type="video/webm" />
                                {/* <source src="/video-example.mp4" type="video/mp4"/> */}
                                Sorry, your browser doesn't support videos.
                            </video>
                        </div>
                        <div className="col-lg-5 events">
                            <h3 className="title text-capitalize font-weight-light text-dark text-center mb-5">upcoming
                                <span className="font-weight-bold"> events</span>
                            </h3>
                            <div className="events-w3ls">
                                <div className="d-flex">
                                    <div className="col-sm-2 col-3 events-up p-3 text-center">
                                        <h5 className="text-white font-weight-bold">1st WEEK
                                            <span className="border-top font-weight-light pt-2 mt-2">SEP</span>
                                        </h5>
                                    </div>
                                    <div className="col-sm-10 col-9 events-right">
                                        <h4 className="text-dark">Admission For Internship</h4>
                                        <ul className="list-unstyled">
                                            <li className="my-2">
                                                <i className="far fa-clock mr-2"></i>10.00 am - 4.30 pm</li>
                                            <li>
                                                <i className="fas fa-map-marker mr-2"></i>Banglore</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="d-flex my-4">
                                <div className="col-sm-2 col-3 events-up p-3 text-center">
                                    <h5 className="text-white font-weight-bold">1st WEEK
                                        <span className="border-top font-weight-light pt-2 mt-2">SEP</span>
                                    </h5>
                                </div>
                                <div className="col-sm-10 col-9 events-right">
                                    <h4 className="text-dark">Admission For courses</h4>
                                    <ul className="list-unstyled">
                                        <li className="my-2">
                                            <i className="far fa-clock mr-2"></i>10.00 am - 4.30 pm</li>
                                        <li>
                                            <i className="fas fa-map-marker mr-2"></i>Banglore.</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="d-flex">
                                <div className="col-sm-2 col-3 events-up p-3 text-center">
                                    <h5 className="text-white font-weight-bold">1st WEEK
                                        <span className="border-top font-weight-light pt-2 mt-2">SEP</span>
                                    </h5>
                                </div>
                                <div className="col-sm-10 col-9 events-right">
                                    <h4 className="text-dark">Digital Marketting </h4>
                                    <ul className="list-unstyled">
                                        <li className="my-2">
                                            <i className="far fa-clock mr-2"></i>10.00 am - 4.30 pm</li>
                                        <li>
                                            <i className="fas fa-map-marker mr-2"></i>Banglore.</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* <TestiMonials /> */}
            {/* <Testimonial /> */}
            <div className="testimonials py-5">
                <div className="container py-xl-5 py-lg-3">
                    <h3 className="title text-capitalize font-weight-light text-white text-center mb-5">what our
                        <span className="font-weight-bold"> people says</span>
                    </h3>
                    <div className="mis-stage">
                        {/* <p>Founder's Message <br />
                            After building our brand recognition in the CAD industry with over 170+ centers around India, we decided to continue on the path that lead to the creation of IT desk. The objective of the IT desk is to enhance the students' skill sets by offering training from the most basic to the most sophisticated levels. Our highly trained training team allows us to provide the most effective training in the industry. We stay abreast of the ever-changing technology scene and present them with a variety of educational options.
                        </p>  */}
                        <TestiMonials />



                    </div>
                </div>
            </div>




            <div className="news-section py-5">
                <div className="container py-xl-5 py-lg-3">
                    <h3 className="title text-capitalize font-weight-light text-dark text-center mb-5">our lastest
                        <span className="font-weight-bold"> News</span>
                    </h3>
                    <div className="row news-grids-w3l pt-md-4">
                        <div className="col-md-4 news-grid">
                            <a href="blog.html">
                                <img src="assests/images/background.jpeg" className="img-fluid" alt="" />
                            </a>
                            <div className="news-text">
                                <div className="news-events-agile event-colo1 py-2 px-3">
                                    <h5 className="float-left">
                                        <a href="blog.html" className="text-white">SEP</a>
                                    </h5>
                                    <div className="post-img float-right">
                                        <ul>
                                            <li>
                                                <a href="#">
                                                    <i className="far fa-comments text-white"></i>
                                                </a>
                                            </li>
                                            <li className="mx-3">
                                                <a href="#">
                                                    <i className="far fa-heart text-white"></i>
                                                </a>
                                            </li>
                                            <li>
                                                <a href="#">
                                                    <i className="fas fa-share text-white"></i>
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="clearfix"></div>
                                </div>
                                <div className="detail-bottom">
                                    <h6 className="my-3">
                                        <a href="blog.html" className="text-dark">
                                            Internship
                                        </a>
                                    </h6>
                                    <p>An internship is a structured and supervised work experience that allows individuals, typically students or recent graduates, to gain practical exposure and insights into their chosen fields of study or career paths.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 news-grid my-md-0 my-sm-5 my-4">
                            <a href="blog.html">
                                <img src="assests/images/background.jpeg" className="img-fluid" alt="" />
                            </a>
                            <div className="news-text">
                                <div className="news-events-agile py-2 px-3">
                                    <h5 className="float-left">
                                        <a href="blog.html" className="text-white">SEP</a>
                                    </h5>
                                    <div className="post-img float-right">
                                        <ul>
                                            <li>
                                                <a href="#">
                                                    <i className="far fa-comments text-white"></i>
                                                </a>
                                            </li>
                                            <li className="mx-3">
                                                <a href="#">
                                                    <i className="far fa-heart text-white"></i>
                                                </a>
                                            </li>
                                            <li>
                                                <a href="#">
                                                    <i className="fas fa-share text-white"></i>
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="clearfix"></div>
                                </div>
                                <div className="detail-bottom">
                                    <h6 className="my-3">
                                        <a href="blog.html" className="text-dark">
                                            Courses
                                        </a>
                                    </h6>
                                    <p>Courses are structured educational programs designed to impart knowledge, skills, and expertise in a specific subject or field of study. They are offered at various academic levels and cater to individuals with diverse interests and career goals.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 news-grid">
                            <a href="blog.html">
                                <img src="assests/images/background.jpeg" className="img-fluid" alt="" />
                            </a>
                            <div className="news-text">
                                <div className="news-events-agile event-colo3 py-2 px-3">
                                    <h5 className="float-left">
                                        <a href="blog.html" className="text-white">SEP</a>
                                    </h5>
                                    <div className="post-img float-right">
                                        <ul>
                                            <li>
                                                <a href="#">
                                                    <i className="far fa-comments text-white"></i>
                                                </a>
                                            </li>
                                            <li className="mx-3">
                                                <a href="#">
                                                    <i className="far fa-heart text-white"></i>
                                                </a>
                                            </li>
                                            <li>
                                                <a href="#">
                                                    <i className="fas fa-share text-white"></i>
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="clearfix"></div>
                                </div>
                                <div className="detail-bottom">
                                    <h6 className="my-3">
                                        <a href="blog.html" className="text-dark">
                                            Placement
                                        </a>
                                    </h6>
                                    <p>Placement refers to the process of assisting individuals, usually students or job seekers, in finding suitable employment opportunities that align with their skills, qualifications, and career aspirations. </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>



            {/* <div className="brands-w3ls">
                <div className="container py-xl-3">
                    <ul className="list-unstyled"> */}
            {/* <li>
                            <i className="fab fa-supple"></i>
                        </li> */}
            {/* <li data-aos="fade-up" data-aos-once="true" class="aos-init aos-animate">

                            <img src="https://itdeskindia.com/wp-content/uploads/2021/01/seven.png" alt="Logo 5" /></li>


                            <li data-aos="fade-up" data-aos-once="true" class="aos-init aos-animate">
                                <img src="https://itdeskindia.com/wp-content/uploads/2021/01/eight.png" alt="Logo 15" /></li>
                          <li data-aos="fade-up" data-aos-once="true" class="aos-init aos-animate">
                            <img src="https://itdeskindia.com/wp-content/uploads/2021/01/five.png" alt="Logo 14" /></li>
                          <li data-aos="fade-up" data-aos-once="true" class="aos-init aos-animate">
                            <img src="https://itdeskindia.com/wp-content/uploads/2021/01/four.png" alt="Logo 13" /></li>
                          <li data-aos="fade-up" data-aos-once="true" class="aos-init aos-animate">
                            <img src="https://itdeskindia.com/wp-content/uploads/2021/01/nine.png" alt="Logo 12" /></li>
                          <li data-aos="fade-up" data-aos-once="true" class="aos-init aos-animate">
                            <img src="https://itdeskindia.com/wp-content/uploads/2021/01/one.png" alt="Logo 11" /></li>
                          <li data-aos="fade-up" data-aos-once="true" class="aos-init aos-animate">
                            <img src="https://itdeskindia.com/wp-content/uploads/2021/01/rec1.png" alt="Logo 10" /></li>
                          <li data-aos="fade-up" data-aos-once="true" class="aos-init aos-animate">
                            <img src="https://itdeskindia.com/wp-content/uploads/2021/01/rec3.png" alt="Logo 9" /></li>

                            
                          <li data-aos="fade-up" data-aos-once="true" class="aos-init aos-animate">
                            <img src="https://itdeskindia.com/wp-content/uploads/2021/01/seven.png" alt="Logo 5" /></li>
                          <li data-aos="fade-up" data-aos-once="true" class="aos-init aos-animate">
                            <img src="https://itdeskindia.com/wp-content/uploads/2021/01/six.png" alt="Logo 4" /></li> */}
            {/* <li data-aos="fade-up" data-aos-once="true" class="aos-init aos-animate">
                            <img src="https://itdeskindia.com/wp-content/uploads/2021/01/ten.png" alt="Logo 3" /></li> */}
            {/* <li data-aos="fade-up" data-aos-once="true" class="aos-init aos-animate">
                            <img src="https://itdeskindia.com/wp-content/uploads/2021/01/two.png" alt="Logo 2" /></li> */}

            {/* <li>
                            <i className="fab fa-angrycreative"></i>
                        </li>
                        <li>
                            <i className="fab fa-aviato"></i>
                        </li>
                        <li>
                            <i className="fab fa-aws"></i>
                        </li>
                        <li>
                            <i className="fab fa-cpanel"></i>
                        </li>
                        <li>
                            <i className="fab fa-hooli"></i>
                        </li>
                        <li>
                            <i className="fab fa-node"></i>
                        </li> */}
            {/* <li data-aos="fade-up" data-aos-once="true" class="aos-init aos-animate">
                            <img src="https://itdeskindia.com/wp-content/uploads/2021/01/eight.png" alt="Logo 15" /></li>
                    </ul>
                </div>
            </div> */}


            {/* 
            <footer>
                <div className="container py-4">
                    <div className="row py-xl-5 py-sm-3">
                        <div className="col-lg-6 map">
                            <h2 className="contact-title text-capitalize text-white font-weight-light mb-sm-4 mb-3">our
                                <span className="font-weight-bold">directions</span>
                            </h2>
                            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3023.495758873587!2d-74.0005340845242!3d40.72911557933012!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c25991b7473067%3A0x59fbd02f7b519ce8!2s550+LaGuardia+Pl%2C+New+York%2C+NY+10012%2C+USA!5e0!3m2!1sen!2sin!4v1516166447283"></iframe>
                            <div className="conta-posi-w3ls p-4 rounded">
                                <h5 className="text-white font-weight-bold mb-3">Address</h5>
                                <p>25095 Blue Ravine Rd,
                                    <span>Diamonds street,</span> California, USA</p>
                            </div>
                        </div>
                        <div className="col-lg-6 contact-agileits-w3layouts mt-lg-0 mt-4">
                            <h4 className="contact-title text-capitalize text-white font-weight-light mb-sm-4 mb-3">get in
                                <span className="font-weight-bold">touch</span>
                            </h4>
                            <p className="conta-para-style border-left pl-4">If you have any questions about the services we provide simply use the form below. We try and respond to all queries
                                and comments within 24 hours.</p>
                            <div className="subscribe-w3ls my-xl-5 my-4">
                                <h6 className="text-white text-capitalize mb-4">subscribe our newsletter</h6>
                                <form action="#" method="post" className="subscribe_form">
                                    <input className="form-control" type="email" name="email" placeholder="Enter your email..." required="" />
                                    <button type="submit" className="btn btn-primary submit">Submit</button>
                                </form>
                            </div>
                            <p className="para-agileits-w3layouts text-white">
                                <i className="fas fa-map-marker pr-3"></i>25095 Blue Ravine Rd,USA</p>
                            <p className="para-agileits-w3layouts text-white my-sm-3 my-2">
                                <i className="fas fa-phone pr-3"></i>032 625 4592</p>
                            <p className="para-agileits-w3layouts">
                                <i className="far fa-envelope-open pr-2"></i>
                                <a href="mailto:mail@example.com" className=" text-white">info@example.com</a>
                            </p>
                        </div>
                    </div>
                </div>
                <div className="copyright-agiles py-3">
                    <div className="container">
                        <div className="row">
                            <p className="col-lg-8 copy-right-grids text-white text-lg-left text-center mt-lg-1">© 2018 Edulearn. All Rights Reserved | Design by
                                <a href="https://w3layouts.com/" target="_blank">W3layouts</a>
                            </p>

                            <div className="social-icons text-lg-right text-center col-lg-4 mt-lg-0 mt-3">
                                <ul className="list-unstyled">
                                    <li>
                                        <a href="#" className="fab fa-facebook-f icon-border facebook rounded-circle"> </a>
                                    </li>
                                    <li className="mx-2">
                                        <a href="#" className="fab fa-twitter icon-border twitter rounded-circle"> </a>
                                    </li>
                                    <li>
                                        <a href="#" className="fab fa-google-plus-g icon-border googleplus rounded-circle"> </a>
                                    </li>
                                    <li className="ml-2">
                                        <a href="#" className="fas fa-rss icon-border rss rounded-circle"> </a>
                                    </li>
                                </ul>
                            </div>

                        </div>
                    </div>
                </div>
            </footer> */}



            {/* <!-- Js files -->
	<!-- JavaScript -->
	<script src="js/jquery-2.2.3.min.js"></script>
	<!-- Default-JavaScript-File -->
	<script src="js/bootstrap.js"></script>
	<!-- Necessary-JavaScript-File-For-Bootstrap -->

	<!-- banner slider -->
	<script src="js/slider.js"></script>
	<!-- //banner slider -->

	<!-- testimonial-plugin -->
	<script src="js/mislider.js"></script>
	<script>
		jQuery(function ($) {
			var slider = $('.mis-stage').miSlider({
				//  The height of the stage in px. Options: false or positive integer. false = height is calculated using maximum slide heights. Default: false
				stageHeight: 320,
				//  Number of slides visible at one time. Options: false or positive integer. false = Fit as many as possible.  Default: 1
				slidesOnStage: false,
				//  The location of the current slide on the stage. Options: 'left', 'right', 'center'. Defualt: 'left'
				slidePosition: 'center',
				//  The slide to start on. Options: 'beg', 'mid', 'end' or slide number starting at 1 - '1','2','3', etc. Defualt: 'beg'
				slideStart: 'mid',
				//  The relative percentage scaling factor of the current slide - other slides are scaled down. Options: positive number 100 or higher. 100 = No scaling. Defualt: 100
				slideScaling: 150,
				//  The vertical offset of the slide center as a percentage of slide height. Options:  positive or negative number. Neg value = up. Pos value = down. 0 = No offset. Default: 0
				offsetV: -5,
				//  Center slide contents vertically - Boolean. Default: false
				centerV: true,
				//  Opacity of the prev and next button navigation when not transitioning. Options: Number between 0 and 1. 0 (transparent) - 1 (opaque). Default: .5
				navButtonsOpacity: 1,
			});
		});
	</script>
	<!-- //testimonial-plugin -->

	<!-- numscroller-js-file -->
	<script src="js/numscroller-1.0.js"></script>
	<!-- //numscroller-js-file -->

	<!-- smooth scrolling -->
	<script src="js/SmoothScroll.min.js"></script>
	<!-- //smooth scrolling -->

	<!-- move-top -->
	<script src="js/move-top.js"></script>
	<!-- easing -->
	<script src="js/easing.js"></script>
	<!--  necessary snippets for few javascript files -->
	<script src="js/edulearn.js"></script>

	<!-- //Js files --> */}
            
        </Fragment>
    )

}
export default Index
