import React, { Fragment } from 'react';
import {Link} from 'react-router-dom'
import { motion } from 'framer-motion';
import './TrendingCourses.css'; // Import the CSS file

const coursesData = [
    {
        id: 1,
        title: 'Digital Marketing',
        image: "/assests/images/Digital_Marketting.jpg",
        description: 'A most powerful tool to grow business and increase reach worldwide.                               ',
        link: '/digitalmarketing'
    },
    {
        id: 2,
        title: 'Data Science with Python',
        image: '/assests/images/DS-with-Python.jpg',
        description: 'Data science with Python performs data analysis, data visualization.',
        link: '/python',
    },
    {
        id: 3,
        title: 'JAVA',
        image: '/assests/images/DS-with-R.jpg',
        description: 'Data Science with R provides the skills required to work with real data sets',
        link: '/corejava',
    },
    {
        id: 4,
        title: 'Mern Stack Development',
        image: '/assests/images/Meanstack.jpg',
        description: 'MEAN is an acronym for MongoDB, Express.js and Angularjs, all of which function upon Node.js',
        link: '/mernstack',
    },
    // Add more course objects as needed
    {
        id: 5,
        title: '.NET',
        image: '/assests/images/Tableau.jpg',
        description: 'Most powerful, secure, and flexible end-to-end analytics platform for data',
        link: '/.net',
    },
    {
        id: 6,
        title: 'Python',
        image: '/assests/images/python2.jpg',
        description: 'high-level programming language with dynamic semantics developed by Guido van Rossum.',
        link: '/python',
    },
    {
        id: 7,
        title: 'Ethical Hacking',
        image: '/assests/images/hacking.jpg',
        description: 'o legally break into computers and networks to test an organizations overall security',
        link: '/ethicalhacking',
    },
    {
        id: 8,
        title: 'Fullstack Developer',
        image: '/assests/images/full-stack.jpg',
        description: 'a professional responsible for working on both front-end and back-end development processes.',
        link: '/fullstack',
    },
    {
        id: 9,
        title: 'C Sharp',
        image: 'assests/images/csharp.jpg',
        description: 'a professional responsible for working on both front-end and back-end development processes.',
        link: '/csharp',
    },

    {
        id: 10,
        title: 'C programming',
        image: 'assests/images/cp.png',
        description: 'a professional responsible for working on both front-end and back-end development processes.',
        link: '/cprogramming',
    },

    {
        id: 11,
        title: 'Android',
        image: 'assests/images/cg1.jpg',
        description: 'a professional responsible for working on both front-end and back-end development processes.',
        link: '/android',
    },

    {
        id: 12,
        title: ' JAVA Fullstack Development ',
        image: 'assests/images/cg2.jpg',
        description: 'a professional responsible for working on both front-end and back-end development processes.',
        link: '/fullstack',
    },
    {
        id: 13,
        title: ' PHP',
        image: 'assests/images/download2.png',
        description: 'a professional responsible for working on both front-end and back-end development processes.',
        link: '/php',
    },
    {
        id: 14,
        title: ' Selenium Testing',
        image: 'assests/images/download3.jpg',
        description: 'a professional responsible for working on both front-end and back-end development processes.',
        link: '/selenium',
    },
    {
        id: 15,
        title: ' AWS ',
        image: 'assests/images/download4.jpg',
        description: 'a professional responsible for working on both front-end and back-end development processes.',
        link: '/aws',
    },
    {
        id: 16,
        title: ' MYSQL',
        image: 'assests/images/download5.jpg',
        description: ' fully managed database service for transactions, real- time analytics across data warehouses.',
        link: '/mysql',
    },
    {
        id: 17,
        title: ' Basic MS Office',
        image: 'assests/images/ms1.png',
        description: ' fully managed database service for transactions, real- time analytics across data warehouses.',
        link: '/msoffice',
    },
    {
        id: 18,
        title: ' Advance EXCEL',
        image: 'assests/images/ms2.jpg',
        description: ' fully managed database service for transactions, real- time analytics across data warehouses.',
        link: '/aexcel',
    },
    {
        id: 19,
        title: ' Tally ERP9 with GST ',
        image: 'assests/images/ms3.png',
        description: ' fully managed database service for transactions, real- time analytics across data warehouses.',
        link: '/tally',
    },
    {
        id: 20,
        title: 'SAP- FICO ',
        image: 'assests/images/ms4.jpg',
        description: ' fully managed database service for transactions, real- time analytics across data warehouses.',
        link: '/sap',
    },

];

const TrendingCourses = () => {
    return (
        <Fragment>
            {/* <div className="banner-agile-2">

                <div className="navigation-w3ls">
                    <nav className="navbar navbar-expand-lg navbar-light bg-light sticky-nav">
                        <button className="navbar-toggler mx-auto" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                            aria-expanded="false" aria-label="Toggle navigation">
                            <span className="navbar-toggler-icon"></span>
                        </button>
                        <div className="collapse navbar-collapse text-center" id="navbarSupportedContent">
                            <ul className="navbar-nav justify-content-center">
                                <li className="nav-item active">

                                    <a className="nav-link text-white" href="/">Home
                                        <span className="sr-only">(current)</span>
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link text-white" href="/about">About Us</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link text-white" href="/allprogram">All Programs</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link text-white" href="/Onlineprogram">Online Programs</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link text-white" href="/internship">Internship</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link text-white" href="/placement">Placement</a>
                                </li>



                                <li className="nav-item">
                                    <a className="nav-link text-white" href="/couses">courses</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link text-white" href="/contactus">Contactus</a>
                                </li>


                            </ul>
                        </div>
                    </nav>
                </div>
            </div> */}
            {/* <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                        <a href="/">Home</a>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page"></li>
                </ol>
            </nav> */}
            <div className="trending-courses">
            {/* <br /><h1>IT DESK - Courses</h1><br /> */}
            <h3 className="title text-capitalize font-weight-light text-dark text-center mb-5">IT Desk -
                        <span className="font-weight-bold">Courses</span>
                    </h3>
                    <br/>
                <div className="course-list">
                    {coursesData.map(course => (
                        <motion.div
                            className="course-card"
                            key={course.id}
                            whileHover={{ scale: 1.05 }}
                            whileTap={{ scale: 0.95 }}
                        >
                            <img src={course.image} alt={course.title} />
                            <h2>{course.title}</h2>
                            <p>{course.description}</p>
                            <a href={course.link} target="_blank" rel="noopener noreferrer">
                                <h2>Learn More</h2>
                            </a>
                            {/* <Link to={course.link} >Learn More</Link> */}
                         </motion.div>
                    ))}
                </div>
            </div>
        </Fragment>
    );
};

export default TrendingCourses