import React from 'react'
import { Link } from 'react-router-dom';

const Navbar = ({ isAdmin}) => {
    console.log(isAdmin)
    return (
        <div>
            <div className="navigation-w3ls">
                <nav className="navbar navbar-expand-lg navbar-light bg-light sticky-nav">
                    <button className="navbar-toggler mx-auto" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                        aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse text-center" id="navbarSupportedContent">
                        <ul className="navbar-nav justify-content-center">
                            <li className="nav-item active">

                                <a className="nav-link text-white" href="/">Home
                                    <span className="sr-only">(current)</span>
                                </a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link text-white" href="/about">About Us</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link text-white" href="/allprogram">All Programs</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link text-white" href="/Onlineprogram">Online Programs</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link text-white" href="/gallery1">Gallery</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link text-white" href="/internship">Internship</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link text-white" href="/placement">Placement</a>
                            </li>

                            <li className="nav-item">
                                <a className="nav-link text-white" href="/contactus">Contactus</a>
                            </li>

                            {isAdmin && (

                                <li className="nav-item">
                                    <Link to="/dashboard" className="nav-link text-white get-start-button d-flex align-items-center" style={{ width: "20px", marginTop: "-1px" }}>
                                        <b style={{color:"blue"}}>ADMIN</b>
                                    </Link>
                                </li>

                            )}

                        </ul>
                    </div>
                </nav>
            </div>
        </div>
    )
}

export default Navbar
