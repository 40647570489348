import React, { useEffect, Fragment,useState} from 'react'
import { Link } from 'react-router-dom';
import Footer from '../components/Footer';
import TransparentRegistrationForm from '../components/TransparentRegistrationForm';
import ContactForm from '../components/ContactForm';
import $ from 'jquery';
import "jquery-ui-dist/jquery-ui"
import Navbar from './Navbar';

const Menu = ({ loggedIn, onLogout }) => {


    useEffect(()=> {

        var sliderInterval = null;
        var $slider = $('.slider');
        var index = 0;
        sliderInterval = setInterval(function(){
            ++index;
            if(index >= $slider.children().length){
            index = 0;
            }
            changeSlideTo(index);
        },3000);

        function changeSlideTo(index){
            var $next = $slider.children().eq(index);
            var $current = $slider.children('.active');
            var $prev = $slider.children('.prev');
            if($prev.length === 0){
            $prev = $slider.children().last();
            }

            $next.addClass('active');
            $current.removeClass('active').addClass('prev');
            $prev.removeClass('prev');
            
            $('.pager .active').removeClass('active');
            $('.pager li').eq(index).addClass('active');
        }
        
        $('.pager li').click(function(e){
            e.preventDefault();
            clearInterval(sliderInterval);
            changeSlideTo($(this).data('index'));
        });

    },[] )




    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [isAdmin, setIsAdmin] = useState(false);
  
    useEffect(() => {
      // Check if admin is logged in from sessionStorage
      const isAdminLoggedIn = sessionStorage.getItem('isAdminLoggedIn');
      if (isAdminLoggedIn) {
        setIsAdmin(true);
        setIsLoggedIn(true); // Assuming admin login also means user is logged in
      }
    }, []);
  
    const handleLogin = () => {
      // Your login logic here
      setIsLoggedIn(true);
      setIsAdmin(true);
      // Store admin login state in sessionStorage
      sessionStorage.setItem('isAdminLoggedIn', 'true');
    };
  
    const handleLogout = () => {
      // Your logout logic here
      setIsLoggedIn(false);
      setIsAdmin(false);
      sessionStorage.removeItem('isAdminLoggedIn');
    };

    return (
        <Fragment>

            <div className="banner-agile">
                <ul className="slider">
                <li className="active">
                        <div className="banner-w3ls-1">
                            <div className="banner-text-posi-w3ls">
                                <div className="banner-text-whtree"  >
                                    <h3 className="text-capitalize text-white p-4">Empowering Dreams,
                                        <b>Illuminating Futures!!</b>
                                    </h3>
                                    <p className="px-4 py-3 text-dark">Expanding the possibilities in IT</p>

                                    <a href="/about" className="button-agiles text-capitalize text-white mt-sm-5 mt-4">read more</a>
                                </div>
                            </div>
                        </div>
                     </li>
                    <li>
                        <div className="banner-w3ls-2">
                            <div className="banner-text-posi-w3ls">
                                <div className="banner-text-whtree"  >
                                    <h3 className="text-capitalize text-white p-4">Great Learnings,
                                        <b>Ahead!!</b>
                                    </h3>
                                    <p className="px-4 py-3 text-dark">Explore IT Courses Now</p>

                                    <a href="/about" className="button-agiles text-capitalize text-white mt-sm-5 mt-4">read more</a>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li>
                        <div className="banner-w3ls-3">
                            <div className="banner-text-posi-w3ls">
                                <div className="banner-text-whtree"  >
                                    <h3 className="text-capitalize text-white p-4">Unlock Your,
                                        <b>Key Potential!!</b>
                                    </h3>
                                    <p className="px-4 py-3 text-dark">With IT DESK E-learning Platform</p>

                                    <a href="/about" className="button-agiles text-capitalize text-white mt-sm-5 mt-4">read more</a>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li>
                        <div className="banner-w3ls-4">
                            <div className="banner-text-posi-w3ls">
                                <div className="banner-text-whtree"  >
                                    <h3 className="text-capitalize text-white p-4">Learning Never,
                                        <b>Exhausts The Mind!!</b>
                                    </h3>
                                    <p className="px-4 py-3 text-dark">Learn IT Trending Courses</p>

                                    <a href="/about" className="button-agiles text-capitalize text-white mt-sm-5 mt-4">read more</a>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li>
                        <div className="banner-w3ls-4">
                            <div className="banner-text-posi-w3ls">
                                <div className="banner-text-whtree"  >
                                    <h3 className="text-capitalize text-white p-4">Learning Never,
                                        <b>Exhausts The Mind!!</b>
                                    </h3>
                                    <p className="px-4 py-3 text-dark">Learn IT Trending Courses</p>

                                    <a href="/about" className="button-agiles text-capitalize text-white mt-sm-5 mt-4">read more</a>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li className="prev">
                        <div className="banner-w3ls-5">
                            <div className="banner-text-posi-w3ls">
                                <div className="banner-text-whtree"  >
                                    <h3 className="text-capitalize text-white p-4">Expanding Possibilities,
                                        <b>Of Better Tomorrow!!</b>
                                    </h3>
                                    <p className="px-4 py-3 text-dark">Develop IT Skills</p>

                                    <a href="/about" className="button-agiles text-capitalize text-white mt-sm-5 mt-4">read more</a>
                                </div>
                            </div>
                        </div>
                    </li>
                </ul>
                <ul className="pager">
                    <li data-index="0" className="active"></li>
                    <li data-index="1"></li>
                    <li data-index="2"></li>
                    <li data-index="3"></li>
                    <li data-index="4"></li>
                    <li data-index="5"></li>
                </ul>

                <ContactForm />
                
                        

             

                <Navbar isLoggedIn={isLoggedIn} isAdmin={isAdmin} onLogout={handleLogout} />


              
            </div>
        </Fragment>
    )
}

export default Menu;
