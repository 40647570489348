import React, { Fragment } from 'react'
import Project from '../components/Project'
import Courses from './Courses'


const Internship = () => {
  return (
    <Fragment>
      <div className="banner-agile-2">
        {/* <div className="navigation-w3ls">
					<nav className="navbar navbar-expand-lg navbar-light bg-light sticky-nav">
						<button className="navbar-toggler mx-auto" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
							aria-expanded="false" aria-label="Toggle navigation">
							<span className="navbar-toggler-icon"></span>
						</button>
						<div className="collapse navbar-collapse text-center" id="navbarSupportedContent">
							<ul className="navbar-nav justify-content-center">
								<li className="nav-item">
									<a className="nav-link text-white" href="/">Home
										<span className="sr-only">(current)</span>
									</a>
								</li>
								<li className="nav-item active">
									<a className="nav-link text-white" href="/about">About Us</a>
								</li>
								<li className="nav-item dropdown">
									<a className="nav-link dropdown-toggle text-white" href="#" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
										Courses
									</a>
									<div className="dropdown-menu">
										<a className="dropdown-item" href="language.html">Language</a>
										<a className="dropdown-item" href="communication.html">Communication</a>
										<a className="dropdown-item" href="business.html">Business</a>
										<a className="dropdown-item" href="software.html">Software</a>
										<a className="dropdown-item" href="social_media.html">Social Media</a>
										<a className="dropdown-item" href="photography.html">Photography</a>
										<a className="dropdown-item" href="course_details.html">Course Details</a>
										<div className="dropdown-divider"></div>
										<a className="dropdown-item" href="form.html">Apply Now</a>
									</div>
								</li>
								
								<li className="nav-item">
									<a className="nav-link text-white" href="/gallery">Gallery</a>
								</li>
								<li className="nav-item">
									<a className="nav-link text-white" href="/contactus">Contact Us</a>
								</li>
							</ul>
						</div>
					</nav>
				</div> */}
        <div className="navigation-w3ls">
          <nav className="navbar navbar-expand-lg navbar-light bg-light sticky-nav">
            <button className="navbar-toggler mx-auto" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
              aria-expanded="false" aria-label="Toggle navigation">
              <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse text-center" id="navbarSupportedContent">
              <ul className="navbar-nav justify-content-center">
                <li className="nav-item active">

                  <a className="nav-link text-white" href="/">Home
                    <span className="sr-only">(current)</span>
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link text-white" href="/about">About Us</a>
                </li>
                <li className="nav-item">
                  <a className="nav-link text-white" href="/allprogram">All Programs</a>
                </li>
                <li className="nav-item">
                  <a className="nav-link text-white" href="/Onlineprogram">Online Programs</a>
                </li>
                <li className="nav-item">
                  <a className="nav-link text-white" href="/internship">Internship</a>
                </li>
                <li className="nav-item">
                  <a className="nav-link text-white" href="/placement">Placement</a>
                </li>

                {/* <li className="nav-item dropdown">
                  <a className="nav-link dropdown-toggle text-white" href="#" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    Courses
                  </a>
                </li> */}

                {/* <li className="nav-item">
                  <a className="nav-link text-white" href="/courses">courses</a>
                </li> */}
                <li className="nav-item">
                  <a className="nav-link text-white" href="/contactus">Contactus</a>
                </li>


              </ul>
            </div>
          </nav>
        </div>
      </div>
      <nav aria-label="breadcrumb">
        <ol className="breadcrumb" style={{ fontSize: "14px", fontFamily:"'Open Sans', sans-serif",padding : (".5em",".5em"), backgroundColor: "#e9ecef"}}>
          <li className="breadcrumb-item">
            <a href="/">Home</a>
          </li>
          <li className="breadcrumb-item active" aria-current="page">Internship</li>
        </ol>
      </nav>


      {/* <div className="internship-page">
        <header>
          <h1>Internship Opportunities</h1>
        </header>
        <main>
          <section className="internship-card">
            <div className="internship-info">
              <h2>Software Developer Intern</h2>
              <p>
                We are looking for talented software developer interns to join our team and work on exciting projects.
              </p>
              <p>
                <strong>Requirements:</strong>
                <ul>
                  <li>Knowledge of HTML, CSS, and JavaScript.</li>
                  <li>Familiarity with React.js or other frontend frameworks.</li>
                  <li>Basic understanding of version control (e.g., Git).</li>
                </ul>
              </p>
              <button>Apply Now</button>
            </div>
          </section>
        </main>
        <footer>
          <p>&copy; {new Date().getFullYear()} Your Company</p>
        </footer>
      </div> */}
      {/* <div className="internship-page">
      <header>
        <h1>Internship Opportunities</h1>
      </header>
      <main>
        <section className="internship-card">
          <div className="internship-info">
            <h2>Software Developer Intern</h2>
            <p>
              We are looking for talented software developer interns to join our team and work on exciting projects.
            </p>
            <p>
              <strong>Requirements:</strong>
              <ul>
                <li>Knowledge of HTML, CSS, and JavaScript.</li>
                <li>Familiarity with React.js or other frontend frameworks.</li>
                <li>Basic understanding of version control (e.g., Git).</li>
              </ul>
            </p>
            <button>Apply Now</button>
          </div>
        </section>

        <section className="trending-courses">
          <h2>Trending Courses</h2>
          <div className="course-card">
            <img
              src="https://example.com/course1-thumbnail.jpg"
              alt="Course 1 Thumbnail"
            />
            <div className="course-info">
              <h3>Course 1: Introduction to Web Development</h3>
              <p>
                Learn the basics of web development with HTML, CSS, and JavaScript.
              </p>
              <p>
                <strong>Duration:</strong> 4 weeks
              </p>
            </div>
          </div>

          <div className="course-card">
            <img
              src="https://example.com/course2-thumbnail.jpg"
              alt="Course 2 Thumbnail"
            />
            <div className="course-info">
              <h3>Course 2: Advanced React and Redux</h3>
              <p>
                Master the concepts of React and Redux to build powerful web applications.
              </p>
              <p>
                <strong>Duration:</strong> 6 weeks
              </p>
            </div>
          </div>
        </section>
      </main>
      <footer>
        <p>&copy; {new Date().getFullYear()} Your Company</p>
      </footer>
    </div> */}

      <div class="breadcrumb-area" >
        <div class="container">
          <div class="row">
            <div class="col-12">
              <div class="breadcrumb_box text-center">
                <h1 class="breadcrumb-title" style={{ color: "#fff" }}></h1>
                {/* <ul class="breadcrumb-list">
                  <li class="breadcrumb-item" style={{ color: "#fff" }}><a href="https://itdeskindia.com">Home</a></li>
                  <li class="breadcrumb-item active" style={{ color: "#fff" }}>Internships</li>
                </ul> */}
              </div>
            </div>
          </div>
        </div>
      </div>


    
    {/* <div className="text-center mx-auto mb-5 wow fadeInUp" data-wow-delay="0.1s" style={{ maxwidth: "600px" }}>
                        <h1 className="section-title bg-white text-center text-primary px-3">Internship</h1>
                        <h2 className="display-6 mb-4">Learn More About Our Internship Projects</h2>

                    </div> */}
                    <h3 className="title text-capitalize font-weight-light text-dark text-center mb-5"> INTERNSHIP
                        <span className="font-weight-bold"> Courses</span>
                    </h3>



      <div id="main-wrapper">
        <div class="site-wrapper-reveal">

          <div class="blog-pages-wrapper section-space--ptb_100">
            <div class="container">
              <div class="row">
              <div class="col-lg-3 col-md-6  mb-30 wow move-up">

<div class="single-blog-item blog-grid">

  <div class="post-feature blog-thumbnail">
    <a href="/onlineprogram">
      <img class="img-fluid" src="assests/images/javai.webp" alt="PHP" />
    </a>
  </div>

  <div class="post-info lg-blog-post-info">
    <h2 class="post-title font-weight--bold">
      <br/>
      <a href="/onlineprogram">Advance Java(J2EE)</a>
    </h2>
    <div class="post-excerpt mt-15">

      <p >Most popular to develop e-commerce websites and ea..</p>
    </div>
    <div class="btn-text">
      <a href="/onlineprogram"><h2>Read more...</h2> <i class="ml-1 button-icon far fa-long-arrow-right"></i></a>
    </div>
  </div>

</div>


</div>


                {/* <div class="col-lg-3 col-md-6  mb-30 wow move-up">

                  <div class="single-blog-item blog-grid">

                    <div class="post-feature blog-thumbnail">
                      <a href="/onlineprogram">
                        <img class="img-fluid" src="assests/images/javai.webp" alt="Advance Java(J2EE)" />
                      </a>
                    </div>

                    <div class="post-info lg-blog-post-info">
                      <h2 class="post-title font-weight--bold">
                        <a href="/onlineprogram">Advance Java(J2EE)</a>
                      </h2>
                      <div class="post-excerpt mt-15">

                        <p>To design and develop web-based, network-centric o..</p>
                      </div>
                      <div class="btn-text">
                        <a href="/onlineprogram">Read more... <i class="ml-1 button-icon far fa-long-arrow-right"></i></a>
                      </div>
                    </div>

                  </div>


                </div> */}


                <div class="col-lg-3 col-md-6  mb-30 wow move-up">

                  <div class="single-blog-item blog-grid">

                    <div class="post-feature blog-thumbnail">
                      <a href="/onlineprogram">
                        <img class="img-fluid" src="assests/images/php.jpg" alt="PHP" />
                      </a>
                    </div>

                    <div class="post-info lg-blog-post-info">
                      <h2 class="post-title font-weight--bold">
                        <br/>
                        <a href="/onlineprogram">PHP</a>
                      </h2>
                      <div class="post-excerpt mt-15">

                        <p>Most popular to develop e-commerce websites and ea..</p>
                      </div>
                      <div class="btn-text">
                        <a href="/onlineprogram"><h2>Read more...</h2> <i class="ml-1 button-icon far fa-long-arrow-right"></i></a>
                      </div>
                    </div>

                  </div>


                </div>


                <div class="col-lg-3 col-md-6  mb-30 wow move-up">

                  <div class="single-blog-item blog-grid">

                    <div class="post-feature blog-thumbnail">
                      <a href="/onlineprogram">
                        <img class="img-fluid" src="assests/images/c++.jpg" alt="C/C++" />
                      </a>
                    </div>

                    <div class="post-info lg-blog-post-info">
                      <h2 class="post-title font-weight--bold">
                        <br/>
                        <br/>
                        <a href="/onlineprogram">C/C++</a>
                      </h2>
                      <div class="post-excerpt mt-15">

                        <p>C and C++ both are powerful languages to develop p..</p>
                      </div>
                      <div class="btn-text">
                        <a href="/onlineprogram"><h2>Read more...</h2> <i class="ml-1 button-icon far fa-long-arrow-right" ></i></a>
                      </div>
                    </div>

                  </div>


                </div>
                <div class="col-lg-3 col-md-6  mb-30 wow move-up">

                  <div class="single-blog-item blog-grid">

                    <div class="post-feature blog-thumbnail">
                      <a href="/onlineprogram">
                        <img class="img-fluid" src="assests/images/i3.jpg" alt="Python Programming" />
                      </a>
                    </div>

                    <div class="post-info lg-blog-post-info">
                      <h2 class="post-title font-weight--bold">
                        <br/>
                        <a href="/onlineprogram">Python Programming</a>
                      </h2>
                      <div class="post-excerpt mt-15">

                        <p>Simple, clean syntax, object encapsulation, good l..</p>
                      </div>
                      <div class="btn-text">
                        <a href="/onlineprogram"><h2>Read more...</h2><i class="ml-1 button-icon far fa-long-arrow-right"></i></a>
                      </div>
                    </div>

                  </div>


                </div>

                


                <div class="col-lg-3 col-md-6  mb-30 wow move-up">

                  <div class="single-blog-item blog-grid">

                    <div class="post-feature blog-thumbnail">
                      <a href="/onlineprogram">
                        <img class="img-fluid" src="assests/images/images/digital-marketin1.webp" alt="Digital Marketing" />
                      </a>
                    </div>

                    <div class="post-info lg-blog-post-info">
                      <h2 class="post-title font-weight--bold">
                        <br/>
                        <a href="/onlineprogram">Digital Marketing</a>
                      </h2>
                      <div class="post-excerpt mt-15">

                        <p>A most powerful tool to grow business and increase..</p>
                      </div>
                      <div class="btn-text">
                        <a href="/onlineprogram"><h2>Read more...</h2> <i class="ml-1 button-icon far fa-long-arrow-right"></i></a>
                      </div>
                    </div>

                  </div>


                </div>


                <div class="col-lg-3 col-md-6  mb-30 wow move-up">

                  <div class="single-blog-item blog-grid">

                    <div class="post-feature blog-thumbnail">
                      <a href="/onlineprogram">
                        <img class="img-fluid" src="assests/images/javai.webp" alt="Core Java Internship" />
                      </a>
                    </div>

                    <div class="post-info lg-blog-post-info">
                      <h2 class="post-title font-weight--bold">
                      <br/>
                        <a href="/onlineprogram">Core Java Internship</a>
                      </h2>
                      <div class="post-excerpt mt-15">

                        <p>Most popular in Console Application, Desktop Appli..</p>
                      </div>
                      <div class="btn-text">
                        <a href="/onlineprogram"><h2>Read more...</h2> <i class="ml-1 button-icon far fa-long-arrow-right"></i></a>
                      </div>
                    </div>

                  </div>


                </div>


                <div class="col-lg-3 col-md-6  mb-30 wow move-up">

                  <div class="single-blog-item blog-grid">

                    <div class="post-feature blog-thumbnail">
                      <a href="/onlineprogram">
                        <img class="img-fluid" src="assests/images/images/webdesign1.png" alt="Web Design Internship" />
                      </a>
                    </div>

                    <div class="post-info lg-blog-post-info">
                      <h2 class="post-title font-weight--bold">
                        <br/>
                        <a href="/onlineprogram">Web Design Internship</a>
                      </h2>
                      <div class="post-excerpt mt-15">

                        <p>Most popular in Websites Designing (static website..)</p>
                      </div>
                      <div class="btn-text">
                        <a href="/onlineprogram"><h2>Read more...</h2> <i class="ml-1 button-icon far fa-long-arrow-right"></i></a>
                      </div>
                    </div>

                  </div>


                </div>

                <div class="col-lg-3 col-md-6  mb-30 wow move-up">

                  <div class="single-blog-item blog-grid">

                    <div class="post-feature blog-thumbnail">
                      <a href="/onlineprogram">
                        <img class="img-fluid" src="assests/images/cloud 2.jpg" alt="Core Java Internship" />
                      </a>
                    </div>

                    <div class="post-info lg-blog-post-info">
                      <h2 class="post-title font-weight--bold">
                      <br/>
                        <a href="/onlineprogram"> Cloud Computing</a>
                      </h2>
                      <div class="post-excerpt mt-15">

                        <p>Most popular in Console Application, Desktop Appli..</p>
                      </div>
                      <div class="btn-text">
                        <a href="/onlineprogram"><h2>Read more.....</h2> <i class="ml-1 button-icon far fa-long-arrow-right"></i></a>
                      </div>
                    </div>

                  </div>


                </div>


              </div>
            </div>
          </div>

          <div class="cta-image-area_one section-space--ptb_80 cta-bg-image_one">
            <div class="container">
              <div class="row align-items-center">
                <div class="col-xl-8 col-lg-7">
                  <div class="cta-content md-text-center">
                    <h3 class="heading text-white">10,000 Students Already Registred <span class="text-color-secondary">In Internships Course</span></h3>
                  </div>
                </div>
                <div class="col-xl-4 col-lg-5">
                  <div class="cta-button-group--one text-center">
                    <a href="./" class="btn btn--white btn-one quick-option"><span class="btn-icon mr-2"><i class="far fa-comment-alt-dots"></i></span></a>

                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="projects-wrapper projectinfotechno-bg section-space--ptb_100">
            <div class="container">

              {/* <div class="row">
                <div class="col-lg-12">

                  <div class="section-title-wrap text-center section-space--mb_40">

                    <h3 class="heading">More <span class="text-color-primary"> Course Categories</span></h3>
                  </div>

                </div>
              </div> */}

              {/* <div class="row">
                <div class="col-12">
                  <div class="projects-wrap swiper-container projects-slider__container swiper-container-initialized swiper-container-horizontal">
                    <div class="swiper-wrapper" style={{ transform: "translate3d(-1170px,0px,0px); transition-duration:0ms" }}>
                      <div class="swiper-slide swiper-slide-duplicate-prev" data-swiper-slide-index="3" style={{ width: "390px" }}>
                        
                        <a href="https://itdeskindia.com/categories/trending-courses/" class="projects-wrap style-01 wow move-up animated" style={{ visibility: "visible" }}>
                          <div class="projects-image-box">
                            <div class="projects-image" >
                              <img class="img-fluid" src="assests/images/images111.png" alt="Trending Courses" />
                            </div>
                            <div class="content">
                              <h6 class="heading">Trending Courses</h6>
                            
                              <div class="text"></div>
                              <div class="box-projects-arrow">
                                <span class="button-text">View More</span>
                                <i class="fa fa-long-arrow-right ml-1"></i>
                              </div>
                            </div>
                          </div>
                        </a>

                      </div>
                      <div class="swiper-slide swiper-slide-duplicate-prev" data-swiper-slide-index="3" style={{ width: "390px" }}>

                        <a href="https://itdeskindia.com/categories/cs-it/" class="projects-wrap style-01 wow move-up animated" style={{ visibility: "visible" }}>
                          <div class="projects-image-box">
                            <div class="projects-image">
                              <img class="img-fluid" src="assests/images/download11.jpg" alt="CS/IT" />
                            </div>
                            <div class="content">
                              <h6 class="heading">CS/IT</h6>
                          
                              <div class="text"></div>
                              <div class="box-projects-arrow">
                                <span class="button-text">View More</span>
                                <i class="fa fa-long-arrow-right ml-1"></i>
                              </div>
                            </div>
                          </div>
                        </a>

                      </div>
                    </div>
                    <div class="swiper-pagination swiper-pagination-project mt_20 swiper-pagination-clickable swiper-pagination-bullets"><span class="swiper-pagination-bullet swiper-pagination-bullet-active" tabindex="0" role="button" aria-label="Go to slide 1"></span><span class="swiper-pagination-bullet" tabindex="0" role="button" aria-label="Go to slide 2"></span><span class="swiper-pagination-bullet" tabindex="0" role="button" aria-label="Go to slide 3"></span><span class="swiper-pagination-bullet" tabindex="0" role="button" aria-label="Go to slide 4"></span></div>
                    <span class="swiper-notification" aria-live="assertive" aria-atomic="true"></span></div>



                </div>
              </div> */}
            </div>
          </div>
        </div>
      </div>

      <Project/>

    </Fragment >
  )
}

export default Internship
