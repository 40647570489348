import React, { useState, useEffect } from 'react';
import '../index.css';

const ScrollButton = () => {
    const [showButton, setShowButton] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            if (window.pageYOffset > 300) {
                setShowButton(true);
            } else {
                setShowButton(false);
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    return (
        <div className={`scroll-up-button ${showButton ? 'show' : 'hide'}`} onClick={scrollToTop}>
            {showButton && <i className="fas fa-arrow-up"></i>}
        </div>
    );
};

export default ScrollButton;
