import React from 'react'

import CertificateGenerator from '../components/CertificateGenerator'
import Certificate1  from '../components/Certificate1'



const Certificate = () => {
  return (
    <div>
     <Certificate1 />
        <CertificateGenerator />
        {/* <Dashbord1 /> */}

        
       
    </div>
  )
}

export default Certificate
