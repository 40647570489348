import React, { Fragment } from 'react'

const Project = () => {
    return (
        
        <Fragment>

            <div className="container-xxl py-5">
                <div className="container">
                    
                    <div className="text-center mx-auto mb-5 wow fadeInUp" data-wow-delay="0.1s" style={{ maxwidth: "600px" }}>
                    <h3 className="title text-capitalize font-weight-light text-dark text-center mb-5"> Our 
                        <span className="font-weight-bold"> Projects</span>
                    </h3>
                        <h1 className="section-title bg-white text-center text-primary px-3"></h1>
                        <h1  className="title text-capitalize font-weight-light text-dark text-center mb-5">Learn More About Our Complete Projects</h1>
                        

                    </div>





                    <div className="owl-carousel project-carousel wow fadeInUp owl-loaded owl-drag" data-wow-delay="0.1s" style={{ "visibility": "visible", "animationDelay": "0.1s", "animationName": "fadeInUp" }}>
                        <div class="owl-stage-outer">
                            <div class="owl-stage" style={{ transform: "translate3d(-1711px, 0px, 0px)", transition: "all 1s ease 0s", width: "5705px" }}>

                                <div class="owl-item cloned" style={{ width: " 260.25px", marginRight: "25px" }}>
                                    <div class="project-item border rounded h-100 p-4" data-dot="01">

                                        {/* <div className="project-item border rounded h-100 p-4" data-dot="01"> */}

                                        <div className="position-relative mb-4">
                                            <img className="img-fluid rounded" src="assests/img/project-1.jpg" alt="" />
                                            <a href="assests/img/project-1.jpg" data-lightbox="project"><i className="fa fa-eye fa-2x"></i></a>
                                        </div>
                                        <h6>UI / UX Des</h6>
                                        <span>Digital agency website design and development</span>
                                    </div>
                                </div>

                                <div class="owl-item cloned" style={{ width: "260.25px", marginRight: "25px" }}>
                                    <div className="project-item border rounded h-100 p-4" data-dot="02">
                                        <div className="position-relative mb-4">
                                            <img className="img-fluid rounded" src="assests/img/project-2.jpg" alt="" />
                                            <a href="assests/img/project-2.jpg" data-lightbox="project"><i className="fa fa-eye fa-2x"></i></a>
                                        </div>
                                        <h6>UI / UX Desn</h6>
                                        <span>Digital agency website design and development</span>
                                    </div>
                                </div>

                                <div class="owl-item cloned" style={{ width: "260.25px", marginRight: "25px" }}>

                                    <div className="project-item border rounded h-100 p-4" data-dot="03">

                                        <div className="position-relative mb-4">
                                            <img className="img-fluid rounded" src="assests/img/project-3.jpg" alt="" />
                                            <a href="assests/img/project-2.jpg" data-lightbox="project"><i className="fa fa-eye fa-2x"></i></a>
                                        </div>
                                        <h6>UI / UX Den</h6>
                                        <span>Digital agency website design and development</span>
                                    </div>
                                </div>

                                <div class="owl-item cloned" style={{ width: "260.25px", marginRight: "25px" }}>
                                    <div className="project-item border rounded h-100 p-4" data-dot="04">
                                        <div className="position-relative mb-4">
                                            <img className="img-fluid rounded" src="assests/img/project-4.jpg" alt="" />
                                            <a href="assests/img/project-4.jpg" data-lightbox="project"><i className="fa fa-eye fa-2x"></i></a>
                                        </div>
                                        <h6>UI /Design</h6>
                                        <span>Digital agency website design and development</span>
                                    </div>
                                </div>

                                <div class="owl-item cloned" style={{ width: "260.25px", marginRight: "25px" }}>
                                    <div className="project-item border rounded h-100 p-4" data-dot="05">

                                        <div className="position-relative mb-4">
                                            <img className="img-fluid rounded" src="assests/img/project-5.jpg" alt="" />
                                            <a href="assests/img/project-5.jpg" data-lightbox="project"><i className="fa fa-eye fa-2x"></i></a>
                                        </div>
                                        <h6>UI /Design</h6>
                                        <span>Digital agency website design and development</span>
                                    </div>
                                </div>

                                <div class="owl-item cloned" style={{ width: "260.25px", marginRight: "25px" }}>

                                    <div className="project-item border rounded h-100 p-4" data-dot="06">
                                        <div className="position-relative mb-4">
                                            <img className="img-fluid rounded" src="assests/img/project-6.jpg" alt="" />
                                            <a href="assests/img/project-6.jpg" data-lightbox="project"><i className="fa fa-eye fa-2x"></i></a>
                                        </div>
                                        <h6>UI / UX Design</h6>
                                        <span>Digital agency website design and development</span>
                                    </div>
                                </div>




                                {/* style={{ width: "260.25px", marginRight: "25px" }} */}
                                <div className='responsive_mobile_our_project_container'>
                                <div class="owl-item responsive_mobile_project_card_container" style={{ width: "260.25px", marginRight: "25px" }}>
                                    <div className="project-item border rounded h-100 p-4" data-dot="07" >
                                        <div className="position-relative mb-4">
                                            <img className="img-fluid rounded" src="assests/images/image4.jpg" alt="" />
                                            <a href="assests/images/images121.jpg" data-lightbox="project"><i className="fa fa-eye fa-2x"></i></a>
                                        </div>
                                        <h2>Cloud Based CHATBOT</h2>
                                        <span>A chatbot is a computer program that simulates conversation with human end users,</span>
                                    </div >
                                </div>

                                <div class="owl-item responsive_mobile_project_card_container" style={{ width: "260.25px", marginRight: "25px" }}>
                                    <div className="project-item border rounded h-100 p-4" data-dot="08">
                                        <div className="position-relative mb-4">
                                            <img className="img-fluid rounded" src="assests/images/image1.jpg" alt="" />
                                            <a href="assests/images/image1.jpg" data-lightbox="project"><i className="fa fa-eye fa-2x"></i></a>
                                        </div>
                                        <h2>Online Blood Bank System</h2>
                                        <span>he major goal of the blood bank management system is to keep track of blood, donors</span>
                                    </div>
                                </div>

                                <div class="owl-item responsive_mobile_project_card_container" style={{ width: "260.25px", marginRight: "25px" }}>
                                    <div className="project-item border rounded h-100 p-4" data-dot="09">
                                        <div className="position-relative mb-4">
                                            <img className="img-fluid rounded" src="assests/images/image2.jpg" alt="" />
                                            <a href="assests/images/image2.jpg" data-lightbox="project"><i className="fa fa-eye fa-2x"></i></a>
                                        </div>
                                        <h2>Airline Reservation System</h2>
                                        <span>Airline reservation systems (ARS) are systems that allow an airline to sell their inventory (seats).</span>
                                    </div>
                                </div >

                                <div class="owl-item active responsive_mobile_project_card_container" style={{ width: "260.25px", marginRight: "25px" }}>
                                    <div className="project-item border rounded h-100 p-4" data-dot="10">
                                        <div className="position-relative mb-4">
                                            <img className="img-fluid rounded" src="assests/images/image3.jpg" alt="" />
                                            <a href="assests/images/image3.jpg" data-lightbox="project"><i className="fa fa-eye fa-2x"></i></a>
                                        </div>
                                        <h2>Rural Banking</h2>
                                        <span> Rural Banking, we mean reaching the banking services to individuals residing in rural areas of the country.</span>
                                    </div>
                                </div >


                                {/* <div class="owl-item" style={{ width: "260.25px", marginRight: "25px" }}>
                                    <div class="project-item border rounded h-100 p-4" data-dot="03">
                                        <div class="position-relative mb-4">
                                            <img class="img-fluid rounded" src="assests/images/image4.jpg" alt="" />
                                            <a href="assests/images/image4.jpg" data-lightbox="project"><i class="fa fa-eye fa-2x"></i></a>
                                        </div>
                                        <h6>User Athentication system</h6>
                                        <span>Digital agency website design and development</span>
                                    </div>
                                </div> */}
{/* 
                                <div class="owl-item cloned" style={{ width: "260.25px", marginRight: "25px" }}>
                                    <div class="project-item border rounded h-100 p-4" data-dot="04">
                                        <div class="position-relative mb-4">
                                            <img class="img-fluid rounded" src="assests/img/project-5.jpg" alt="" />
                                            <a href="assests/img/project-5.jpg" data-lightbox="project"><i class="fa fa-eye fa-2x"></i></a>
                                        </div>
                                        <h6>UI / UX</h6>
                                        <span>Digital agency website design and development</span>

                                    </div>
                                </div> */}

                                {/* <div class="owl-item cloned" style={{ width: "260.25px", marginRight: "25px" }}>
                                    <div class="project-item border rounded h-100 p-4" data-dot="05">
                                        <div class="position-relative mb-4">
                                            <img class="img-fluid rounded" src="assests/img/project-5.jpg" alt="" />
                                            <a href="assests/img/project-5.jpg" data-lightbox="project"><i class="fa fa-eye fa-2x"></i></a>
                                        </div>
                                        <h6>UI / UX Design</h6>
                                        <span>Digital agency website design and development</span>

                                    </div>
                                </div> */}




                                 </div>
                            </div >
                        </div >



                       
                    </div>
                </div>
            </div>


            <div className="container-xxl py-5">
                <div className="container">
            

                    <div className="owl-carousel project-carousel wow fadeInUp owl-loaded owl-drag" data-wow-delay="0.1s" style={{ "visibility": "visible", "animationDelay": "0.1s", "animationName": "fadeInUp" }}>
                        <div class="owl-stage-outer">
                            <div class="owl-stage" style={{ transform: "translate3d(-1711px, 0px, 0px)", transition: "all 1s ease 0s", width: "5705px" }}>



                                <div class="owl-item cloned" style={{ width: " 260.25px", marginRight: "25px" }}>
                                    <div class="project-item border rounded h-100 p-4" data-dot="01">

                                        {/* <div className="project-item border rounded h-100 p-4" data-dot="01"> */}

                                        <div className="position-relative mb-4">
                                            <img className="img-fluid rounded" src="assests/img/project-1.jpg" alt="" />
                                            <a href="assests/img/project-1.jpg" data-lightbox="project"><i className="fa fa-eye fa-2x"></i></a>
                                        </div>
                                        <h6>UI / UX Design</h6>
                                        <span>Digital agency website design and development</span>
                                    </div>
                                </div>



                                <div class="owl-item cloned" style={{ width: "260.25px", marginRight: "25px" }}>
                                    <div className="project-item border rounded h-100 p-4" data-dot="02">
                                        <div className="position-relative mb-4">
                                            <img className="img-fluid rounded" src="assests/img/project-2.jpg" alt="" />
                                            <a href="assests/img/project-2.jpg" data-lightbox="project"><i className="fa fa-eye fa-2x"></i></a>
                                        </div>
                                        <h6>UI / UX Design</h6>
                                        <span>Digital agency website design and development</span>
                                    </div>
                                </div>


                                <div class="owl-item cloned" style={{ width: "260.25px", marginRight: "25px" }}>

                                    <div className="project-item border rounded h-100 p-4" data-dot="03">

                                        <div className="position-relative mb-4">
                                            <img className="img-fluid rounded" src="assests/img/project-3.jpg" alt="" />
                                            <a href="assests/img/project-2.jpg" data-lightbox="project"><i className="fa fa-eye fa-2x"></i></a>
                                        </div>
                                        <h6>UI / UX Design</h6>
                                        <span>Digital agency website design and development</span>
                                    </div>
                                </div>

                                <div class="owl-item cloned" style={{ width: "260.25px", marginRight: "25px" }}>
                                    <div className="project-item border rounded h-100 p-4" data-dot="04">
                                        <div className="position-relative mb-4">
                                            <img className="img-fluid rounded" src="assests/img/project-4.jpg" alt="" />
                                            <a href="assests/img/project-4.jpg" data-lightbox="project"><i className="fa fa-eye fa-2x"></i></a>
                                        </div>
                                        <h6>UI / UX Design</h6>
                                        <span>Digital agency website design and development</span>
                                    </div>
                                </div>



                                <div class="owl-item cloned" style={{ width: "260.25px", marginRight: "25px" }}>
                                    <div className="project-item border rounded h-100 p-4" data-dot="05">

                                        <div className="position-relative mb-4">
                                            <img className="img-fluid rounded" src="assests/img/project-5.jpg" alt="" />
                                            <a href="assests/img/project-5.jpg" data-lightbox="project"><i className="fa fa-eye fa-2x"></i></a>
                                        </div>
                                        <h6>UI / UX Design</h6>
                                        <span>Digital agency website design and development</span>
                                    </div>
                                </div>






                                <div class="owl-item cloned" style={{ width: "260.25px", marginRight: "25px" }}>

                                    <div className="project-item border rounded h-100 p-4" data-dot="06">
                                        <div className="position-relative mb-4">
                                            <img className="img-fluid rounded" src="assests/images/image5.jpg" alt="" />
                                            <a href="assests\images\image5.jpg" data-lightbox="project"><i className="fa fa-eye fa-2x"></i></a>
                                        </div>
                                        <h2>Card enceypt and decrypt</h2>
                                        <span>Digital agency website design and development</span>
                                    </div>
                                </div>




                                <div className='responsive_mobile_our_project_container'>
                                <div class="owl-item responsive_mobile_project_card_container" style={{ width: "260.25px", marginRight: "25px" }}>
                                    <div className="project-item border rounded h-100 p-4" data-dot="07" >
                                        <div className="position-relative mb-4">
                                            <img className="img-fluid rounded" src="assests/images/images11.jfif" alt="" />
                                            <a href="assests/images/images11.jpg" data-lightbox="project"><i className="fa fa-eye fa-2x"></i></a>
                                        </div>
                                        <h2>Keylogger Software</h2>
                                        <span>Digital agency website design and development</span>
                                    </div >
                                </div>

                                <div class="owl-item responsive_mobile_project_card_container" style={{ width: "260.25px", marginRight: "25px" }}>
                                    <div className="project-item border rounded h-100 p-4" data-dot="08">
                                        <div className="position-relative mb-4">
                                            <img className="img-fluid rounded" src="assests/images/images6.jpg" alt="" />
                                            <a href="assests/images/images6.jpg" data-lightbox="project"><i className="fa fa-eye fa-2x"></i></a>
                                        </div>
                                        <h2>Graphical Password user</h2>
                                        <span>Digital agency website design and development</span>
                                    </div>
                                </div>



                                <div class="owl-item responsive_mobile_project_card_container" style={{ width: " 260.25px", marginRight: "25px" }}>
                                    <div className="project-item border rounded h-100 p-4" data-dot="09">
                                        <div className="position-relative mb-4">
                                            <img className="img-fluid rounded" src="assests/images/images77.jpg" alt="" />
                                            <a href="assests/images/images77.jpg" data-lightbox="project"><i className="fa fa-eye fa-2x"></i></a>
                                        </div>
                                        <h2>Security scanner</h2>
                                        <span>Scanner is a scan tool designed to find and remove malware from Windows computers</span>
                                    </div>
                                </div >


                                <div class="owl-item active responsive_mobile_project_card_container" style={{ width: " 260.25px", marginRight: "25px" }}>
                                    <div className="project-item border rounded h-100 p-4" data-dot="10">
                                        <div className="position-relative mb-4">
                                            <img className="img-fluid rounded" src="assests/images/int1.jpeg" alt="" />
                                            <a href="assests/images/java3.jpg" data-lightbox="project"><i className="fa fa-eye fa-2x"></i></a>
                                        </div>
                                        <h2>Cyber Security</h2>
                                        <span>Text Encryption Using Cryptographic Algorithms</span>
                                    </div>
                                </div >


                                {/* <div class="owl-item" style={{ width: "260.25px", marginRight: "25px" }}>
                                    <div class="project-item border rounded h-100 p-4" data-dot="03">
                                        <div class="position-relative mb-4">
                                            <img class="img-fluid rounded" src="assests/img/project-3.jpg" alt="" />
                                            <a href="assests/img/project-8.jpg" data-lightbox="project"><i class="fa fa-eye fa-2x"></i></a>
                                        </div>
                                        <h6>UI / UX Design</h6>
                                        <span>Digital agency website design and development</span>
                                    </div>
                                </div>

                                <div class="owl-item cloned" style={{ width: "260.25px", marginRight: "25px" }}>
                                    <div class="project-item border rounded h-100 p-4" data-dot="04">
                                        <div class="position-relative mb-4">
                                            <img class="img-fluid rounded" src="assests/img/project-5.jpg" alt="" />
                                            <a href="assests/img/project-5.jpg" data-lightbox="project"><i class="fa fa-eye fa-2x"></i></a>
                                        </div>
                                        <h6>UI / UX Design</h6>
                                        <span>Digital agency website design and development</span>

                                    </div>
                                </div>

                                <div class="owl-item cloned" style={{ width: "260.25px", marginRight: "25px" }}>
                                    <div class="project-item border rounded h-100 p-4" data-dot="05">
                                        <div class="position-relative mb-4">
                                            <img class="img-fluid rounded" src="assests/img/project-5.jpg" alt="" />
                                            <a href="assests/img/project-5.jpg" data-lightbox="project"><i class="fa fa-eye fa-2x"></i></a>
                                        </div>
                                        <h6>Cyber Security</h6>
                                        <span>Text Encryption Using Cryptographic Algorithms</span>

                                    </div>
                                </div> */}



</div>

                            </div >
                        </div >





                    </div>
                </div>
            </div>






            {/* <div class="container-xxl py-5">
                <div class="container">
                    <div class="section-title text-center">
                        <h1 class="display-5 mb-5">Our Projects</h1>
                    </div>
                    <div class="row mt-n2 wow fadeInUp" data-wow-delay="0.3s">
                        <div class="col-12 text-center">
                            <ul class="list-inline mb-5" id="portfolio-flters">
                                <li class="mx-2 active" data-filter="*">All</li>
                                <li class="mx-2" data-filter=".first">General Carpentry</li>
                                <li class="mx-2" data-filter=".second">Custom Carpentry</li>
                            </ul>
                        </div>
                    </div>
                    <div class="row g-4 portfolio-container">
                        <div class="col-lg-4 col-md-6 portfolio-item first wow fadeInUp" data-wow-delay="0.1s">
                            <div class="rounded overflow-hidden">
                                <div class="position-relative overflow-hidden">
                                    <img class="img-fluid w-100" src="img/portfolio-1.jpg" alt="" />
                                    <div class="portfolio-overlay">
                                        <a class="btn btn-square btn-outline-light mx-1" href="img/portfolio-1.jpg" data-lightbox="portfolio"><i class="fa fa-eye"></i></a>
                                        <a class="btn btn-square btn-outline-light mx-1" href=""><i class="fa fa-link"></i></a>
                                    </div>
                                </div>
                                <div class="border border-5 border-light border-top-0 p-4">
                                    <p class="text-primary fw-medium mb-2">General Carpentry</p>
                                    <h5 class="lh-base mb-0">Wooden Furniture Manufacturing And Remodeling</h5>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6 portfolio-item second wow fadeInUp" data-wow-delay="0.3s">
                            <div class="rounded overflow-hidden">
                                <div class="position-relative overflow-hidden">
                                    <img class="img-fluid w-100" src="img/portfolio-2.jpg" alt="" />
                                    <div class="portfolio-overlay">
                                        <a class="btn btn-square btn-outline-light mx-1" href="img/portfolio-2.jpg" data-lightbox="portfolio"><i class="fa fa-eye"></i></a>
                                        <a class="btn btn-square btn-outline-light mx-1" href=""><i class="fa fa-link"></i></a>
                                    </div>
                                </div>
                                <div class="border border-5 border-light border-top-0 p-4">
                                    <p class="text-primary fw-medium mb-2">Custom Carpentry</p>
                                    <h5 class="lh-base mb-0">Wooden Furniture Manufacturing And Remodeling</h5>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6 portfolio-item first wow fadeInUp" data-wow-delay="0.5s">
                            <div class="rounded overflow-hidden">
                                <div class="position-relative overflow-hidden">
                                    <img class="img-fluid w-100" src="img/portfolio-3.jpg" alt="" />
                                    <div class="portfolio-overlay">
                                        <a class="btn btn-square btn-outline-light mx-1" href="img/portfolio-3.jpg" data-lightbox="portfolio"><i class="fa fa-eye"></i></a>
                                        <a class="btn btn-square btn-outline-light mx-1" href=""><i class="fa fa-link"></i></a>
                                    </div>
                                </div>
                                <div class="border border-5 border-light border-top-0 p-4">
                                    <p class="text-primary fw-medium mb-2">General Carpentry</p>
                                    <h5 class="lh-base mb-0">Wooden Furniture Manufacturing And Remodeling</h5>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6 portfolio-item second wow fadeInUp" data-wow-delay="0.1s">
                            <div class="rounded overflow-hidden">
                                <div class="position-relative overflow-hidden">
                                    <img class="img-fluid w-100" src="img/portfolio-4.jpg" alt="" />
                                    <div class="portfolio-overlay">
                                        <a class="btn btn-square btn-outline-light mx-1" href="img/portfolio-4.jpg" data-lightbox="portfolio"><i class="fa fa-eye"></i></a>
                                        <a class="btn btn-square btn-outline-light mx-1" href=""><i class="fa fa-link"></i></a>
                                    </div>
                                </div>
                                <div class="border border-5 border-light border-top-0 p-4">
                                    <p class="text-primary fw-medium mb-2">Custom Carpentry</p>
                                    <h5 class="lh-base mb-0">Wooden Furniture Manufacturing And Remodeling</h5>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6 portfolio-item first wow fadeInUp" data-wow-delay="0.3s">
                            <div class="rounded overflow-hidden">
                                <div class="position-relative overflow-hidden">
                                    <img class="img-fluid w-100" src="img/portfolio-5.jpg" alt="" />
                                    <div class="portfolio-overlay">
                                        <a class="btn btn-square btn-outline-light mx-1" href="img/portfolio-5.jpg" data-lightbox="portfolio"><i class="fa fa-eye"></i></a>
                                        <a class="btn btn-square btn-outline-light mx-1" href=""><i class="fa fa-link"></i></a>
                                    </div>
                                </div>
                                <div class="border border-5 border-light border-top-0 p-4">
                                    <p class="text-primary fw-medium mb-2">General Carpentry</p>
                                    <h5 class="lh-base mb-0">Wooden Furniture Manufacturing And Remodeling</h5>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6 portfolio-item second wow fadeInUp" data-wow-delay="0.5s">
                            <div class="rounded overflow-hidden">
                                <div class="position-relative overflow-hidden">
                                    <img class="img-fluid w-100" src="img/portfolio-6.jpg" alt="" />
                                    <div class="portfolio-overlay">
                                        <a class="btn btn-square btn-outline-light mx-1" href="img/portfolio-6.jpg" data-lightbox="portfolio"><i class="fa fa-eye"></i></a>
                                        <a class="btn btn-square btn-outline-light mx-1" href=""><i class="fa fa-link"></i></a>
                                    </div>
                                </div>
                                <div class="border border-5 border-light border-top-0 p-4">
                                    <p class="text-primary fw-medium mb-2">Custom Carpentry</p>
                                    <h5 class="lh-base mb-0">Wooden Furniture Manufacturing And Remodeling</h5>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}


        </Fragment>
    )
}

export default Project