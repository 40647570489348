import React, { useEffect, useState } from 'react';
import TestimonialsDetails from './TestimonialsDetails'
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import userPic from '../../images/user-one.png';
import image1 from '../../images/harsha.png';
import image2 from '../../images/bhojaraj.png';
import image3 from '../../images/ektha.png';
import image4 from '../../images/rajath.png';

import './Testimonials.css'

const TestiMonials = () => {
  
    const testiMonials = [
        {
            name: 'Harsha Vardhan',
            description: 'Good IT institute for beginners or for experienced candidates who are working in IT job and wants to upgrade their skills. Good faculties with knowledgeable and good communication to make students understand from the basics.',
            address: 'Bangalore',
            img: image1
        },
        {
            name: 'Bhojaraju Challa',
            description: 'Nice Atmosphere to learn and Develop the IT skills Good Teaching staff very friendly .',
            address: 'Andhra',
            img: image2
        },
        {
            name: 'Ektha A Mehta',
            description: 'I personally liked the content because they have enough content to become a python developer and the way they teach is very excellent with realtime examples they are available at any time to clarify our doubts, in this low fees structure they are giving very good teaching services and I loved it.',
            address: 'Pune',
            img: image3
        },
        {
            name: 'Rajath',
            description: 'Best Centre to learn professionally and the training skills were excellent, Good teaching by the staff. Counseling Team is very Helpful for choosing the right software. Thanks for great guidance',
            address: 'Bangalore',
            img: image4
        },
    ]
    //Owl Carousel Settings
    const options = {
        loop: true,
        center: true,
        items: 3,
        margin: 0,
        autoplay: true,
        dots: true,
        autoplayTimeout: 8500,
        smartSpeed: 450,
        nav: false,
        responsive: {
            0: {
                items: 1
            },
            600: {
                items: 3
            },
            1000: {
                items: 3
            }
        }
    };

        // <section id="testimonial" className="testimonials pt-70 pb-70">
        //     <div className="container mt-5">
        //         <h4 className="miniTitle text-center">TESTIMONIALS</h4>
        //          <div className="text-center ">
        //            <h3 className="sectionTitle">What Our Clients are Saying?</h3>
        //         </div>
        //      <p className="text-center ">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna.</p>
               
        //          <div className="row">
        //             <div className="col-md-12">
        //                 <OwlCarousel id="customer-testimonoals" className="owl-carousel owl-theme" {...options}>
        //                     {
        //                         testiMonials.length === 0 ?
        //                             <div class="item">
        //                                 <div class="shadow-effect">
        //                                     <img class="img-circle" src={userPic} alt="" />

        //                                     <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna.</p>
        //                                 </div>
        //                                 <div class="testimonial-name">
        //                                     <h5>Rajon Rony</h5>
        //                                     <small>ITALY</small>
        //                                 </div>
        //                             </div> :
        //                             testiMonials.map(testiMonialDetail => {
        //                                 return (
        //                                     <TestimonialsDetails testiMonialDetail={testiMonialDetail} key={testiMonialDetail._key} />

        //                                 )
        //                             })
        //                     }
        //                 </OwlCarousel>
        //             </div>
        //         </div>
        //     </div>
        //                 </section> 

    return (
        <>
         <OwlCarousel id="customer-testimonoals" className="owl-carousel owl-theme" {...options}>
                            {
                                testiMonials.length === 0 ?
                                    <div class="item">
                                        <div class="shadow-effect">
                                            <img class="img-circle" src={userPic} alt="" />

                                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna.</p>
                                        </div>
                                        <div class="testimonial-name">
                                            <h5>Rajon Rony</h5>
                                            <small>ITALY</small>
                                        </div>
                                    </div> :
                                    testiMonials.map(testiMonialDetail => {
                                        return (
                                            <TestimonialsDetails testiMonialDetail={testiMonialDetail} key={testiMonialDetail._key} />

                                        )
                                    })
                            }
                        </OwlCarousel>
                        </>
   
    );
};

export default TestiMonials;