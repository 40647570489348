import React, { Fragment } from 'react'
import TrendingCourses from './TrendingCourses'

const Onlineprogram = () => {
  return (
    <Fragment>
      <div className="banner-agile-2">
        {/* <div className="navigation-w3ls">
					<nav className="navbar navbar-expand-lg navbar-light bg-light sticky-nav">
						<button className="navbar-toggler mx-auto" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
							aria-expanded="false" aria-label="Toggle navigation">
							<span className="navbar-toggler-icon"></span>
						</button>
						<div className="collapse navbar-collapse text-center" id="navbarSupportedContent">
							<ul className="navbar-nav justify-content-center">
								<li className="nav-item">
									<a className="nav-link text-white" href="/">Home
										<span className="sr-only">(current)</span>
									</a>
								</li>
								<li className="nav-item active">
									<a className="nav-link text-white" href="/about">About Us</a>
								</li>
								<li className="nav-item dropdown">
									<a className="nav-link dropdown-toggle text-white" href="#" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
										Courses
									</a>
									<div className="dropdown-menu">
										<a className="dropdown-item" href="language.html">Language</a>
										<a className="dropdown-item" href="communication.html">Communication</a>
										<a className="dropdown-item" href="business.html">Business</a>
										<a className="dropdown-item" href="software.html">Software</a>
										<a className="dropdown-item" href="social_media.html">Social Media</a>
										<a className="dropdown-item" href="photography.html">Photography</a>
										<a className="dropdown-item" href="course_details.html">Course Details</a>
										<div className="dropdown-divider"></div>
										<a className="dropdown-item" href="form.html">Apply Now</a>
									</div>
								</li>
								
								<li className="nav-item">
									<a className="nav-link text-white" href="/gallery">Gallery</a>
								</li>
								<li className="nav-item">
									<a className="nav-link text-white" href="/contactus">Contact Us</a>
								</li>
							</ul>
						</div>
					</nav>
				</div> */}
        <div className="navigation-w3ls">
          <nav className="navbar navbar-expand-lg navbar-light bg-light sticky-nav">
            <button className="navbar-toggler mx-auto" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
              aria-expanded="false" aria-label="Toggle navigation">
              <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse text-center" id="navbarSupportedContent">
              <ul className="navbar-nav justify-content-center">
                <li className="nav-item active">

                  <a className="nav-link text-white" href="/">Home
                    <span className="sr-only">(current)</span>
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link text-white" href="/about">About Us</a>
                </li>
                <li className="nav-item">
                  <a className="nav-link text-white" href="/allprogram">All Programs</a>
                </li>
                <li className="nav-item">
                  <a className="nav-link text-white" href="/Onlineprogram">Online Programs</a>
                </li>
                <li className="nav-item">
                  <a className="nav-link text-white" href="/internship">Internship</a>
                </li>
                <li className="nav-item">
                  <a className="nav-link text-white" href="/placement">Placement</a>
                </li>

                {/* <li className="nav-item dropdown">
                  <a className="nav-link dropdown-toggle text-white" href="#" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    Courses
                  </a>
                </li> */}

                {/* <li className="nav-item">
                  <a className="nav-link text-white" href="/courses">Courses</a>
                </li> */}
                <li className="nav-item">
                  <a className="nav-link text-white" href="/contactus">Contactus</a>
                </li>


              </ul>
            </div>
          </nav>
        </div>
      </div>
      <nav aria-label="breadcrumb">
        <ol className="breadcrumb" style={{ fontSize: "14px", fontFamily:"'Open Sans', sans-serif",padding : (".5em",".5em"), backgroundColor: "#e9ecef"}}>
          <li className="breadcrumb-item">
            <a href="/">Home</a>
          </li>
          <li className="breadcrumb-item active" aria-current="page">onlineprogram</li>
        </ol>
      </nav>

	  
	  <div class="course-w3ls py-5 ">
				<div class="container py-xl-5 py-lg-3">
					<h3 class="title text-capitalize font-weight-light text-dark text-center mb-sm-5 mb-4">course -
						<span class="font-weight-bold">Software</span>
					</h3>


					<div class="row cource-list-agile pt-4 cource-list-agile-2">
						<div class="col-lg-7 agile-course-main">
							<div class="w3ls-cource-first">
								<img src="assests/images/1.png" alt="" class="img-fluid img-poiscour mx-auto d-block mt-2"></img>
								<div class="px-md-5 px-4  pb-md-5 pb-4">
									<h3 class="text-dark">A Guide For Beginners Software</h3>
									<p class="mt-3 mb-4 pr-lg-5">Java has long been one of the most popular programming languages in the software industry. With its versatility and wide range of applications.</p>
									<ul class="list-unstyled text-capitalize">
										<li>
											<i class="fas fa-calendar-alt mr-3"></i> Aug</li>
										<li class="my-3">
											<i class="fas fa-clock mr-3"></i>4 - 6 Months</li>
										<li>
											<i class="fas fa-users mr-3"></i>70 seats</li>
									</ul>
								</div>
							</div>
						</div>
						<div class="col-lg-5 agile-course-main-2 mt-4">
							<img src="assests/images/java3.jpg" alt="" class="img-fluid" />
						</div>
						<div class="buttons-w3ls">
							<a class="btn button-cour-w3ls text-white" href="/onlineprogram" role="button">Learn More</a>
							<a class="btn bg-dark text-white" href="/" role="button">Apply Now</a>
						</div>
					</div>


					<div class="row cource-list-agile cource-list-agile-2">
						<div class="col-lg-5 agile-course-main-3 mt-4">
							<img src="assests/images/ds1.jpg" alt="" class="img-fluid" />
						</div>
						<div class="col-lg-7 agile-course-main text-right">
							<div class="w3ls-cource-first">
								<img src="assests/images/1.png" alt="" class="img-fluid img-poiscour mx-auto d-block mt-2"></img>
								<div class="px-md-5 px-4  pb-md-5 pb-4">
									<h3 class="text-dark">Introduction To Engineering Design</h3>
									<p class="mt-3 mb-4 pl-lg-4">if youre looking to explore the fascinating world of data science and gain proficiency in R programming,.</p>
									<ul class="list-unstyled text-capitalize">
										<li>Aug
											<i class="fas fa-calendar-alt ml-3"></i>
										</li>
										<li class="my-3">3 - 6 Months
											<i class="fas fa-clock ml-3"></i>
										</li>
										<li>60 seats
											<i class="fas fa-users ml-3"></i>
										</li>
									</ul>
								</div>
							</div>
						</div>
						{/* <div class="col-lg-5 agile-course-main-2 mt-4">
							<img src="assests/images/java3.jpg" alt="" class="img-fluid" />
						</div> */}
						<div class="buttons-w3ls-2">
							<a class="btn button-cour-w3ls text-white" href="/onlineprogram" role="button">Learn More</a>
							<a class="btn bg-dark text-white" href="/" role="button">Apply Now</a>
						</div>
					</div>


					<div class="row cource-list-agile pt-4 cource-list-agile-2">
						<div class="col-lg-7 agile-course-main">
							<div class="w3ls-cource-first">
								<img src="assests/images/2.png" alt="" class="img-fluid img-poiscour mx-auto d-block mt-2"></img>
								<div class="px-md-5 px-4  pb-md-5 pb-4">
									<h3 class="text-dark">Organize Of Program Languages</h3>
									<p class="mt-3 mb-4 pr-lg-5">Mean Stack Development is one such technology that has gained immense popularity among developers.</p>
									<ul class="list-unstyled text-capitalize">
										<li>
											<i class="fas fa-calendar-alt mr-3"></i>Aug</li>
										<li class="my-3">
											<i class="fas fa-clock mr-3"></i>4 - 6 Months</li>
										<li>
											<i class="fas fa-users mr-3"></i>70 seats</li>
									</ul>
								</div>
							</div>
						</div>
						<div class="col-lg-5 agile-course-main-2 mt-4">
							<img src="assests/images/ds2.png" alt="" class="img-fluid" />
						</div>
						<div class="buttons-w3ls">
							<a class="btn button-cour-w3ls text-white" href="/onlineprogram" role="button">Learn More</a>
							<a class="btn bg-dark text-white" href="/" role="button">Apply Now</a>
						</div>
					</div>


					<div class="row cource-list-agile cource-list-agile-2">
						<div class="col-lg-5 agile-course-main-3 mt-4">
							<img src="assests/images/ds7.jpg" alt="" class="img-fluid" />
						</div>
						<div class="col-lg-7 agile-course-main text-right">
							<div class="w3ls-cource-first">
								<img src="assests/images/2.png" alt="" class="img-fluid img-poiscour mx-auto d-block mt-2"></img>
								<div class="px-md-5 px-4  pb-md-5 pb-4">
									<h3 class="text-dark">Programming Software Engineer</h3>
									<p class="mt-3 mb-4 pl-lg-4"> C/C++ programming internship is a practical learning opportunity designed for students and programming enthusiasts.</p>
									<ul class="list-unstyled text-capitalize">
										<li>Aug
											<i class="fas fa-calendar-alt ml-3"></i>
										</li>
										<li class="my-3">3 - 6 Months
											<i class="fas fa-clock ml-3"></i>
										</li>
										<li>60 seats
											<i class="fas fa-users ml-3"></i>
										</li>
									</ul>
								</div>
							</div>
						</div>
						<div class="buttons-w3ls-2">
							<a class="btn button-cour-w3ls text-white" href="/onlineprogram" role="button">Learn More</a>
							<a class="btn bg-dark text-white" href="/" role="button">Apply Now</a>
						</div>
					</div>


					<div class="row cource-list-agile pt-4 cource-list-agile-2">
						<div class="col-lg-7 agile-course-main">
							<div class="w3ls-cource-first">
								<img src="assests/images/1.png" alt="" class="img-fluid img-poiscour mx-auto d-block mt-2"></img>
								<div class="px-md-5 px-4  pb-md-5 pb-4">
									<h3 class="text-dark">Object-Oriented Programming Java</h3>
									<p class="mt-3 mb-4 pr-lg-5">Java is an object-oriented programming language which is generally designed either to be compiled to native (machine) code or to be interpreted from source code at runtime..</p>
									<ul class="list-unstyled text-capitalize">
										<li>
											<i class="fas fa-calendar-alt mr-3"></i>Aug</li>
										<li class="my-3">
											<i class="fas fa-clock mr-3"></i>4 - 6 Months</li>
										<li>
											<i class="fas fa-users mr-3"></i>70 seats</li>
									</ul>
								</div>
							</div>
						</div>
						<div class="col-lg-5 agile-course-main-2 mt-4">
							<img src="assests/images/ds8.jpg" alt="" class="img-fluid" />
						</div>
						<div class="buttons-w3ls">
							<a class="btn button-cour-w3ls text-white" href="/onlineprogram" role="button">Learn More</a>
							<a class="btn bg-dark text-white" href="/" role="button">Apply Now</a>
						</div>
					</div>


					<div class="row cource-list-agile cource-list-agile-2">
						<div class="col-lg-5 agile-course-main-3 mt-4">
							<img src="assests/images/ds6.jpg" alt="" class="img-fluid" />
						</div>
						<div class="col-lg-7 agile-course-main text-right">
							<div class="w3ls-cource-first">
								<img src="assests/images/2.png" alt="" class="img-fluid img-poiscour mx-auto d-block mt-2"></img>
								<div class="px-md-5 px-4  pb-md-5 pb-4">
									<h3 class="text-dark">Algorithms Software Programming</h3>
									<p class="mt-3 mb-4 pl-lg-4"> Java offers a plethora of opportunities for developers. Among the various branches of Java, Advanced Java J2EE stands out as a powerful tool for building enterprise-level applications</p>
									<ul class="list-unstyled text-capitalize">
										<li>Aug
											<i class="fas fa-calendar-alt ml-3"></i>
										</li>
										<li class="my-3">3 - 6 Months
											<i class="fas fa-clock ml-3"></i>
										</li>
										<li>60 seats
											<i class="fas fa-users ml-3"></i>
										</li>
									</ul>
								</div>
							</div>
						</div>
						<div class="buttons-w3ls-2">
							<a class="btn button-cour-w3ls text-white" href="/onlineprogram" role="button">Learn More</a>
							<a class="btn bg-dark text-white" href="./" role="button">Apply Now</a>
						</div>
					</div>


					<div class="row cource-list-agile pt-4 cource-list-agile-2">
						<div class="col-lg-7 agile-course-main">
							<div class="w3ls-cource-first">
								<img src="assests/images/1.png" alt="" class="img-fluid img-poiscour mx-auto d-block mt-2"></img>
								<div class="px-md-5 px-4  pb-md-5 pb-4">
									<h3 class="text-dark">Digitral Marketing</h3>
									<p class="mt-3 mb-4 pr-lg-5">Any marketing that uses electronic devices and can be used by marketing specialists to convey promotional messaging and measure its impact through your customer journey</p>
									<ul class="list-unstyled text-capitalize">
										<li>
											<i class="fas fa-calendar-alt mr-3"></i>aug</li>
										<li class="my-3">
											<i class="fas fa-clock mr-3"></i>4 - 6 months</li>
										<li>
											<i class="fas fa-users mr-3"></i>70 seats</li>
									</ul>
								</div>
							</div>
						</div>
						<div class="col-lg-5 agile-course-main-2 mt-4">
							<img src="assests/images/dm2.png" alt="" class="img-fluid" />
						</div>
						<div class="buttons-w3ls">
							<a class="btn button-cour-w3ls text-white" href="/onlineprogram" role="button">Learn More</a>
							<a class="btn bg-dark text-white" href="/" role="button">Apply Now</a>
						</div>
					</div>


					<div class="row cource-list-agile cource-list-agile-2">
						<div class="col-lg-5 agile-course-main-3 mt-4">
							<img src="assests/images/dm3.png" alt="" class="img-fluid" />
						</div>
						<div class="col-lg-7 agile-course-main text-right">
							<div class="w3ls-cource-first">
								<img src="assests/images/2.png" alt="" class="img-fluid img-poiscour mx-auto d-block mt-2"></img>
								<div class="px-md-5 px-4  pb-md-5 pb-4">
									<h3 class="text-dark">AWS</h3>
									<p class="mt-3 mb-4 pl-lg-4"> Build, Deploy, and Manage Websites, Apps or Processes On AWS Secure, Reliable Network.</p>
									<ul class="list-unstyled text-capitalize">
										<li>Aug
											<i class="fas fa-calendar-alt ml-3"></i>
										</li>
										<li class="my-3">3 - 6 Months
											<i class="fas fa-clock ml-3"></i>
										</li>
										<li>60 seats
											<i class="fas fa-users ml-3"></i>
										</li>
									</ul>
								</div>
							</div>
						</div>

						<div class="buttons-w3ls-2">
							<a class="btn button-cour-w3ls text-white" href="/onlineprogram" role="button">Learn More</a>
							<a class="btn bg-dark text-white" href="./" role="button">Apply Now</a>
						</div>

					</div>


					<div class="row cource-list-agile pt-4 cource-list-agile-2">
						<div class="col-lg-7 agile-course-main">
							<div class="w3ls-cource-first">
								<img src="assests/images/1.png" alt="" class="img-fluid img-poiscour mx-auto d-block mt-2"></img>
								<div class="px-md-5 px-4  pb-md-5 pb-4">
									<h3 class="text-dark">Android</h3>
									<p class="mt-3 mb-4 pr-lg-5">an automaton that resembles a human being. synonyms: humanoid, mechanical man. type of: automaton, golem, robot.</p>
									<ul class="list-unstyled text-capitalize">
										<li>
											<i class="fas fa-calendar-alt mr-3"></i>aug</li>
										<li class="my-3">
											<i class="fas fa-clock mr-3"></i>4 - 6 months</li>
										<li>
											<i class="fas fa-users mr-3"></i>70 seats</li>
									</ul>
								</div>
							</div>
						</div>
						<div class="col-lg-5 agile-course-main-2 mt-4">
							<img src="assests/images/androd1.jpg" alt="" class="img-fluid" />
						</div>
						<div class="buttons-w3ls">
							<a class="btn button-cour-w3ls text-white" href="/onlineprogram" role="button">Learn More</a>
							<a class="btn bg-dark text-white" href="/" role="button">Apply Now</a>
						</div>
					</div>


					<div class="row cource-list-agile cource-list-agile-2">
						<div class="col-lg-5 agile-course-main-3 mt-4">
							<img src="assests/images/mern11.png" alt="" class="img-fluid" />
						</div>
						<div class="col-lg-7 agile-course-main text-right">
							<div class="w3ls-cource-first">
								<img src="assests/images/2.png" alt="" class="img-fluid img-poiscour mx-auto d-block mt-2"></img>
								<div class="px-md-5 px-4  pb-md-5 pb-4">
									<h3 class="text-dark">MERN STACK</h3>
									<p class="mt-3 mb-4 pl-lg-4"> a collection of technologies that enables faster application development</p>
									<ul class="list-unstyled text-capitalize">
										<li>Aug
											<i class="fas fa-calendar-alt ml-3"></i>
										</li>
										<li class="my-3">3 - 6 Months
											<i class="fas fa-clock ml-3"></i>
										</li>
										<li>60 seats
											<i class="fas fa-users ml-3"></i>
										</li>
									</ul>
								</div>
							</div>
						</div>

						<div class="buttons-w3ls-2">
							<a class="btn button-cour-w3ls text-white" href="/onlineprogram" role="button">Learn More</a>
							<a class="btn bg-dark text-white" href="./" role="button">Apply Now</a>
						</div>

					</div>

					<div class="row cource-list-agile pt-4 cource-list-agile-2">
						<div class="col-lg-7 agile-course-main">
							<div class="w3ls-cource-first">
								<img src="assests/images/1.png" alt="" class="img-fluid img-poiscour mx-auto d-block mt-2"></img>
								<div class="px-md-5 px-4  pb-md-5 pb-4">
									<h3 class="text-dark">PYTHON</h3>
									<p class="mt-3 mb-4 pr-lg-5"> developing websites and software, task automation, data analysis, and data visualization.</p>
									<ul class="list-unstyled text-capitalize">
										<li>
											<i class="fas fa-calendar-alt mr-3"></i>aug</li>
										<li class="my-3">
											<i class="fas fa-clock mr-3"></i>4 - 6 months</li>
										<li>
											<i class="fas fa-users mr-3"></i>70 seats</li>
									</ul>
								</div>
							</div>
						</div>
						<div class="col-lg-5 agile-course-main-2 mt-4">
							<img src="assests/images/python111.jpg" alt="" class="img-fluid" />
						</div>
						<div class="buttons-w3ls">
							<a class="btn button-cour-w3ls text-white" href="/onlineprogram" role="button">Learn More</a>
							<a class="btn bg-dark text-white" href="/" role="button">Apply Now</a>
						</div>
					</div>


					<div class="row cource-list-agile cource-list-agile-2">
						<div class="col-lg-5 agile-course-main-3 mt-4">
							<img src="assests/images/Java-developers1.jpg" alt="" class="img-fluid" />
						</div>
						<div class="col-lg-7 agile-course-main text-right">
							<div class="w3ls-cource-first">
								<img src="assests/images/2.png" alt="" class="img-fluid img-poiscour mx-auto d-block mt-2"></img>
								<div class="px-md-5 px-4  pb-md-5 pb-4">
									<h3 class="text-dark">JAVA</h3>
									<p class="mt-3 mb-4 pl-lg-4">  Java. Java is a popular programming language. Java is used to develop mobile apps,.</p>
									<ul class="list-unstyled text-capitalize">
										<li>Aug
											<i class="fas fa-calendar-alt ml-3"></i>
										</li>
										<li class="my-3">3 - 6 Months
											<i class="fas fa-clock ml-3"></i>
										</li>
										<li>60 seats
											<i class="fas fa-users ml-3"></i>
										</li>
									</ul>
								</div>
							</div>
						</div>

						<div class="buttons-w3ls-2">
							<a class="btn button-cour-w3ls text-white" href="/onlineprogram" role="button">Learn More</a>
							<a class="btn bg-dark text-white" href="./" role="button">Apply Now</a>
						</div>

					</div>


				</div>
			</div>



			{/* <div class="stats-w3layouts py-5">
				<div class="container py-xl-5 py-lg-3">
					<div class="stats-info">
						<div class="row">
							<div class="col-md-3 col-6 stats-grid-w3-agile text-center px-xl-5 px-3">
								<div class='numscroller font-weight-bold my-2' data-slno='1' data-min='0' data-max='1280' data-delay='.5' data-increment="1">1280</div>
								<p class="text-uppercase text-white border-top pt-4 mt-3">happy students</p>
							</div>
							<div class="col-md-3 col-6 stats-grid-w3-agile text-center  px-xl-5 px-3">
								<div class='numscroller font-weight-bold my-2' data-slno='1' data-min='0' data-max='920' data-delay='.5' data-increment="1">920</div>
								<p class="text-uppercase text-white border-top pt-4 mt-3">approved courses</p>
							</div>
							<div class="col-md-3 col-6 stats-grid-w3-agile text-center mt-md-0 mt-5  px-xl-5 px-3">
								<div class='numscroller font-weight-bold my-2' data-slno='1' data-min='0' data-max='1200' data-delay='.5' data-increment="1">1200</div>
								<p class="text-uppercase text-white border-top pt-4 mt-3">certified teachers</p>
							</div>
							<div class="col-md-3 col-6 stats-grid-w3-agile text-center mt-md-0 mt-5  px-xl-5 px-3">
								<div class='numscroller font-weight-bold my-2' data-slno='1' data-min='0' data-max='1200' data-delay='.5' data-increment="1">1200</div>
								<p class="text-uppercase text-white border-top pt-4 mt-3">graduate students</p>
							</div>
						</div>
					</div>
				</div>
			</div> */}


      {/* <TrendingCourses/> */}


      






       

    </Fragment>
  )

}

export default Onlineprogram
