import React from 'react';
import { FaWhatsapp } from 'react-icons/fa';
import '../index.css';

const WhatsAppButton = () => {
  const phoneNumber = '9901371386';

  const handleClick = () => {
    window.open(`https://api.whatsapp.com/send?phone=${phoneNumber}`, '_blank');
  };

  return (
    <button className="whatsapp-button" onClick={handleClick}>
      <FaWhatsapp className="whatsapp-icon" />
      
    </button>
  );
};

export default WhatsAppButton;


  