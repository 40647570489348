import React from 'react'
import image1 from '../images/WhyChooseUs.png';
import image2 from '../images/capture.png';
const WhyUs = () => {
  return (
   
	<div class="blog-w3l py-5">
		<div class="container py-xl-5 py-lg-3">
			<h3 class="title text-capitalize font-weight-light text-dark text-center mb-5">Why
				<span class="font-weight-bold"> Us?</span>
			</h3>
			<div class="row blog-content pt-md-4">
				
				<div class="col-lg-8 blog_section">
					<div class="card">
						<img class="card-img-top" src={image1} alt="" />
						
					</div>
					<div class="card my-4">
						<img class="card-img-top" src={image2} alt="" />
						
					</div>
					
					
					
				</div>
				
				<div class="col-lg-4 col-12 event-right mt-lg-0 mt-sm-5 mt-4">
					<div class="event-right1">
						
						<div class="categories my-4 p-4 border">
							<h3 class="blog-title text-dark">10 REASONS TO CHOOSE US</h3>
							<ul>
								<li class="mt-3">
									<i class="fas fa-check mr-2"></i>
									<a href="single.html">PROJECT BASED TRAINING</a>
								</li>
								<li class="mt-3">
									<i class="fas fa-check mr-2"></i>
									<a href="single.html">CERTIFICATION</a>
								</li>
								<li class="mt-3">
									<i class="fas fa-check mr-2"></i>
									<a href="single.html">RELIABILITY</a>
								</li>
								<li class="mt-3">
									<i class="fas fa-check mr-2"></i>
									<a href="single.html">QUALITY DELIVERY</a>
								</li>
								<li class="mt-3">
									<i class="fas fa-check mr-2"></i>
									<a href="single.html">TRAINING BY CERTIFIED TRAINERS</a>
								</li>
								<li class="mt-3">
									<i class="fas fa-check mr-2"></i>
									<a href="single.html">POST TRAINING SUPPORT</a>
								</li>
                                <li class="mt-3">
									<i class="fas fa-check mr-2"></i>
									<a href="single.html">PLACEMENT ASSISTANCE</a>
								</li>
								<li class="mt-3">
									<i class="fas fa-check mr-2"></i>
									<a href="single.html">VALUE FOR MONEY</a>
								</li>
								<li class="mt-3">
									<i class="fas fa-check mr-2"></i>
									<a href="single.html">PRE-PLACEMENT TRAINING</a>
								</li>
								<li class="mt-3">
									<i class="fas fa-check mr-2"></i>
									<a href="single.html">BEST INFRASTRUCTURE AND SERVICES</a>
								</li>
							</ul>
						</div>
						
					</div>
				</div>
				
			</div>
		</div>
	</div>
  )
}

export default WhyUs